import { Button, Result, Spin } from 'antd';
import { observer } from 'mobx-react';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import usePageTitle from '../DataSource/Hooks/usePageTitle';
import { PaymentStore } from '../DataSource/Stores/Site/PaymentStore';
import Lang from './Lang';

interface QueryParams {
  [key: string]: string | undefined;
}

export interface PaymentResult {
  payment: 'failure' | 'success';
  pg_order_id: string; //"6670158fad1a0ef9f55597d1"
  pg_payment_id: string; //"1286500683"
  pg_salt: string; //"AXUZKSFYcNzeKlmQ"
  pg_sig: string; //"e799014e18b13d414f8fee3311ecc1b3"
}

export interface PaymentOrder {
  order_id: string;
  product_id: string;
  amount: number;
  currency: string;
  status: 'pending' | 'error' | 'not_completed' | 'ok';
  createdAt: string;
  updatedAt: string;
}

export interface PaymentOrderStatusResult {
  pg_create_date?: string;
  pg_status: string;
  pg_payment_status: 'success' | 'failed' | 'waiting';
}

export interface PaymentStatus {
  pg_payment_status: string;
  pg_status: string;
  order_id: string;
  product_id: string;
  amount: number;
  currency: string;
  status: 'pending' | 'error' | 'not_completed' | 'ok';
  createdAt: string;
  updatedAt: string;
}

function Waiting() {
  const [init, setInit] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [serverError, setServerError] = useState<boolean>(false);
  const [orderNotFound, setOrderNotFound] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('Please wait... ');

  const location = useLocation();
  usePageTitle('Sign up');

  useEffect(() => {
    if (init) return;
    setInit(true);

    !PaymentStore.loading && getOrder();
  }, [location.search]);

  const getParams = (): QueryParams => {
    const searchParams = new URLSearchParams(location.search);
    const params: QueryParams = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  };

  const getOrder = async () => {
    let order;
    serverError && setServerError(false);
    orderNotFound && setOrderNotFound(false);
    try {
      order = await PaymentStore.getPaymentStatus(getParams() as unknown as PaymentResult);
    } catch (error: any) {
      // console.log('error=', JSON.stringify(error));
      console.log('error=', error.message);
      console.log('error=', error.code);
      console.log('error=', error.status);
      if (error.message == 'Request failed with status code 409') {
        setTitle(Lang({ str: 'orderNotFound', section: 'default' }));
        setOrderNotFound(true);
      } else {
        setTitle(Lang({ str: 'internalServerError', section: 'default' }));
        setServerError(true);
      }
      return;
    }

    console.log('---- getOrder|order=', order);
    const { pg_payment_status, pg_status } = order;

    if (pg_status === 'ok' && pg_payment_status === 'success') {
      console.log('The payment has been completed successfully');
      // return 'success';
      setTitle(Lang({ str: 'paymentStatusWaiting', section: 'default' }));
      setSuccess(true);
      window.location.replace('/success');
      return;
    }
    if (pg_status === 'ok' && pg_payment_status === 'failed') {
      console.log('Платеж неуспешен');
      // return 'failed';
      setTitle(Lang({ str: 'paymentStatusFailed', section: 'default' }));
      window.location.replace('/failure');
      return;
    }
    if (pg_status === 'ok' && pg_payment_status === 'waiting') {
      console.log('The payment is pending, please repeat the request later');
      // return 'waiting';
      setTitle(Lang({ str: 'paymentStatusWaiting', section: 'default' }));
      setWaiting(true);
      setTimeout(() => window.location.reload(), 30000);
      return;
    }
    if (pg_status === 'error') {
      console.log('Ошибка платежа', { pg_payment_status });
      // return 'waiting';
      setTitle('Error');
      setFailed(true);
      return;
    }
    setTitle('Payment status: ' + pg_payment_status);
  };

  // Обработка ошибок сервера
  if (serverError || orderNotFound) {
    return (
      <div className="not_found_wrp">
        <div className="not_found">
          <Result
            status="error"
            title={title}
            extra={[
              serverError ? (
                <Button size="small" key={'tryAgain'} onClick={getOrder}>
                  {Lang({ str: 'tryAgain', section: 'default' })}
                </Button>
              ) : (
                <Link className="main_page_but" key={'failure'} to={'/'}>
                  {Lang({ str: 'newchall', section: 'default' })}
                </Link>
              ),
            ]}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="not_found_wrp">
      <div className="not_found">
        <Result
          status="info"
          title={title}
          subTitle={<Spin />}
          extra={[
            <Link className="main_page_but" key={'waiting'} to={'/waiting'}>
              {Lang({ str: 'refresh', section: 'default' })}
            </Link>,
          ]}
        />
      </div>
    </div>
  );
}

export default observer(Waiting);
