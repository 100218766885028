import { DownOutlined } from '@ant-design/icons';
import { Collapse, CollapseProps, Dropdown, Flex, MenuProps } from 'antd';
import { FC, Fragment, ReactNode } from 'react';
import { StagesStore } from '../../../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import Clickable from '../../../../../ui/Clickable';
import * as styles from './index.module.scss';

interface IProps {
  expandIcon: (panelProps: any) => ReactNode;
  assignedTo?: string;
}

const MemberTasksBlock: FC<IProps> = ({ expandIcon, assignedTo }) => {
  const memberTasksCollapseItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'ЗАДАЧИ ПОЛЬЗОВАТЕЛЕЙ',
      children: (
        <Flex vertical gap={8}>
          {StagesStore.dataUserTask.map((task) => {
            const membersDropdownMenu: MenuProps['items'] = Object.values(
              WorkspacesStore.members
            ).map((member) => ({
              key: member._id,
              label: <div>{member.userId.firstname}</div>,
            }));

            return (
              <Fragment key={task._id}>
                {/*<Button*/}
                {/*  variant={task.status == 'in_progress' ? 'primary' : 'secondary'}*/}
                {/*  onClick={() => onTask(task._id, task.status)}*/}
                {/*  key={task._id}*/}
                {/*  loading={StagesStore.loadingJob}*/}
                {/*>*/}
                {/*  <div>*/}
                {/*    <p>taskId: {task._id.slice(-4)}</p>*/}
                {/*  </div>*/}
                {/*</Button>*/}
                <div className={styles.field}>
                  <p className={styles.name}>{task.title}</p>
                  {/*<Dropdown trigger={['click']}>*/}
                  {/*  <Clickable className={styles.dropdownBtn}>*/}
                  {/*    {task.status}*/}
                  {/*    <DownOutlined color="#2A3038" />*/}
                  {/*  </Clickable>*/}
                  {/*</Dropdown>*/}
                  {task.status}
                </div>
                <div className={styles.field}>
                  <p className={styles.name}>Ответственный</p>
                  {/*<Dropdown menu={{ items: membersDropdownMenu }} trigger={['click']}>*/}
                  {/*  <Clickable className={styles.dropdownBtn}>*/}
                  {/*    {task.assignedTo && assignedTo*/}
                  {/*      ? WorkspacesStore.members[assignedTo]?.userId?.firstname*/}
                  {/*      : 'Ответственный'}*/}
                  {/*    <DownOutlined color="#2A3038" />*/}
                  {/*  </Clickable>*/}
                  {/*</Dropdown>*/}
                  {task.assignedTo && assignedTo
                    ? WorkspacesStore.members[assignedTo]?.userId?.firstname
                    : 'Ответственный'}
                </div>
              </Fragment>
            );
          })}
        </Flex>
      ),
    },
  ];

  return (
    <Collapse
      items={memberTasksCollapseItems}
      expandIcon={expandIcon}
      expandIconPosition="right"
      bordered={false}
      defaultActiveKey={[1]}
      ghost
    />
  );
};

export default MemberTasksBlock;
