import { Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import ButtonNew from '../../../../ui/Button';
import Icon from '../../../../ui/Icon';
import OrderItem from '../OrderItem';
import * as styles from './index.module.scss';

const NewOrders: React.FC = () => {
  return (
    <>
      <div className={styles.flex_bl}>
        <div className={styles.text}>
          <span>16 июля 2024</span>
          <p>Новые заказы</p>
        </div>
        <div className={styles.d_flex}>
          <Tooltip placement="bottom" title={<span>Авто-распределение</span>}>
            <div>
              <ButtonNew variant="outlined">
                <Icon slug={'fi-rr-shuffle'} />
              </ButtonNew>
            </div>
          </Tooltip>

          <Tooltip placement="bottom" title={<span>Отправить все заказы</span>}>
            <div>
              <ButtonNew variant="delivery_secondary">
                <Icon slug={'fi-rr-screen-share'} />
              </ButtonNew>
            </div>
          </Tooltip>
        </div>
      </div>
      <p className={styles.order_text}>16 заявок</p>
      <div className={styles.order_list}>
        <OrderItem />
      </div>
    </>
  );
};
export default observer(NewOrders);
