// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sxs90PQXWHls9RlrlDAC{position:relative;display:flex;justify-content:space-between;margin-bottom:24px}.Sxs90PQXWHls9RlrlDAC .AXJgj9QwrMFMSiYQ7EAn{background:rgba(241,183,41,.08);border:.5px solid #f1b729;border-radius:8px;padding:4px 16px;display:flex;align-items:center;font-weight:500;width:fit-content;font-size:16px;color:#f1b729;cursor:pointer;text-align:center}.Sxs90PQXWHls9RlrlDAC .AXJgj9QwrMFMSiYQ7EAn svg{margin-right:8px}.gNlMEquz7ib4WSoeavll{width:100%}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DistributionCenter/NewJobsBlock/ SelectedTask/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,4CAEE,+BAAA,CACA,yBAAA,CACA,iBAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA,CACA,cAAA,CACA,aAAA,CACA,cAAA,CACA,iBAAA,CACA,gDACE,gBAAA,CAIN,sBACE,UAAA","sourcesContent":[".flex_block{position:relative;display:flex;justify-content:space-between;margin-bottom:24px}.flex_block .back{background:rgba(241,183,41,.08);border:.5px solid #f1b729;border-radius:8px;padding:4px 16px;display:flex;align-items:center;font-weight:500;width:fit-content;font-size:16px;color:#f1b729;cursor:pointer;text-align:center}.flex_block .back svg{margin-right:8px}.prompts_create{width:100%}"],"sourceRoot":""}]);
// Exports
export var flex_block = `Sxs90PQXWHls9RlrlDAC`;
export var back = `AXJgj9QwrMFMSiYQ7EAn`;
export var prompts_create = `gNlMEquz7ib4WSoeavll`;
export default ___CSS_LOADER_EXPORT___;
