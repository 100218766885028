import { Button, Form, Input, Modal, message, type FormProps } from 'antd';
import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { ProfileStore } from '../../../../DataSource/Stores/Site/ProfileStore';
import Lang from '../../../Lang/index';

export type FieldType = {
  new_email: string;
  code: number;
};

interface ModalProfileProps {
  forceUpdate: () => void;
}

const ModalProfile: React.FC<ModalProfileProps> = ({ forceUpdate }) => {
  const [form] = Form.useForm();
  const formRef = useRef<any>(null);
  const [codeIsSend, setCodeIsSend] = useState<boolean>(false);

  const onFinish = async (values: FieldType) => {
    console.log('onFinish|values=', values);

    if (values.new_email && !values.code) {
      try {
        ProfileStore.sendingEmail = true;
        message.loading('Loading');

        await ProfileStore.sendNewEmail(values.new_email);

        message.destroy();
      } catch (error: any) {
        // console.error('signUp exception:error=', JSON.stringify(error));
        console.error('signUp exception:error?.status=', error?.message);
        message.destroy();
        if (error?.message == 'Request failed with status code 409') {
          message.error('Email exist');
        } else {
          message.error('Error send Email');
        }
        return;
      } finally {
        ProfileStore.sendingEmail = false;
      }
      setCodeIsSend(true);
    } else if (values.new_email && values.code) {
      try {
        ProfileStore.sendingEmail = true;
        message.loading('Loading');

        await ProfileStore.changeEmail(values);

        message.destroy();

        ProfileStore.isModalOpen = false;

        formRef.current?.resetFields();

        setCodeIsSend(false);
      } catch (error: any) {
        console.error('signUp exception:error?.status=', error?.message);
        message.destroy();
        if (error?.message == 'Request failed with status code 409') {
          message.error('Wrong code');
        } else {
          message.error('Error send code');
        }
        return;
      } finally {
        ProfileStore.sendingEmail = false;
      }
    }

    forceUpdate();
  };

  const handleOk = () => {
    ProfileStore.isModalOpen = false;
    formRef.current?.resetFields();
  };
  const handleCancel = () => {
    ProfileStore.isModalOpen = false;
    setCodeIsSend(false);
    formRef.current?.resetFields();
  };
  const onChange = () => {};

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title={Lang({ str: 'newemailaddress', section: 'default' })}
      open={ProfileStore.isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <Form
        key={Date.now()}
        form={form}
        name="new_email_address"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
        ref={formRef}
      >
        <Form.Item<FieldType>
          // label="Enter a new email address olegych21@gmail.com"
          label={Lang({ str: 'newemailadred', section: 'default' })}
          name="new_email"
          rules={[
            { required: true, message: 'Please input your new email address!' },
            { type: 'email', message: 'Please enter a valid email address!' },
          ]}
        >
          <Input disabled={ProfileStore.sendingEmail || codeIsSend} />
        </Form.Item>
        {codeIsSend && (
          <Form.Item<FieldType>
            label="Enter the confirmation code sent to your email"
            name="code"
            rules={[{ required: true, message: 'Please code' }]}
          >
            <ReactCodeInput
              type="number"
              fields={6}
              name="code"
              inputMode="numeric"
              value={''}
              onChange={onChange}
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button loading={ProfileStore.sendingEmail} type="primary" htmlType="submit">
            {codeIsSend
              ? Lang({ str: 'save', section: 'default' })
              : Lang({ str: 'send', section: 'default' })}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default observer(ModalProfile);
