import { Progress } from 'antd';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { useDrag } from 'react-dnd';
import avatar from '../../../../../assets/bg.svg';
import * as styles from './index.module.scss';

export type ButtonVariant = 'busy' | 'free' | 'work';

export interface CourierItemProps {
  title: string;
  percent: number;
  theme?: ButtonVariant;
  photo?: ReactNode;
  key?: string;
  id?: string;
  size?: number;
}

const CourierItemProgressDrag = ({ title, percent, photo, theme, size }: CourierItemProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CourierItem',
    item: { title, percent, theme },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }} className={styles.bl_progress_course}>
      <div className={styles.progress_container}>
        <Progress
          size={size ? size : 42}
          type="circle"
          percent={percent}
          trailColor="#e5e5e5"
          strokeWidth={6}
          strokeColor={theme == 'free' ? '#21da4a' : '#1890ff'}
          format={() => (
            <div
              className={
                size ? styles.progress_content + ' ' + styles.size_32 : styles.progress_content
              }
            >
              {photo || <img src={avatar} alt="Avatar" />}
            </div>
          )}
        />
        <p>{title}</p>
      </div>
    </div>
  );
};
export default observer(CourierItemProgressDrag);
