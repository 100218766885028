import { animated, useSpring } from '@react-spring/web';
import { Result, Spin } from 'antd';
import { observer } from 'mobx-react';
import { FC, useRef } from 'react';
import { GeoPoint, JobsDataType } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import Button from '../../../ui/Button';
import Icon from '../../../ui/Icon';
import * as styles from '../Mobile/index.module.scss';
import JobItemAssigned from './JobItemAssigned';

interface IProps {
  workspaceId: string;
}

const JobTabAssigned: FC<IProps> = ({ workspaceId }) => {
  const wrapOrdRef = useRef<HTMLDivElement>(null);

  const jobClick = (job: JobsDataType) => {
    StagesStore.setShowTextDel(true); //otobrazh knopki
    StagesStore.setLayoutPageJobDel('jobeditor'); //rejim redactora
    StagesStore.setSelectedJobDel(job); //vibiraem job

    StagesStore.setIsSelectedJobDel(true);

    StagesStore.setShowTextTaskDelivery(false);
    StagesStore.setLayoutPageDelivery('library');
    StagesStore.setSelectedTask(null);
    StagesStore.setIsSelectedTask(false);
  };

  const onBackJob = () => {
    StagesStore.setShowTextDel(false);
    StagesStore.setLayoutPageJobDel('joblay');
    StagesStore.setIsSelectedJobDel(false);
    StagesStore.setSelectedJobDel(null);
  };

  const handleNavigate = (geoPoint?: GeoPoint) => {
    if (!geoPoint) {
      return;
    }

    const { latitude, longitude } = geoPoint;

    const deepLink = `dgis://2gis.ru/routeSearch/to/${longitude},${latitude}/go`;

    // Открываем диплинк в новой вкладке
    const newTab = window.open(deepLink, '_blank');

    if (newTab) {
      // Закрываем вкладку через небольшой интервал
      setTimeout(() => {
        newTab.close();
      }, 3000); // Задержка в 3000 мс (3 секунды)
    } else {
      console.error('Не удалось открыть новую вкладку.');
    }
  };

  const textAnimation = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: StagesStore.showTextDel ? 1 : 0,
    },
    delay: 300, // Задержка перед появлением текста
    config: { duration: 400 }, // Длительность анимации
  });

  return (
    <div className={styles.prompt_wrap} ref={wrapOrdRef}>
      <div className={styles.task_flex}>
        {StagesStore.layoutPageJobDel == 'joblay' ? (
          <div className={styles.task_child_flex}>
            {StagesStore.loadingWaitJobsError && 'Error loading'}

            {StagesStore.loadingWaitJobs && StagesStore.dataListJobsWork?.length === 0 ? (
              <Spin size="small" />
            ) : StagesStore.dataListJobsWork?.length === 0 ? (
              <div className={styles.no_tasks}>
                <Result status="warning" title="Новых заявок пока нет" />
              </div>
            ) : (
              StagesStore.dataListJobsWork?.map((task_i) => (
                <JobItemAssigned
                  key={task_i._id}
                  _id={task_i._id}
                  name={task_i.name}
                  jobId={task_i.jobId}
                  onClick={jobClick}
                  stageId={task_i.stageId}
                  workspaceId={task_i.workspaceId}
                  bitrixId2={task_i.externalId?.bitrixId}
                  jiraId2={task_i.externalId?.jiraId}
                  deliveryData={task_i.deliveryData}
                  jobData={task_i.jobData}
                  jobFor={task_i.jobFor}
                  labels={task_i.labels}
                  targetDate={task_i.targetDate}
                  comments={task_i.comments}
                  isSelectedJob={StagesStore.JobSelectedDel}
                  submitBtnName="Добавить в маршрут"
                />
              ))
            )}
          </div>
        ) : (
          <div className={styles.task_child_flex}>
            <div>
              <div className={styles.flex_block}>
                <animated.p className={styles.back} onClick={onBackJob} style={textAnimation}>
                  <Icon slug="arrow_back" /> Все "Мои"
                </animated.p>
              </div>

              {StagesStore.selectedJobDel && StagesStore.layoutPageJobDel == 'jobeditor' && (
                <JobItemAssigned
                  key={StagesStore.selectedJobDel._id}
                  targetDate={StagesStore.selectedJobDel.targetDate}
                  _id={StagesStore.selectedJobDel._id}
                  name={''}
                  deliveryData={StagesStore.selectedJobDel.deliveryData}
                  jobId={StagesStore.selectedJobDel.jobId}
                  workspaceId={StagesStore.selectedJobDel.workspaceId}
                  bitrixId2={StagesStore.selectedJobDel.bitrixId2}
                  jiraId2={StagesStore.selectedJobDel.jiraId2}
                  jobData={StagesStore.selectedJobDel.jobData}
                  labels={StagesStore.selectedJobDel.labels}
                  jobFor={StagesStore.selectedJobDel.jobFor}
                  comments={StagesStore.selectedJobDel.comments}
                  onClick={() => {}}
                  isSelectedJob={StagesStore.isJobSelectedDel}
                  stageId={''}
                  submitBtnName={'Добавить в маршрут'}
                  children={
                    <Button
                      icon="location"
                      className={styles.navigatorBtn}
                      onClick={() => {
                        handleNavigate(
                          StagesStore.selectedJobDel?.deliveryData?.isPickedUp
                            ? StagesStore.selectedJobDel?.deliveryData?.deliveryPoint?.geoPoint
                            : StagesStore.selectedJobDel?.deliveryData?.pickupPoint?.geoPoint
                        );
                      }}
                    >
                      Навигатор
                    </Button>
                  }
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(JobTabAssigned);
