import { action, makeAutoObservable, observable } from 'mobx';

import moment from 'moment';
import { basicRequestGet } from '../Requests/basicRequestGet';
import { basicRequestPost } from '../Requests/basicRequestPost';
import { User } from '../Resources/User';

import { FieldTypetwo } from '../../components/Auth/Create';
import { basicRequestDelete } from '../Requests/basicRequestDelete';
import { basicRequestPatch } from '../Requests/basicRequestPatch';
import { EMAIL_REGEX } from '../Utils/regex';
import { AuthStoreDataClass } from './AuthStoreDataClass';

export class AuthStoreActionsClass {
  @observable
  currentUserData: AuthStoreDataClass = new AuthStoreDataClass();

  @observable
  loggedTesting: boolean | null = /*this.currentUserData.loadAccessToken() ? false :*/ null;

  @observable changingUserData: boolean = false;

  @observable networkError: boolean = false;

  @observable sending: boolean = false;
  @observable signUpError: boolean = false;
  @observable signUpSuccess: boolean = false;

  @observable usersList: UserDataType[] = [];

  @observable _usersPagination: any = null;

  constructor(authStoreData: AuthStoreDataClass) {
    // makeObservable(this);
    makeAutoObservable(this);

    // console.log(
    //   '---- ww|AuthStoreDataClass|constructor|loadAccessToken=',
    //   this.currentUserData.loadAccessToken()
    // );

    this.setCurrentUserData(authStoreData);
    // console.log('---- ww|AuthStoreDataClass|constructor');
  }

  @action
  setCurrentUserData(data: AuthStoreDataClass) {
    this.currentUserData = data;
  }

  @action
  setLoggedTesting(data: boolean) {
    this.loggedTesting = data;
  }

  /**
   * меняем токен, присланный МИС, на "нормальный", который будет рефрешиться
   */
  switchSessionIfNeeded = async () => {
    return false;

    // на этом проекте не нужно
  };

  /**
   * получаем информацию о текущем залогиненном пользователе, полный цикл (инфа о сессии + запрос пользователя)
   */
  @action
  testCurrentLogged = async (): Promise<void> => {
    if (this.loggedTesting) {
      return;
    }
    // console.log('testCurrentLogged', this.loggedTesting);
    // return;
    this.setLoggedTesting(true);
    if (!this.currentUserData.ready) {
      this.currentUserData.setReady(false);
    }
    try {
      const response = await basicRequestGet('/user/profile');
      // console.log('---- ww|testCurrentLogged|response=', response);
      // console.log('---- ww|testCurrentLogged|response.data=', response.data);
      this.currentUserData.setUser(new User(response.data));
      this.currentUserData.setReady(true);
    } catch (error: any) {
      // console.error('testCurrentLogged error:', JSON.stringify(error));
      console.error('testCurrentLogged error:', error.message);
      if (error.message == 'Network Error') {
        this.networkError = true;
      }
    } finally {
      // console.log('testCurrentLogged|end');
      this.setLoggedTesting(false);
    }
  };

  /**
   * проверяем логин пароль, при удачном входе сохраняем данные
   * при авторизации передаем basic-auth клиента и в теле логин и пароль пользователя
   * клиент должен иметь доступный grant-type password
   *
   */
  login(login: string, password: string) {
    this.currentUserData.clear();

    return this.currentUserData.getToken(login, password).then(this.testCurrentLogged);
  }

  /**
   * Очистка локального хранилища от Контекста Емиаса
   */
  clearLocalStorageContext = () => {
    localStorage.removeItem('contextId');
  };

  /**
   *
   */
  async sendRecoveryLink(email: string) {
    await basicRequestPost('/forgot/sendRecoveryLink', {
      email,
    });
    console.log('---- ww|sendRecoveryLink');
    // history.pushState({}, 'Welcome', config.basePath());
  }

  /**
   *
   */
  async sendRegistrationLink(phoneNumber: string) {
    console.log('---- ww|sendRegistrationLink');
    this.sending = true;
    try {
      await basicRequestPost('/auth/sendRegistrationLink', {
        phoneNumber,
      });
    } catch (error) {
      console.error('---- sendRegistrationLink|error=', error);
      throw error;
    } finally {
      this.sending = false;
    }

    // history.pushState({}, 'Welcome', config.basePath());
  }

  /**
   *
   */
  async sendRegistrationLinkByEmail(email: string) {
    console.log('---- ww|sendRegistrationLinkByEmail');
    this.sending = true;
    try {
      await basicRequestPost('/auth/sendRegistrationLinkByEmail', {
        email,
      });
    } catch (error) {
      console.error('---- sendRegistrationLinkByEmail|error=', error);
      throw error;
    } finally {
      this.sending = false;
    }

    // history.pushState({}, 'Welcome', config.basePath());
  }

  /**
   *
   */
  async recoveryPass(data: FieldTypeForgot) {
    // await basicRequestPost('/auth/signUp', data);
    console.log('---- ww|signUp');
    // history.pushState({}, 'Welcome', config.basePath());
    return this.currentUserData.recoveryPass(data).then(this.testCurrentLogged);
  }

  /**
   *
   */
  async signUp(data: FieldTypetwo) {
    // await basicRequestPost('/auth/signUp', data);
    console.log('---- ww|signUp');
    // history.pushState({}, 'Welcome', config.basePath());
    return this.currentUserData.signUp(data).then(this.testCurrentLogged);
  }

  /**
   *
   */
  async signUpByEmail(data: FieldTypetwo) {
    // await basicRequestPost('/auth/signUp', data);
    console.log('---- ww|signUpByEmail');
    // history.pushState({}, 'Welcome', config.basePath());
    return this.currentUserData.signUpByEmail(data).then(this.testCurrentLogged);
  }

  /**
   *
   */
  logout() {
    // return basicRequestPost('/openid/logout', {
    //   refresh_token: this.currentUserData.loadRefreshToken(),
    // })
    //   .then(() => {
    // this.clearLocalStorageContext();
    this.currentUserData.clear();
    //     history.pushState({}, 'Welcome', config.basePath());
    //   })
    //   .catch((e) => console.warn('logout exception:', e));
  }

  /**
   *
   */
  @action
  requestPasswordReset = async ({ email, history }: any) => {
    if (!EMAIL_REGEX.test(email)) {
      this.currentUserData.setPasswordRequestActivityEmailFieldError(true);
      return this.currentUserData.setResetPasswordRequestError('setResetPasswordRequestError');
    }

    this.currentUserData.setResetPasswordRequestError(null);
    this.currentUserData.setPasswordRequestActivityEmailFieldError(false);
    this.currentUserData.setResetPasswordRequestInProgress(true);

    try {
      await basicRequestGet(`/api/integrations/element/password/reset/request/?email=${email}`);
    } catch (error: any) {
      this.currentUserData.setResetPasswordRequestInProgress(false);

      console.error(error.response.data);

      if (error.response.data.status == 'email_not_found') {
        return this.currentUserData.setResetPasswordRequestError(
          `setResetPasswordRequestError (1)`
        );
      }
      if (error.response.data.status == 'email_send_error') {
        return this.currentUserData.setResetPasswordRequestError(
          `setResetPasswordRequestError (2)`
        );
      }

      return this.currentUserData.setResetPasswordRequestError(`setResetPasswordRequestError (3)`);
    }

    this.currentUserData.clear();
    this.clearLocalStorageContext();

    history.push('/');
  };

  /**
   * Смена пароля
   */
  setInputForChangePassword = async ({ key, email, history }: any) => {
    if (
      !this.currentUserData.inputForChangePassword1 ||
      this.currentUserData.inputForChangePassword1.trim().length < 6 ||
      !this.currentUserData.inputForChangePassword2 ||
      this.currentUserData.inputForChangePassword2.trim().length < 6
    ) {
      return this.currentUserData.setInputForChangePasswordError(
        'setInputForChangePasswordError (1)'
      );
    }

    const password1 = this.currentUserData.inputForChangePassword1.trim();
    const password2 = this.currentUserData.inputForChangePassword2.trim();

    if (password1 != password2) {
      return this.currentUserData.setInputForChangePasswordError(
        'setInputForChangePasswordError (2)'
      );
    }

    this.currentUserData.setInputForChangePasswordError(null);
    this.currentUserData.setResetPasswordRequestInProgress(true);

    try {
      await basicRequestGet(
        `/api/integrations/element/password/reset/proceed/?email=${email}&key=${key}&password=${password1}`
      );
    } catch (error: any) {
      this.currentUserData.setResetPasswordRequestInProgress(false);

      console.error(error.response.data);

      switch (error.response.data.status) {
        case 'email_not_found':
          return this.currentUserData.setInputForChangePasswordError(
            'setInputForChangePasswordError (3)'
          );
        case 'password_error':
          return this.currentUserData.setInputForChangePasswordError(
            'setInputForChangePasswordError (4)'
          );
        case 'key_not_found':
          return this.currentUserData.setInputForChangePasswordError(
            'setInputForChangePasswordError (5)'
          );
        case 'users_not_found':
        case 'users_each_empty':
          return this.currentUserData.setInputForChangePasswordError(
            'setInputForChangePasswordError (6)'
          );
      }

      return this.currentUserData.setInputForChangePasswordError(
        'setInputForChangePasswordError (7)'
      );
    }

    this.currentUserData.clear();
    this.clearLocalStorageContext();

    history.push('/');
  };

  set usersPagination(val) {
    this._usersPagination = val;
  }

  get usersPagination() {
    return this._usersPagination;
  }

  /**
   *
   */
  async getUsers() {
    console.log('---- ww|getUsers');
    const nowdate = moment().valueOf();

    const res = await basicRequestPost('/admin/user/list', {});
    this.usersList = res.data.data.data.map((i: any, k: number) => {
      i.key = i._id;
      i.number = k + 1;
      i.tags = [];
      i.dformat = new Date(i.createdAt).toLocaleString();
      //i.date = moment.duration(nowdate - new Date(i.createdAt).valueOf()).days();
      i.date = moment(i.createdAt).locale('ru').format('DD.MM.YYYY • HH:mm');
      if (i.status == 'just_created') {
        i.status = 'Not verified';
        i.firstname = '';
        i.secondname = '';
      }
      return i;
    }) as UserDataType[];

    this.usersPagination = res.data.data.pagination;
  }

  /**
   *
   */
  async deleteUser(_id: string) {
    console.log('deleteUser|_id=', _id);
    if (this.changingUserData) {
      return;
    }
    this.changingUserData = true;
    const index = this.usersList.findIndex((obj) => obj.key === _id);
    console.log('deleteUser|index=', index);
    if (index === -1) {
      console.error('deleteUser|index !== -1');
      throw 'Remove error';
    }
    try {
      await basicRequestDelete('/admin/user/delete/' + _id);
      this.usersList = this.usersList.filter((obj) => obj.key !== _id);
    } catch (error) {
      console.error('---- ww|setAsAdmin|error=', error);
      throw error;
    } finally {
      this.changingUserData = false;
    }
  }

  /**
   *
   */
  async setAsAdmin(_id: string, checked: boolean) {
    console.log('setAsAdmin|_id=', _id);
    if (this.changingUserData) {
      return;
    }
    this.changingUserData = true;
    const index = this.usersList.findIndex((obj) => obj.key === _id);
    console.log('setAsAdmin|index=', index);
    if (index === -1) {
      console.error('deleteUser|index !== -1');
      throw 'Remove error';
    }
    try {
      await basicRequestPatch('/admin/user/set_as_admin/' + _id, { checked });
      this.usersList = this.usersList.map((obj) => {
        if (obj.key === _id) {
          obj.role = checked ? 'admin' : 'user';
        }
        return obj;
      });
    } catch (error) {
      console.error('---- ww|setAsAdmin|error=', error);
      throw error;
    } finally {
      this.changingUserData = false;
    }
  }

  /**
   *
   */
  async blockUser(_id: string, checked: boolean) {
    console.log('blockUser|_id=', _id);
    if (this.changingUserData) {
      return;
    }
    this.changingUserData = true;
    const index = this.usersList.findIndex((obj) => obj.key === _id);
    console.log('blockUser|index=', index);
    if (index === -1) {
      console.error('deleteUser|index !== -1');
      throw 'Remove error';
    }
    try {
      await basicRequestPatch('/admin/user/block/' + _id, { checked });
      this.usersList = this.usersList.map((obj) => {
        if (obj.key === _id) {
          obj.status = checked ? 'block' : 'verified';
        }
        return obj;
      });
    } catch (error) {
      console.error('---- ww|blockUser|error=', error);
      throw error;
    } finally {
      this.changingUserData = false;
    }
  }

  /**
   *
   */
  async editProfile(data: FieldTypetwo) {
    console.log('---- ww|editProfile');
    return await basicRequestPost('/user/profile/edit', data);
  }
}
