import { Image } from 'antd';
import { FC, Fragment } from 'react';
import { config } from '../../../../../../config';
import * as styles from './index.module.scss';

interface IProps {
  jobData?: { text: string; photoFiles: string[]; bitrixOrderData?: string }[];
}

const ReferenceBlock: FC<IProps> = ({ jobData }) => {
  return (
    <div className={styles.fieldsWrap}>
      <p className={styles.title}>ОПИСАНИЕ</p>
      <div className={styles.photosWrap}>
        {jobData?.[0]?.photoFiles?.map((url) => (
          <Image
            key={url}
            className={styles.photo}
            height={200}
            src={`${config.EXTERNAL_ADDRESS}/public/${url}`}
          />
        ))}
      </div>
      {jobData?.[0]?.text && (
        <div className={styles.additionalInfoBlock}>
          {jobData[0].text.split('\\n').map((line, i) => (
            <Fragment key={i}>
              {line}
              <br />
            </Fragment>
          ))}
        </div>
      )}
      {jobData?.[0]?.bitrixOrderData && (
        <div className={styles.additionalInfoBlock}>
          {jobData[0].bitrixOrderData
            .replace(/\[\/b\]/g, '')
            .replace(/\[b\]/g, '')
            .split('_')
            .map((line, i) => (
              <Fragment key={i}>
                {line}
                <br />
              </Fragment>
            ))}
        </div>
      )}
    </div>
  );
};

export default ReferenceBlock;
