import { Spin } from 'antd';
import * as React from 'react';
import * as styles from './Auth.module.scss';

const CheckingAuth: React.FC = () => (
  <div className={styles.loadingPage}>
    <div>
      <Spin />
      <p>Checking Authorization...</p>
    </div>
  </div>
);

export default CheckingAuth;
