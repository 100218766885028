import { Progress } from 'antd';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { config } from '../../../../../config';
import * as styles from './index.module.scss';
export type ButtonVariant = 'busy' | 'free' | 'work';

export interface CourierItemProps {
  title: string;
  percent: number;
  theme?: ButtonVariant;
  photo?: ReactNode;
}

const CourierItemProgress = ({ title, percent, photo, theme }: CourierItemProps) => {
  return (
    <div className={styles.bl_progress_course}>
      <div className={styles.progress_container}>
        <Progress
          size={42}
          type="circle"
          percent={percent}
          trailColor="#e5e5e5"
          strokeWidth={6}
          strokeColor={theme == 'free' ? '#21da4a' : '#1890ff'}
          format={() => (
            <div className={styles.progress_content}>
              {(photo && (
                <img src={`${config.EXTERNAL_ADDRESS}/image-uploader/${photo}`} alt="Avatar" />
              )) ||
                null}
            </div>
          )}
        />
        <p>{title}</p>
      </div>
    </div>
  );
};
export default observer(CourierItemProgress);
