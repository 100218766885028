import urlJoin from 'url-join';
import { config } from '../../config';
import { AuthStoreData } from '../Stores/AuthStoreDataClass';
import { HeaderStore } from '../Stores/HeaderStore';

export const makeUrl = (relativeUrl: string) => {
  return urlJoin(config.EXTERNAL_ADDRESS, relativeUrl);
};

export const makeConfig = (requestConfig: any) => {
  return {
    ...{ maxRedirects: 0 },
    ...requestConfig,
    headers: {
      'Accept-Language': HeaderStore.locale,
      ...AuthStoreData.authHeaders(),
      ...requestConfig.headers,
    },
  };
};
