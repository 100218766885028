import { ReactNode } from 'react';
import avatar from '../../../../../assets/save.svg';

interface CourierData {
  key: string;
  id: string;
  title: string;
  percent: number;
  theme?: 'free' | 'busy';
  image?: ReactNode;
}

export const COURIER_DATA: CourierData[] = [
  {
    key: '1',
    id: 'andrew',
    title: 'Андрей',
    percent: 20,
    theme: 'free',
    image: <img src={avatar} alt="Avatar" />,
  },
  {
    key: '2',
    id: 'max',
    title: 'Максим',
    percent: 35,
    theme: 'busy',
    image: <img src={avatar} alt="Avatar" />,
  },
];
