import { Avatar, DatePicker, Dropdown, Flex, Table, TableColumnsType } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Member, MemberUser, StagesDataType, Statistic } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import { config } from '../../../../config';
import uWS from '../../../../uWS';
import { $class } from '../../../../utils';
import Clickable from '../../../ui/Clickable';
import Icon from '../../../ui/Icon';
import * as styles from './index.module.scss';
import Chart from './Сhart';

type WorkspaceIdParams = {
  id?: string;
};

type DateRange = 'Сегодня' | 'Вчера' | 'Неделя' | 'Месяц' | 'Выбрать свой';

const { RangePicker } = DatePicker;

const Statistics: React.FC = () => {
  // const workspaceId = config.WORKSPACE_ID;
  const workspaceId = '66e170b52fc0a03d58789c24';

  const { id } = useParams<WorkspaceIdParams>();

  const [loading, setLoading] = useState(false);
  const [visibleCart, setVisibleCart] = useState(false);
  const [visibleCartStatic, setVisibleCartStatic] = useState(false);
  const [stages, setStages] = useState<StagesDataType[]>([]);
  const [selectedUser, setSelectedUser] = useState<MemberUser | null>(null);
  const [dateRange, setDateRange] = useState<DateRange>('Сегодня');
  const [customDateRange, setCustomDateRange] = useState<[Dayjs | null, Dayjs | null] | null>(null);

  useEffect(() => {
    if (!id) return;
    (async () => {
      setLoading(true);
      try {
        // if (!Object.keys(StagesStore.dataStatus)?.length) {
        //   await StagesStore.getUserStatus(id);
        // }
        const res = await WorkspacesStore.membersGet.call(id);
        WorkspacesStore.setMembers(res.data);
        console.log(res);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }

      try {
        const res = await WorkspacesStore.getJobsInWork.call(id);
        WorkspacesStore.setInWorkJobs(res.data);
        console.log(res.data, 'sfdfdff');
        console.log(res.data);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }

      await StagesStore.getList(id);
      setStages(StagesStore.dataList);
      setLoading(false);
    })();
  }, [id]);

  // вот это как пример подписки на смену статуса операторов в ра��очем пространстве
  useEffect(() => {
    if (!id) return;
    const eventStatus = uWS.subscribe(`workspaces/${id}/userOnline`, (data: any) => {
      if (data.error) {
        console.error('Error subscribing to workspace user online:', data.error);
        return;
      }

      console.log('Online user:', data);
      // OperatorsStore.setOnlineUser(data);
      (async () => {
        // setLoading(true);
        try {
          // await StagesStore.getUserStatus(id);
          const res = await WorkspacesStore.membersGet.call(id);
          WorkspacesStore.setMembers(res.data);
          console.log(res);
        } catch (e: any) {
          console.error(e);
          toast.error(e.message.message);
        }

        try {
          const res = await WorkspacesStore.getJobsInWork.call(id);
          WorkspacesStore.setInWorkJobs(res.data);
          console.log(res.data);
        } catch (e: any) {
          console.error(e);
          toast.error(e.message.message);
        }

        await StagesStore.getList(id);
        setStages(StagesStore.dataList);
        setLoading(false);
      })();
    });

    return () => {
      if (eventStatus) uWS.unSubscribe(`workspaces/${id}/userOnline`);
    };
  }, [id]);
  const getDateRange = (range: string) => {
    let startDate, endDate;

    switch (range) {
      case 'Сегодня':
        startDate = dayjs().startOf('day').toDate();
        endDate = dayjs().endOf('day').toDate();
        break;

      case 'Вчера':
        startDate = dayjs().subtract(1, 'day').startOf('day').toDate();
        endDate = dayjs().subtract(1, 'day').endOf('day').toDate();
        break;

      case 'Неделя':
        startDate = dayjs().subtract(6, 'day').startOf('day').toDate();
        endDate = dayjs().endOf('day').toDate();
        break;

      case 'Месяц':
        startDate = dayjs().subtract(30, 'day').startOf('day').toDate();
        endDate = dayjs().toDate();
        break;

      case 'Выбрать свой':
        if (customDateRange && customDateRange[0] && customDateRange[1]) {
          startDate = customDateRange[0].startOf('day').toDate();
          endDate = customDateRange[1].endOf('day').toDate();
        } else {
          startDate = dayjs().toDate();
          endDate = dayjs().toDate();
        }
        break;

      default:
        startDate = dayjs().toDate();
        endDate = dayjs().toDate();
    }

    return { startDate, endDate };
  };

  const handleDateRange = (value: DateRange) => {
    if (!selectedUser) return;
    setDateRange(value);

    const { startDate, endDate } = getDateRange(value);
    StagesStore.getUserStatistic(workspaceId, selectedUser._id, startDate, endDate).catch((e) => {
      console.error(e);
    });

    console.log(startDate, endDate, 'startDaddddte');
  };

  // Функция для обработки пользовательского диапазона дат
  const handleCustomDateRange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    setCustomDateRange(dates);

    if (!selectedUser || !dates || !dates[0] || !dates[1]) return;
    const startDate = dates[0].startOf('day').toDate();
    const endDate = dates[1].endOf('day').toDate();

    StagesStore.getUserStatistic(workspaceId, selectedUser._id, startDate, endDate).catch((e) => {
      console.error(e);
    });
  };

  const handleRowClick = (record: any) => {
    setVisibleCart(true);
    setSelectedUser(record.userId);

    console.log(record.userId, 'record.userId');

    const { startDate, endDate } = getDateRange(dateRange);
    StagesStore.getUserStatistic(workspaceId, record.userId._id, startDate, endDate).catch((e) => {
      console.error(e);
    });
  };

  const columns: TableColumnsType<Member> = [
    {
      title: 'Имя',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text: any, record: Member) => (
        <div className={styles.nameBlock}>
          {record.userId.photo && (
            <Avatar
              src={`${config.EXTERNAL_ADDRESS}/image-uploader/${record.userId.photo}`}
              shape="circle"
              size={40}
            />
          )}
          {!record.userId.photo && (
            <div>
              <div className={styles.photo}>{record.userId.firstname[0]}</div>
            </div>
          )}
          <div>
            <p className={styles.name}>{record.userId.firstname}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: Member) => (
        <div
          className={$class(
            styles.status,
            [styles.online, record.onlineStatus === 'online'],
            [styles.offline, record.onlineStatus === 'offline'],
            [styles.inactive, record.onlineStatus === 'inactive' || record.onlineStatus === 'away']
          )}
        >
          <div />
          {record.onlineStatus === 'online'
            ? 'Онлайн'
            : record.onlineStatus === 'offline'
              ? 'Оффлайн'
              : record.onlineStatus === 'away'
                ? 'Нет на месте'
                : ''}
        </div>
      ),
    },
  ];

  const columns2: TableColumnsType<Statistic> = [
    {
      title: 'JobId',
      dataIndex: 'jobId',
      key: 'jobId',
      render: (text: any, record: Statistic) => <p className={styles.name}>{record.jobId}</p>,
    },

    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: any, record: Statistic) => (
        <p className={styles.name}> {dayjs(record.createdAt).format('DD / MM / YYYY')}</p>
      ),
    },
    {
      title: 'Время',
      dataIndex: 'createdAt',
      key: 'createdAt',

      render: (text: any, record: Statistic) => (
        <p className={styles.name}> {Math.round((record.duration / 60) * 100) / 100} мин.</p>
      ),
      sorter: (a, b) => a.duration / 60 - b.duration / 2,
    },
  ];

  useEffect(() => {
    console.log('Selected User:', selectedUser?._id);

    if (selectedUser) {
      handleDateRange(dateRange);
    }
  }, [dateRange, selectedUser]);

  const UserDuration: React.FC = () => {
    const [averageDuration, setAverageDuration] = useState<string>('0,00');

    useEffect(() => {
      const userStatistics = StagesStore.dataUserStatistic;

      if (userStatistics.length > 0) {
        const totalDuration = userStatistics.reduce((total, item) => total + item.duration, 0);
        const average = totalDuration / userStatistics.length;
        const result = (average / 60).toFixed(2).replace('.', ',');
        setAverageDuration(result);
      } else {
        setAverageDuration('0,00');
      }
    }, [StagesStore.dataUserStatistic, selectedUser]);

    return <span>{averageDuration}</span>;
  };

  return (
    <div className={styles.membersPage}>
      <div className={styles.membersPageTop}>
        <p className={styles.title}>Статистика пользователей</p>
      </div>
      <div className={styles.block_stat}>
        <div className={styles.table_bl}>
          <p className={styles.title_ad + ' mb-16'}>Список всех пользователей</p>
          <Table
            className={styles.table}
            columns={columns}
            pagination={false}
            dataSource={Object.values(WorkspacesStore.members)}
            loading={loading}
            scroll={{ y: 480 }}
            rowKey="_id"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}

            // onRow={(record) => ({
            //   onClick: () => {
            //     setVisibleCart(true);
            //     setSelectedUser(record.userId);
            //     StagesStore.getUserStatistic(
            //       workspaceId,
            //       record.userId._id,
            //       dayjs().toDate(),
            //       dayjs().toDate()
            //     ).catch((e) => {
            //       console.error(e);
            //     });
            //   },
            // })}
          />
        </div>
        {visibleCart && (
          <div className={styles.second_bl}>
            <Flex vertical gap={16}>
              <div className={styles.flex}>
                <p className={styles.title_ad}>
                  Пользователь:
                  <br />
                  {selectedUser ? selectedUser.firstname : ''}
                </p>
                <span onClick={() => setVisibleCart(false)}>
                  <Icon slug="close" />
                </span>
              </div>

              <Flex vertical gap={8}>
                <div className={styles.rangePickerWrap}>
                  <p className={styles.name}>Диапазон:</p>
                  <Dropdown
                    menu={{
                      items: [
                        { key: 1, label: 'Сегодня', onClick: () => handleDateRange('Сегодня') },
                        { key: 2, label: 'Вчера', onClick: () => handleDateRange('Вчера') },
                        { key: 3, label: 'Неделя', onClick: () => handleDateRange('Неделя') },
                        { key: 4, label: 'Месяц', onClick: () => handleDateRange('Месяц') },
                        {
                          key: 5,
                          label: 'Выбрать свой',
                          onClick: () => handleDateRange('Выбрать свой'),
                        },
                      ],
                    }}
                    trigger={['click']}
                  >
                    <Clickable className={styles.rangePickerBtn}>
                      {dateRange} <Icon slug="angle_down" color="#F1B729" />
                    </Clickable>
                  </Dropdown>
                </div>
                <div className={styles.rangeBlock}>
                  {dateRange === 'Сегодня' && dayjs().format('DD / MM / YYYY')}
                  {dateRange === 'Вчера' && dayjs().subtract(1, 'day').format('DD / MM / YYYY')}
                  {dateRange === 'Неделя' && (
                    <>
                      {dayjs().subtract(6, 'day').format('DD / MM / YYYY')}
                      <span>—</span>
                      {dayjs().format('DD / MM / YYYY')}
                    </>
                  )}
                  {dateRange === 'Месяц' && (
                    <>
                      {dayjs().subtract(30, 'day').format('DD / MM / YYYY')}
                      <span>—</span>
                      {dayjs().format('DD / MM / YYYY')}
                    </>
                  )}
                  {dateRange === 'Выбрать свой' && (
                    <RangePicker
                      value={customDateRange}
                      onChange={(dates) => handleCustomDateRange(dates)}
                    />
                  )}
                </div>
              </Flex>
            </Flex>

            <Flex vertical gap={24}>
              <div
                onClick={() => setVisibleCartStatic(true)}
                className={visibleCartStatic ? styles.card + ' ' + styles.active : styles.card}
              >
                <p>
                  <Icon slug="cake" />
                  Выполнено (шт.)
                </p>
                <span>{StagesStore.dataUserStatistic.length}</span>
              </div>
              <div className={styles.card}>
                <p>
                  <Icon slug="clock" />
                  Среднее время (мин.)
                </p>
                <UserDuration />
              </div>
            </Flex>
          </div>
        )}
        {visibleCartStatic && (
          <div className={styles.thid_bl}>
            <div className={styles.flex}>
              <p className={styles.title_ad}>Статистика заказов</p>
              <span onClick={() => setVisibleCartStatic(false)}>
                <Icon slug="close" />
              </span>
            </div>

            <Table
              className={styles.table}
              columns={columns2}
              pagination={false}
              dataSource={StagesStore.dataUserStatistic}
              loading={loading}
              showSorterTooltip={{ target: 'sorter-icon' }}
              scroll={{ y: 480 }}
              rowKey="_id1"
            />
          </div>
        )}
      </div>
      <Chart />
    </div>
  );
};

export default observer(Statistics);
