import { EllipsisOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthStore } from '../../DataSource/Stores/AuthStore';
import logo from '../../assets/Logo-new.svg';
import { config } from '../../config';
import Lang from '../Lang/index';
import Clickable from '../ui/Clickable';
import * as styles from './Header.module.scss';
type WorkspaceIdParams = {
  id?: string;
};
const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { id } = useParams<WorkspaceIdParams>();
  const navigate = useNavigate();

  const logOut = () => {
    console.log('User logged out');
    AuthStore.logout();
    navigate('/');
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a rel="noopener noreferrer" href={'/workspaces/' + id + '/profile'}>
          {Lang({ str: 'profile', section: 'default' })}
        </a>
      ),
    },
    {
      key: '2',
      label: <a onClick={() => setOpen(true)}>{Lang({ str: 'exit', section: 'default' })}</a>,
    },
  ];

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const fullName = `${AuthStore.currentUserData.user.firstname} ${AuthStore.currentUserData.user.secondname}`;

  return (
    <>
      <div className={styles.header}>
        {id === config.WORKSPACE_ID ? (
          <img className={styles.logo} src={logo} alt="" />
        ) : (
          <p>{/* <i className="fi fi-rr-truck-side"></i> Delivery */}</p>
          // <img className={styles.logo} src={''} alt="" />
        )}

        <div className={styles.flex_bl}>
          <div className={styles.userCard}>
            <p>{fullName}</p>

            <Dropdown trigger={['click']} menu={{ items }}>
              <Clickable className={styles.dropdownBtn}>
                <EllipsisOutlined />
              </Clickable>
            </Dropdown>
          </div>
        </div>
      </div>
      <Modal
        title=""
        open={open}
        closable={false}
        onOk={logOut}
        onCancel={() => setOpen(false)}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to leave the site?</p>
      </Modal>
    </>
  );
};
export default observer(Header);
