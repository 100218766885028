import { Input, Modal, message, Flex } from 'antd';
import { FC, ReactNode, useEffect, useState } from 'react';
import { StagesDataType } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import Button from '../../../ui/Button';
import IconButton from '../../../ui/IconButton';
import StageDrawer from '../../StageDrawer';
import * as styles from './index.module.scss';

interface IProps extends StagesDataType {
  variant: 'ZakazBuketov' | 'DeliveryCenter';
  children: ReactNode;
}

const Column: FC<IProps> = ({ variant, children, _id, name }) => {
  const [password, setPassword] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setPassword('');
  }, [modalOpen]);

  const handlePassword = () => {
    //защита от дураков
    if (password !== '112255') {
      message.error('Неверный пароль');
      return;
    }
    setModalOpen(false);
    setDrawerOpen(true);
  };

  const stage = StagesStore.dataList.find((stage) => stage._id === _id);

  return (
    <>
      <div className={styles.column}>
        <div className={styles.columnTop}>
          <Flex align="center" gap={8}>
            <IconButton
              className={styles.settingsBtn}
              icon="cog"
              variant="clear"
              onClick={() => setModalOpen(true)}
            />
            <p className={styles.name}>{name}</p>
          </Flex>
          <p className={styles.amount}>
            {StagesStore.dataJobsList?.filter((job) => job.stageId === _id).length}
          </p>
        </div>
        {children && children}
      </div>

      <Modal open={modalOpen} onCancel={() => setModalOpen(false)} footer={null}>
        <div className={styles.passwordModal}>
          <Input
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {variant === 'ZakazBuketov' && <Button onClick={handlePassword}>Продолжить</Button>}

          {variant === 'DeliveryCenter' && (
            <Button variant="delivery_primary" onClick={handlePassword}>
              Продолжить
            </Button>
          )}
        </div>
      </Modal>

      {stage && <StageDrawer isOpen={drawerOpen} onClose={() => setDrawerOpen(false)} {...stage} />}
    </>
  );
};

export default Column;
