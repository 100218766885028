import { Result, Spin } from 'antd';
import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { Task } from '../../ types';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import * as styles from '../DistributionCenter/User/index.module.scss';
import SelectedTask from './ SelectedTask';
import TaskItem from './TaskItem';
interface IProps {
  workspaceId: string;
}
const TaskTab: FC<IProps> = ({ workspaceId }) => {
  const handleTaskItemClick = (task: Task) => {
    StagesStore.setShowTextTask(true);
    StagesStore.setLayoutPage('editor');
    StagesStore.setSelectedTask(task);
    StagesStore.setIsSelectedTask(true);
  };

  useEffect(() => {
    if (!StagesStore.selectedTask?._id) return;
    StagesStore.getMyTaskOpen(workspaceId, StagesStore.selectedTask?._id).catch((e) => {
      console.error(e);
    });
  }, [StagesStore.selectedTask]);

  return (
    <div className={styles.prompt_wrap}>
      <div className={styles.task_flex}>
        {StagesStore.layoutPage === 'library' ? (
          <div className={styles.task_child_flex}>
            {StagesStore.loadingErrorMyTasks && 'Error loading'}
            {StagesStore.loadingMyTasks && StagesStore.dataMyTasks?.length === 0 ? (
              <Spin size="small" />
            ) : StagesStore.dataMyTasks?.length === 0 ? (
              <div className={styles.no_tasks}>
                <Result status="warning" title="У Вас пока нет задач" />
              </div>
            ) : (
              StagesStore.dataMyTasks?.map((task_i) => (
                <TaskItem
                  key={task_i._id}
                  _id={task_i._id}
                  onClick={() => {
                    handleTaskItemClick(task_i);
                  }}
                  dueDate={task_i.dueDate}
                  createdAt={task_i.createdAt}
                  title={task_i.title || ''}
                  status={''}
                  description={task_i.description || ''}
                  stageName={task_i.stageName || ''}
                  comments={task_i.comments || []}
                  workspaceId={workspaceId}
                  jobId={task_i.jobId}
                  jobIdShort={task_i.jobIdShort}
                  expectedDate={task_i.expectedDate}
                  isSelectedTask={
                    StagesStore.isSelectedTask && StagesStore.selectedTask?._id === task_i._id
                  }
                />
              ))
            )}
          </div>
        ) : (
          <SelectedTask workId={workspaceId} />
        )}
      </div>
    </div>
  );
};

export default observer(TaskTab);
