import { Avatar } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { AuthStore } from '../../../../DataSource/Stores/AuthStore';
import { config } from '../../../../config';
import Button from '../../../ui/Button';
import Icon from '../../../ui/Icon';
import StatusProf from '../../StatusProf';
import EditProfile from './EditProfile';
import * as styles from './index.module.scss';

export type FieldTypetwo = {
  firstname: string;
  secondname: string;
  password: string;
  email: string;
  confirmpassword: string;
  token?: string;
};

const Profile: React.FC = () => {
  const [view, setView] = useState<'read' | 'edit'>('read');
  const user = AuthStore.currentUserData.user;

  if (view === 'edit')
    return (
      <div>
        <EditProfile onBack={() => setView('read')} />
      </div>
    );

  return (
    <div>
      <div className={styles.userCard}>
        <div className={styles.userCardWrap}>
          {/* <ImageUpload
          image={`${config.EXTERNAL_ADDRESS}/image-uploader/${AuthStore.currentUserData.user.photo}`}
        /> */}
          <div className={styles.userCardAvatar}>
            <Avatar
              size={100}
              icon={<Icon slug="user" size={30} color="#cecece" />}
              src={`${config.EXTERNAL_ADDRESS}/image-uploader/${AuthStore.currentUserData.user.photo}`}
              style={{ backgroundColor: '#fafafa' }}
            />
          </div>

          <div>
            <p>Имя</p>
            <div>
              {user.firstname} <span>({user.email})</span>
            </div>
          </div>

          <div>
            <p>Телефон</p>
            <div>{user.phoneNumber || '-'}</div>
          </div>

          <div></div>

          <Button onClick={() => setView('edit')} variant="delivery_primary">
            Редактировать профиль
          </Button>
        </div>
      </div>
      {location.pathname.includes('/user') && (
        <>
          <h4 className={styles.userStatusLabel}>Статус</h4>
          <StatusProf className={styles.userStatus} />
        </>
      )}
    </div>
  );
};
export default observer(Profile);
