import axios from 'axios';
import _get from 'lodash/get';
import { makeAutoObservable, observable } from 'mobx';

//
// конфиг будет загружаться из адреса, указанного в переменной window.oncoParams.сonfigPath
// это сделано для того, чтобы можно было подменять адрес конфига
//
// если адрес не будет указан - по умолчанию /config.json (именно из корня)
// (!) не пытайтесь делать относительные пути
//
class ConfigStoreClass {
  //
  @observable loaded: boolean = false;

  @observable config: any = {};

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  basePath = () => {
    return _get(window, 'oncoParams.basePath', '').replace(/\/+$/, '') + '/';
  };

  configPath = () => {
    return '/config.json';
  };

  get = (paramName: string, defaultValue?: string) => {
    if (Object.keys(this.config).indexOf(paramName) < 0) {
      if (typeof defaultValue !== 'undefined') {
        return defaultValue;
      }
      console.warn(`parameter ${paramName} not found at config and default value is not provided`);
      return undefined;
    }

    // @ts-ignore
    return this.config[paramName];
  };

  load = async (): Promise<void> => {
    const url = this.configPath() + '?d=' + Math.random();
    console.log('url=', url);

    // no axios wrapper - public endpoint
    try {
      const response = await axios.get(url);
      this.config = response.data;
      console.log('this.config=', this.config);
      this.loaded = true;
    } catch (error) {
      console.error('load config error:', error);
      throw error;
    }
  };

  /**
   * некоторые кастомные конфиги нужно просто хардкодно перезаписать
   */
  override = (values: string) => {
    console.log('overriding config with these values:', values);
    // @ts-ignore
    this.config = { ...this.config, ...values };
  };

  get EXTERNAL_ADDRESS() {
    return this.get('EXTERNAL_ADDRESS_DEV', this.get('EXTERNAL_ADDRESS', ''));
  }

  get UWA_HOST() {
    return this.get('UWA_HOST');
  }
  get CEH_STAGE() {
    return this.get('CEH_STAGE');
  }
  get NEW_STAGE() {
    return this.get('NEW_STAGE');
  }

  get WORKSPACE_ID() {
    return this.get('WORKSPACE_ID');
  }

  get WORKSPACE_ID_DELIVERY() {
    return this.get('WORKSPACE_ID_DELIVERY');
  }

  get BASE_URL() {
    return this.get('BASE_URL', '') || `${this.EXTERNAL_ADDRESS}`;
  }

  get BACKEND_BASE_URL() {
    return this.get('BACKEND_BASE_URL', '') || `${this.EXTERNAL_ADDRESS}backend-mm/`;
  }
}

export { ConfigStoreClass };
