// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R31AIlOmmNbKwXv6Xmye{display:flex;flex-direction:column;gap:10px;padding:0}.I7CUnvwkksd3mweUXg6S{display:flex;align-items:center;gap:12px}.I7CUnvwkksd3mweUXg6S .hPaI539l8HEW3Rd5otFI{height:35px;background-color:#fff;border:1px solid #e0e0e0;border-radius:8px;box-shadow:0 2px 2px 0 #eaeaea;color:rgba(42,48,56,.4);font-size:14px;font-weight:800;text-transform:uppercase;display:flex;align-items:center;justify-content:center;padding:0 16px}.I7CUnvwkksd3mweUXg6S .YILOT2rQ1bxJStnUOzG7>input{height:35px;color:rgba(151,151,151,.5019607843);font-size:15px}.R31AIlOmmNbKwXv6Xmye td{font-size:18px}.hEaTOOdV_qsP0BNzdPAA{display:flex;align-items:center;justify-content:center;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/History/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,SAAA,CAEA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,4CACE,WAAA,CACA,qBAAA,CACA,wBAAA,CACA,iBAAA,CACA,8BAAA,CACA,uBAAA,CACA,cAAA,CACA,eAAA,CACA,wBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CAGF,kDACE,WAAA,CACA,mCAAA,CACA,cAAA,CAIJ,yBACE,cAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA","sourcesContent":[".historyPage{display:flex;flex-direction:column;gap:10px;padding:0}.historyPageTop{display:flex;align-items:center;gap:12px}.historyPageTop .title{height:35px;background-color:#fff;border:1px solid #e0e0e0;border-radius:8px;box-shadow:0 2px 2px 0 #eaeaea;color:rgba(42,48,56,.4);font-size:14px;font-weight:800;text-transform:uppercase;display:flex;align-items:center;justify-content:center;padding:0 16px}.historyPageTop .searchInput>input{height:35px;color:rgba(151,151,151,.5019607843);font-size:15px}.historyPage td{font-size:18px}.copyBtn{display:flex;align-items:center;justify-content:center;cursor:pointer}"],"sourceRoot":""}]);
// Exports
export var historyPage = `R31AIlOmmNbKwXv6Xmye`;
export var historyPageTop = `I7CUnvwkksd3mweUXg6S`;
export var title = `hPaI539l8HEW3Rd5otFI`;
export var searchInput = `YILOT2rQ1bxJStnUOzG7`;
export var copyBtn = `hEaTOOdV_qsP0BNzdPAA`;
export default ___CSS_LOADER_EXPORT___;
