import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import uWS from '../../../uWS';
// import NewJobsBlock from '../NewJobsBlock';
// import styles from './index.module.scss';
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';
import { WorkspacesStore } from '../../../DataSource/Stores/Cabinet/WorkspacesStore';
import DeliveryCenter from '../DeliveryCenter';
import DistributionCenter from '../DistributionCenter';
import ShokolatyeCex from '../ShokolatyeCex';

type WorkspaceIdParams = {
  id?: string;
};

const Workspaces = () => {
  const { id } = useParams<WorkspaceIdParams>();
  // const [view, setView] = useState<'list' | 'columns'>('columns');
  // const navigate = useNavigate();
  useEffect(() => {
    if (!id) return;
    (async () => {
      if (!WorkspacesStore.dataListWork?.length) {
        await WorkspacesStore.getList();
      }

      try {
        const res = await WorkspacesStore.membersGet.call(id);
        WorkspacesStore.setMembers(res.data);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }
    })();
  }, [id]);

  const workspace = WorkspacesStore.dataListWork.find((el) => el._id === id);

  return (
    <>
      {id &&
        id == '66e170b52fc0a03d58789c24' &&
        workspace &&
        workspace.category === 'order_management' && (
          <DistributionCenter workspaceId={id} name={workspace.name} />
        )}
      {id &&
        id == '67220912e6bd539b6a9143bd' &&
        workspace &&
        workspace.category === 'order_management' && (
          <DistributionCenter workspaceId={id} name={workspace.name} />
        )}
      {id &&
        id == '6707a212598d48bda5860bf0' &&
        workspace &&
        workspace.category === 'delivery_center' && (
          <DeliveryCenter workspaceId={id} name={workspace.name} />
        )}
      {id &&
        workspace &&
        id === '6732ed08a29f2065aeb56662' &&
        workspace.category !== 'delivery_center' && (
          <ShokolatyeCex workspaceId={id} name={workspace.name} />
        )}
    </>
  );
};

export default observer(Workspaces);
