import dayjs from 'dayjs';
import moment from 'moment';

export function toLocalDate(utcDate: string) {
  return moment.utc(utcDate).local().format('YYYY-MM-DD HH:mm:ss');
}

export function formatDate(date: string) {
  return dayjs(date).format('HH : mm・MM / DD / YYYY')
}
