import {
  closestCorners,
  defaultDropAnimationSideEffects,
  DndContext,
  DragEndEvent,
  DraggableAttributes,
  DraggableSyntheticListeners,
  DragMoveEvent,
  DragOverlay,
  DropAnimation,
  MeasuringStrategy,
  UniqueIdentifier,
  useDraggable,
  useDroppable,
} from '@dnd-kit/core';

import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';

import { createPortal } from 'react-dom';
import { AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import { List } from 'react-virtualized';
import { $class } from '../../../utils';
import Button from '../../ui/Button';
import Icon from '../../ui/Icon';
import * as styles from './index.module.scss';

// {/* <JobsKanbanDeck
//   columns={StagesStore.dataList}
//   items={StagesStore.dataJobsList}
//   columnKeyField={'stageId'}
//   renderColumn={(column) => <div>{column.name}</div>}
//   onDrop={onDropHandler}
// >
//   {(item, listeners) => (
//     <JobCardItem job={item} withHandle listeners={listeners} />
//   )}
// </JobsKanbanDeck> */}

interface UniqueItem {
  _id: string;
}

type RenderItemFunc<T> = (
  item: T,
  dragProps?: DraggableSyntheticListeners & DraggableAttributes
) => JSX.Element;

type DeckSortFunc<T> = (a: T, b: T) => number;

interface IProps<T extends UniqueItem, D extends UniqueItem> {
  items: T[];
  onDrop?: (item: T, column: D) => void;
  columnKeyField: Extract<keyof T, string>;
  columns: D[];
  renderColumn: (column: D) => JSX.Element;
  children: RenderItemFunc<T>;
  sort?: DeckSortFunc<T>;
  style?: CSSProperties;
  rowHeight?: number | ((item: T) => number);
  variant: 'ZakazBuketov' | 'DeliveryCenter';
}

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.1',
      },
    },
  }),
};

const JobsKanbanDeck = <T extends UniqueItem, D extends UniqueItem>({
  items,
  onDrop,
  columns,
  columnKeyField,
  renderColumn,
  children: renderItem,
  sort,
  style,
  rowHeight = 100,
  variant,
}: IProps<T, D>) => {
  const wrap = useRef<HTMLDivElement | null>(null);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const activeItem = useMemo(() => {
    return items.find((item) => item._id === activeId);
  }, [activeId]);

  const groupedItems = useMemo(() => {
    const record = items.reduce(
      (acc, item) => {
        const key = item[columnKeyField] as string;

        if (key in acc) {
          acc[key].push(item);
        } else {
          acc[key] = [item];
        }

        return acc;
      },
      {} as Record<string, T[]>
    );

    if (sort) {
      for (const key in record) {
        record[key] = record[key].sort(sort);
      }
    }

    return record;
  }, [items, columnKeyField, sort]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    setActiveId(null);

    const item = items.find((x) => active.id === x._id);
    const column = columns.find((x) => over?.id === x._id);

    if (!item || !column) return;

    if (item[columnKeyField] === column?._id) {
      console.log('SAME STAGE');
      return;
    }

    onDrop && onDrop(item, column);

    setTimeout(() => {
      if (
        active.data.current?.index !== undefined &&
        active.data.current?.list?.current?.scrollToRow
      ) {
        console.log('RESET SCROLL', active.data.current);
        active.data.current.list.current.scrollToRow(active.data.current.index);
      }
    }, 10);
  };

  const onDragMove = ({ activatorEvent }: DragMoveEvent) => {};

  const mouse = useRef<{ x: number; y: number }>(null);

  const onDroppableOver = (column: D) => {
    const el = document.querySelector(`[data-column-id="${column._id}"]`);
    if (!el || !wrap.current) return;

    el.scrollIntoView();
  };

  const onToScrollHandler = (dir = 1) => {
    if (wrap.current) {
      wrap.current.scrollTo({
        left: wrap.current.scrollLeft + window.innerWidth * 0.5 * dir,
        behavior: 'smooth',
      });
    }
  };

  return (
    <DndContext
      autoScroll={{ enabled: false }}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      collisionDetection={closestCorners}
      onDragStart={({ active }) => {
        if (!active) {
          return;
        }

        setActiveId(active.id);
      }}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
      onDragCancel={() => setActiveId(null)}
    >
      <div>
        <NavButton onClick={() => onToScrollHandler(-1)} />
        <NavButton onClick={() => onToScrollHandler()} />
      </div>

      <div ref={wrap} className={styles.wrap} style={style}>
        {columns.map((column) => (
          <div key={column._id} className={styles.column}>
            <div>{renderColumn(column)}</div>
            <ColumnList
              renderItem={renderItem}
              column={column}
              items={groupedItems[column._id] || []}
              rowHeight={rowHeight}
              onDroppableOver={onDroppableOver}
              variant={variant}
            />
          </div>
        ))}
      </div>
      {createPortal(
        <DragOverlay dropAnimation={dropAnimationConfig}>
          {activeItem ? renderItem(activeItem) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};

const ColumnList = <T extends UniqueItem, D extends UniqueItem>({
  items,
  column,
  renderItem,
  rowHeight,
  onDroppableOver,
  variant,
}: {
  items: T[];
  column: D;
  renderItem: RenderItemFunc<T>;
  rowHeight: number | ((item: T) => number);
  onDroppableOver?: (column: D) => void;
  variant: 'ZakazBuketov' | 'DeliveryCenter';
}) => {
  const list = useRef<List | null>(null);
  const { setNodeRef, isOver } = useDroppable({
    id: column._id,
    data: {
      list,
    },
  });

  useEffect(() => {
    if (isOver && onDroppableOver) {
      onDroppableOver(column);
    }
  }, [isOver]);

  const cache = useMemo(() => {
    return new CellMeasurerCache({ fixedWidth: true, keyMapper: (index) => items[index] });
  }, [items]);

  useEffect(() => {
    list.current?.recomputeRowHeights();
  }, [cache, list]);

  return (
    <div
      data-column-id={column._id}
      className={$class(styles.columnList, isOver && styles.columnList_Over)}
      ref={setNodeRef}
      style={
        {
          '--height':
            variant === 'ZakazBuketov' ? '120px' : variant === 'DeliveryCenter' ? '200px' : null,
        } as React.CSSProperties
      }
    >
      {/* <button onClick={() => setCount((x) => x + 1)}>{count}</button> */}
      {/* <button onClick={() => list.current?.recomputeRowHeights()}>Refresh</button> */}
      {/* <button onClick={() => cache.clearAll()}>Cache X</button> */}
      {/* <button onClick={() => console.log(cache.getHeight(5, 0))}>Cache Get</button> */}
      {/* <button onClick={() => list.current?.scrollToRow}>Scroll to</button> */}
      <AutoSizer>
        {({ width, height }) => (
          <List
            ref={list}
            width={width}
            height={height}
            rowCount={items.length}
            deferredMeasurementCache={cache}
            rowHeight={(params) =>
              typeof rowHeight === 'number' ? rowHeight : rowHeight(items[params.index])
            }
            className={styles.virtualized}
            rowRenderer={({ index, key, style, parent }) => (
              <ColumnItem
                listRef={list}
                key={key}
                index={index}
                style={style}
                parent={parent}
                item={items[index]}
                cache={cache}
                renderItem={renderItem}
              />
            )}
          />
        )}
      </AutoSizer>
    </div>
  );
};

interface ColumnItem<T> {
  item: T & { _id: string };
  renderItem: RenderItemFunc<T>;
  style?: CSSProperties;
  parent?: any;
  cache: CellMeasurerCache;
  index?: number;
  listRef?: any;
}

const ColumnItem = <T,>({
  listRef,
  index,
  item,
  renderItem,
  style,
  parent,
  cache,
}: ColumnItem<T>) => {
  const { isDragging, setNodeRef, listeners, attributes } = useDraggable({
    id: item._id,
    data: {
      index,
      list: listRef,
    },
  });

  return (
    <CellMeasurer parent={parent} cache={cache} columnIndex={0} rowIndex={index}>
      <div
        ref={setNodeRef}
        data-index={index}
        style={{
          ...style,
          opacity: isDragging ? 0.5 : 1,
          width: '100%',
        }}
      >
        {renderItem(item, { ...listeners, ...attributes } as DraggableSyntheticListeners &
          DraggableAttributes)}
      </div>
    </CellMeasurer>
  );
};

const NavButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      rippleColor="grey"
      width={42}
      variant="outlined"
      className={styles.nav}
      onClick={onClick}
    >
      <Icon color="grey" size={30} slug="chevronLeft" />
    </Button>
  );
};

export default JobsKanbanDeck;
