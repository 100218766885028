// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CfWmC62QLq8PfkpoIN7c{margin:0;font-weight:600;display:flex}.CfWmC62QLq8PfkpoIN7c p{padding:4px 10px;font-weight:400;border-radius:20px;margin:0;font-size:15px}.CfWmC62QLq8PfkpoIN7c.oTFC1mj5LPf6b5tvyt_w p{color:#ff9914;background:rgba(255,153,20,.1)}.CfWmC62QLq8PfkpoIN7c.WTqAzyOFesswupNIt9zI p{color:#74c978;background:rgba(116,201,120,.1)}.CfWmC62QLq8PfkpoIN7c.Pz47vAD1v464_krCZnEg p{color:#878787;background:rgba(75,77,76,.1)}.CfWmC62QLq8PfkpoIN7c.oocAJBmdltfzA2MZNJ5U p{color:#e9d309;background:rgba(232,210,8,.1)}.W25PAfy0FlOJUDIcVk5Z{position:relative}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/StatusTag/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CACA,eAAA,CACA,YAAA,CAEA,wBACE,gBAAA,CACA,eAAA,CACA,kBAAA,CACA,QAAA,CACA,cAAA,CAGA,6CACE,aAAA,CAEA,8BAAA,CAIF,6CACE,aAAA,CAEA,+BAAA,CAIF,6CACE,aAAA,CAEA,4BAAA,CAIF,6CACE,aAAA,CAEA,6BAAA,CAIN,sBACE,iBAAA","sourcesContent":[".text_block{margin:0;font-weight:600;display:flex}.text_block p{padding:4px 10px;font-weight:400;border-radius:20px;margin:0;font-size:15px}.text_block.work p{color:#ff9914;background:rgba(255,153,20,.1)}.text_block.create p{color:#74c978;background:rgba(116,201,120,.1)}.text_block.unactive p{color:#878787;background:rgba(75,77,76,.1)}.text_block.indelivery p{color:#e9d309;background:rgba(232,210,8,.1)}.theme{position:relative}"],"sourceRoot":""}]);
// Exports
export var text_block = `CfWmC62QLq8PfkpoIN7c`;
export var work = `oTFC1mj5LPf6b5tvyt_w`;
export var create = `WTqAzyOFesswupNIt9zI`;
export var unactive = `Pz47vAD1v464_krCZnEg`;
export var indelivery = `oocAJBmdltfzA2MZNJ5U`;
export var theme = `W25PAfy0FlOJUDIcVk5Z`;
export default ___CSS_LOADER_EXPORT___;
