// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D3edQ1eR8QCRgYMxVKnq{display:flex;align-items:center;column-gap:8px;flex-wrap:wrap}.D3edQ1eR8QCRgYMxVKnq p{font-family:"Mulish",sans-serif;border-radius:8px;font-size:10px;font-weight:700;padding:4px 6px;margin-bottom:4px}.rqlKEBZlazBKo1cE2NiC{background:#ff7a00;color:#fff}.ca_pTveiWgfYSgIOWJd3{color:#fff;background:#ff1f00}.kDQJ4E2IG7aIu_SIzqcu{background:#330600;color:#fff}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/Kanban/Distribution/Labels/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,cAAA,CACA,wBACE,+BAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,iBAAA,CAIJ,sBACE,kBAAA,CACA,UAAA,CAEF,sBACE,UAAA,CACA,kBAAA,CAEF,sBACE,kBAAA,CACA,UAAA","sourcesContent":[".flex_labels{display:flex;align-items:center;column-gap:8px;flex-wrap:wrap}.flex_labels p{font-family:\"Mulish\",sans-serif;border-radius:8px;font-size:10px;font-weight:700;padding:4px 6px;margin-bottom:4px}.UpdateStyle{background:#ff7a00;color:#fff}.DeclineStyle{color:#fff;background:#ff1f00}.CancelStyle{background:#330600;color:#fff}"],"sourceRoot":""}]);
// Exports
export var flex_labels = `D3edQ1eR8QCRgYMxVKnq`;
export var UpdateStyle = `rqlKEBZlazBKo1cE2NiC`;
export var DeclineStyle = `ca_pTveiWgfYSgIOWJd3`;
export var CancelStyle = `kDQJ4E2IG7aIu_SIzqcu`;
export default ___CSS_LOADER_EXPORT___;
