import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Drawer, Dropdown, Flex, MenuProps, Popconfirm, message } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { FC, Fragment } from 'react';
import { JobsDataType } from '../../../../../ types';
import { StagesStore } from '../../../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import { ManagerStore } from '../../../../../../DataSource/Stores/ManagerStore';
import Button from '../../../../../ui/Button';
import Clickable from '../../../../../ui/Clickable';
import Icon from '../../../../../ui/Icon';
import IconButton from '../../../../../ui/IconButton';
import Labels from '../../Distribution/Labels';
import CommentsBlock from './CommentsBlock';
import MemberTasksBlock from './MemberTasksBlock';
import SystemTasksBlock from './SystemTasksBlock';
import * as styles from './index.module.scss';

interface IProps extends JobsDataType {
  _id: string;
  isOpen: boolean;
  onClose: () => void;
}

const JobDrawer: FC<IProps> = ({
  workspaceId,
  _id,
  jobId,
  isOpen,
  targetDate,
  assignedTo,
  stageId,
  comments,
  deliveryData,
  labels,
  jobData,
  jobFor,
  onClose,
}) => {
  const changeArchived = async (isArchived: boolean) => {
    await StagesStore.updateJobArchived(workspaceId, _id, isArchived);
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.isModalOpen = false;
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  const changeStage = async (stageId: string) => {
    await StagesStore.updateJobStage(workspaceId, _id, stageId);
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      onClose();
      StagesStore.isModalOpen = false;
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  const changeMember = async (memberId: string) => {
    await StagesStore.updateJobAssignedTo(_id, workspaceId, memberId);
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.isModalOpen = false;
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  const onTask = async (idTask: string, status: string) => {
    console.log('dgdgd', idTask);
    if (status === 'in_progress') {
      status = 'completed';
      await StagesStore.updateTask(workspaceId, idTask, status);
      if (StagesStore.jobUpdateError) {
        message.error('Update Error');
      } else {
        message.info('Success');
        StagesStore.isModalOpen = false;
        StagesStore.getListJobs(workspaceId).catch((e) => {
          console.error(e);
        });
      }
    }
  };

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      message.info('Скопировано', 2);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const customExpandIcon = ({ isActive }: any) => (
    <UpOutlined style={{ transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)' }} />
  );

  const topDropdownMenu: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Popconfirm
          title="Bы действительно хотите архивировать заказ?"
          okText="Да"
          cancelText="Нет"
          onConfirm={() => changeArchived(true)}
        >
          <div>Архивировать</div>
        </Popconfirm>
      ),
    },
    {
      key: '2',
      label: <p onClick={() => showModal()}>Редактировать</p>,
    },
  ];

  const showModal = () => {
    onClose();
    ManagerStore.ModalOpenOrder = true;
    StagesStore.setModalEditFormOrder(true);
  };

  const stagesDropdownMenu: MenuProps['items'] = StagesStore.dataList.map((stage, i) => {
    if (i < 3 || i > 7) {
      return {
        key: stage._id,
        label:
          stage._id !== stageId ? (
            <Popconfirm
              title="Вы действительно хотите перенести заказ?"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => changeStage(stage._id)}
            >
              <div className={styles.name_bl}>{stage.name}</div>
            </Popconfirm>
          ) : (
            <div>{stage.name}</div>
          ),
      };
    }
    return null;
  });

  const membersDropdownMenu: MenuProps['items'] = Object.values(WorkspacesStore.members).map(
    (member) => ({
      key: member.userId._id,
      label:
        member.userId._id !== assignedTo ? (
          <Popconfirm
            title="Bы действительно хотите перенести заказ?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => changeMember(member.userId._id)}
          >
            <div className={styles.name_bl}>{member.userId.firstname}</div>
          </Popconfirm>
        ) : (
          <div>{member.userId.firstname}</div>
        ),
    })
  );

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} width={540}>
        <div className={styles.jobDrawer}>
          <div className={styles.jobDrawerTop}>
            <Dropdown menu={{ items: topDropdownMenu }} trigger={['click']}>
              <IconButton icon="three_dots" variant="secondary" />
            </Dropdown>
          </div>

          <div className={styles.field}>
            <p className={styles.name}>Номер заказа:</p>
            <Flex align="center" gap={8}>
              <Button variant="delivery_primary" autoWidth disabled>
                #{jobId}
              </Button>
              <div onClick={() => handleCopy(jobId.toString())} style={{ cursor: 'pointer' }}>
                <Icon slug="copy" />
              </div>
            </Flex>
          </div>

          <div className={styles.field}>
            <p className={styles.name}>Дата и время:</p>
            <div className={styles.dateBlock}>
              {dayjs(targetDate).format('HH:mm')}
              <span>•</span>
              {dayjs(targetDate).format('DD / MM / YYYY')}
            </div>
          </div>

          <div className={styles.separator} />

          <div className={styles.field}>
            <p className={styles.name}>Подразделение</p>
            <p className={styles.jobfor}>{jobFor}</p>
          </div>
          <div className={styles.separator} />
          {labels && labels.length > 0 ? (
            <>
              <div className={styles.field}>
                <p className={styles.name}>Метки:</p>
                <Labels labels={labels} _id={''} name={''} />
              </div>
              <div className={styles.separator} />{' '}
            </>
          ) : (
            ''
          )}

          <div className={styles.field}>
            <p className={styles.name}>Этап</p>
            <Dropdown menu={{ items: stagesDropdownMenu }} trigger={['click']}>
              <Clickable className={styles.dropdownBtn}>
                <span>
                  {stageId
                    ? StagesStore.dataList.find(
                        (item) => item._id === StagesStore.dataJobs.job?.stageId
                      )?.name
                    : 'Статус'}
                </span>
                <DownOutlined color="#2A3038" />
              </Clickable>
            </Dropdown>
          </div>
          <div className={styles.field}>
            <p className={styles.name}>Ответственный</p>
            <Dropdown
              menu={{ items: membersDropdownMenu, style: { height: 300, overflowY: 'auto' } }}
              trigger={['click']}
            >
              <Clickable className={styles.dropdownBtn}>
                <span>
                  {assignedTo
                    ? WorkspacesStore.members[assignedTo]?.userId?.firstname
                    : 'Ответственный'}
                </span>
                <DownOutlined color="#2A3038" />
              </Clickable>
            </Dropdown>
          </div>
          <div className={styles.separator} />

          <div className={styles.field}>
            <p className={styles.name}>Откуда:</p>
            {deliveryData?.pickupPoint.address}
          </div>
          <div className={styles.field}>
            <p className={styles.name} style={{ alignSelf: 'start' }}>
              Отправитель
            </p>
            <Flex vertical gap={8}>
              {deliveryData?.pickupPoint.contactData.map((item) => (
                <Fragment key={item.phone}>
                  <p>{item.name}</p>
                  <p>{item.phone}</p>
                </Fragment>
              ))}
            </Flex>
          </div>

          <div className={styles.separator} />

          <div className={styles.field}>
            <p className={styles.name}>Куда:</p>
            {deliveryData?.deliveryPoint.address}
          </div>
          <div className={styles.field}>
            <p className={styles.name} style={{ alignSelf: 'start' }}>
              Получатель:
            </p>
            <Flex vertical gap={8}>
              {deliveryData?.deliveryPoint.contactData.map((item) => (
                <Fragment key={item.phone}>
                  <p>{item.name}</p>
                  <p>{item.phone}</p>
                </Fragment>
              ))}
            </Flex>
          </div>

          <div className={styles.separator} />

          <div className={styles.field}>
            <p className={styles.name}>Способ оплаты:</p>
            {deliveryData?.paymentData.method}
          </div>
          <div className={styles.field}>
            <p className={styles.name}>Сумма:</p>
            {deliveryData?.paymentData.amount} ₸
          </div>

          {jobData?.[0]?.text ? (
            <div className={styles.instraction_bl}>
              <span>Инструкция:</span>

              {jobData?.[0]?.text && (
                <p>
                  {jobData[0].text.split('\\n').map((line, i) => (
                    <Fragment key={i}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
                </p>
              )}
            </div>
          ) : (
            ''
          )}

          {!!comments?.length && (
            <CommentsBlock expandIcon={customExpandIcon} comments={comments} />
          )}

          {!!StagesStore.dataUserTask.length && (
            <MemberTasksBlock expandIcon={customExpandIcon} assignedTo={assignedTo} />
          )}

          {!!StagesStore.dataSystemTask.length && (
            <SystemTasksBlock expandIcon={customExpandIcon} />
          )}
        </div>
      </Drawer>
    </>
  );
};

export default observer(JobDrawer);
