import { FC } from 'react';
import { Comment } from '../../../../ types';
import CommentBlock from './CommentBlock';
import * as styles from './index.module.scss';

interface IProps {
  comments?: Comment[];
}

const CommentsBlock: FC<IProps> = ({ comments }) => {
  return (
    <div className={styles.commentsWrap}>
      <p className={styles.title}>КОММЕНТАРИИ</p>
      {comments
        ?.toSorted((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        ?.map((comment) => <CommentBlock key={comment._id} {...comment} />)}
    </div>
  );
};

export default CommentsBlock;
