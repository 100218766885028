// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o_kVXIwfhcPc4CsMeOuT{width:100%;cursor:pointer;border:0;margin:14px 0;background:rgba(0,0,0,0)}.o_kVXIwfhcPc4CsMeOuT p{font-weight:400;font-size:15px;color:#000}.o_kVXIwfhcPc4CsMeOuT span{font-weight:400;font-size:14px;color:#878787}.o_kVXIwfhcPc4CsMeOuT .yPV3748zzsSibaedGnZA{display:flex;column-gap:16px;align-items:center}.o_kVXIwfhcPc4CsMeOuT .DcWszfExjdwXrqrEiUrj{justify-content:space-between}.X1nDGJiYcGTKBMwuGJfQ i{color:#000;font-size:20px}.sKc5R43AwoxXalpExNy8 p{text-align:left}.sKc5R43AwoxXalpExNy8 p i{margin-right:8px}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/HistoryOrderItem/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,cAAA,CACA,QAAA,CACA,aAAA,CACA,wBAAA,CACA,wBACE,eAAA,CACA,cAAA,CACA,UAAA,CAEF,2BACE,eAAA,CACA,cAAA,CACA,aAAA,CAEF,4CACE,YAAA,CACA,eAAA,CACA,kBAAA,CAEF,4CACE,6BAAA,CAKF,wBACE,UAAA,CACA,cAAA,CAIF,wBACE,eAAA,CAEA,0BACE,gBAAA","sourcesContent":[".order_item{width:100%;cursor:pointer;border:0;margin:14px 0;background:rgba(0,0,0,0)}.order_item p{font-weight:400;font-size:15px;color:#000}.order_item span{font-weight:400;font-size:14px;color:#878787}.order_item .fl_block{display:flex;column-gap:16px;align-items:center}.order_item .fl_beetw{justify-content:space-between}.but_bl i{color:#000;font-size:20px}.text_data p{text-align:left}.text_data p i{margin-right:8px}"],"sourceRoot":""}]);
// Exports
export var order_item = `o_kVXIwfhcPc4CsMeOuT`;
export var fl_block = `yPV3748zzsSibaedGnZA`;
export var fl_beetw = `DcWszfExjdwXrqrEiUrj`;
export var but_bl = `X1nDGJiYcGTKBMwuGJfQ`;
export var text_data = `sKc5R43AwoxXalpExNy8`;
export default ___CSS_LOADER_EXPORT___;
