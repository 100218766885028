import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../../utils';
import { AuthStore } from '../../DataSource/Stores/AuthStore';

const Invite = () => {
  const navigate = useNavigate();
  const { workspaceId, email, code } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const res = await api.post(`workspaces/${workspaceId}/inviteAccept`, {
          email,
          code,
          workspaceId,
        });

        if (!res.data.user) {
          localStorage.setItem('invite', JSON.stringify({ workspaceId, email, code }));
          AuthStore.logout();
          navigate('/register', { state: { email, emailConfirm: false } });
          return;
        }

        localStorage.removeItem('invite');
        localStorage.setItem('authToken', res.data.user.access_token);
        window.location.href = `/invite_success/${workspaceId}`;
      } catch (e: any) {
        console.error(e);
        navigate('/');
      }
    })();
  }, [workspaceId, email, code]);

  return <></>;
};

export default Invite;
