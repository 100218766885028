import { Button, Drawer, Form, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { Organization } from '../../../ types';

import { toJS } from 'mobx';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { WorkspacesStore } from '../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import IconButton from '../../../ui/IconButton';
import StatusTitle from '../StatusTitle';
import * as styles from './index.module.scss';

interface FormData {
  name: string;
  address: string;
  coords: string;
  contacts: { name: string; phone: string }[];
}

const getInitialValues = (data: Partial<Organization> | null): FormData => {
  if (data?._id) {
    return {
      name: data.name || '',
      address: data.address?.fullAddress || '',
      coords: `${data.address?.geoPoint.latitude},${data.address?.geoPoint.longitude}`,
      contacts: toJS(data.contactData) || [],
    };
  }

  return {
    name: '',
    address: '',
    coords: '',
    contacts: [{ name: '', phone: '' }],
  };
};

const OrganizationDrawer = ({
  data,
  onClose,
}: {
  data: Partial<Organization> | null;
  onClose: () => void;
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const isEdit = !!data?._id;

  const loading =
    WorkspacesStore.addOrganization.loading ||
    WorkspacesStore.deleteOrganization.loading ||
    WorkspacesStore.updateOrganization.loading;

  useEffect(() => {
    console.log('SET VALUES');

    form.setFieldsValue(getInitialValues(data));
  }, [data]);

  const onSubmit = async (formData: FormData) => {
    if (!id) return;

    try {
      const { name, address, coords, contacts } = formData;

      if (!coords.includes(',') || coords.split(',').length !== 2) {
        return toast.error('Укажте правильные координаты');
      }

      const [latitude, longitude] = coords.split(',');

      const body = {
        name,
        address: { fullAddress: address, geoPoint: { latitude, longitude } },
        contactData: contacts,
        workspaceId: id,
      };

      if (data?._id) {
        await WorkspacesStore.updateOrganization.call(id, data._id, body);
      } else {
        await WorkspacesStore.addOrganization.call(id, body);
      }

      await WorkspacesStore.organizations.call(id);

      onClose();
    } catch (e: any) {
      console.error(e);
      toast.error(JSON.stringify(e.message));
    }
  };

  const onDeleteHandler = async () => {
    if (!id || !data?._id) return;

    try {
      await WorkspacesStore.deleteOrganization.call(id, data?._id);
      await WorkspacesStore.organizations.call(id);
      onClose();
    } catch (e: any) {
      console.error(e);
      toast.error(JSON.stringify(e.message));
    }
  };

  return (
    <Drawer
      className={styles.drawer}
      open={data !== null}
      onClose={onClose}
      width={540}
      style={{ background: 'transparent' }}
      styles={{ wrapper: { boxShadow: 'none' } }}
    >
      <div className={styles.drawerWrap}>
        <div className={styles.drawerTop}>
          <div>
            <div className={styles.drawerTopLabel}>подразделение</div>
            <p className={styles.drawerTopTitle}>{isEdit ? data.name : 'Новая организация'}</p>
          </div>

          <IconButton
            onClick={onClose}
            className={styles.drawerClose}
            icon="x"
            variant="secondary"
          />
        </div>

        <div>
          {!isEdit && <StatusTitle title="создание" theme="create" />}
          {isEdit && <StatusTitle title="редактирование" theme="work" />}

          <Form
            form={form}
            name="basic"
            layout="vertical"
            onFinish={onSubmit}
            requiredMark={false}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <DrawerInput name="name" label="Наименование организации:" placeholder="Название" />
            <DrawerInput name="address" label="Адрес:" placeholder="Полный адрес" />
            <DrawerInput
              name="coords"
              label="Координаты (широта, долгота):"
              placeholder="82.808814,54.989664"
            />

            <div className={styles.drawerContactsLabels}>
              <div>Телефон:</div>
              <div>Имя:</div>
            </div>
            <Form.List name="contacts" initialValue={[{ name: '', phone: '' }]}>
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ display: 'flex', gap: 8, marginBottom: 8 }}>
                      <Form.Item
                        {...restField}
                        name={[name, 'phone']}
                        style={{ marginBottom: 0 }}
                        rules={[{ required: true, message: 'Phone is required' }]}
                      >
                        <Input placeholder="Телефон" />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        style={{ marginBottom: 0 }}
                        rules={[{ required: true, message: 'Name is required' }]}
                      >
                        <Input placeholder="Имя" />
                      </Form.Item>

                      <IconButton
                        variant="secondary"
                        onClick={() => remove(name)}
                        icon="minus"
                        iconProps={{ size: 18, color: '#959595' }}
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button type="default" onClick={() => add()} block>
                      + Добавить контакт
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>

            <div className={styles.drawerBottom}>
              {isEdit && (
                <Button
                  loading={loading}
                  onClick={onDeleteHandler}
                  className={styles.drawerBtn}
                  type="default"
                  danger
                >
                  Удалить
                </Button>
              )}
              <Button
                loading={loading}
                className={styles.drawerBtn}
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
              >
                {isEdit ? 'Сохранить' : 'Добавить'}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};

interface IDrawerInput {
  name: string;
  label: string;
  placeholder: string;
}

const DrawerInput = ({ name, label, placeholder }: IDrawerInput) => {
  return (
    <Form.Item className={styles.drawerInput} style={{ marginBottom: 12 }}>
      <div className={styles.drawerInputLabel}>{label}</div>
      <Form.Item rules={[{ required: true, message: 'Заполните поле' }]} name={name} noStyle>
        <Input className={styles.drawerInputBody} placeholder={placeholder} />
      </Form.Item>
    </Form.Item>
  );
};

export default OrganizationDrawer;
