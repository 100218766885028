import { Avatar } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { JobsDataType } from '../../../../ types';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import { config } from '../../../../../config';
import Button from '../../../../ui/Button';
import Icon from '../../../../ui/Icon';
import Labels from '../Distribution/Labels';
import JobDrawer from './JobDrawer';
import * as styles from './index.module.scss';

const JobColumnItem: FC<JobsDataType & { dragProps: any }> = ({
  _id,
  name,
  stageId,
  jobId,
  jobData,
  workspaceId,
  externalId,
  targetDate,
  assignedTo,
  comments,
  createdAt,
  updatedAt,
  deliveryData,
  labels,
  dragProps,
  jobFor,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    StagesStore.getJobsId(workspaceId, _id).catch((e) => {
      console.error(e);
    });
  };

  // const showModalForce = (forceId: string) => {
  //   setIsModalOpen(true);
  //   StagesStore.getJobsId(workspaceId, forceId).catch((e) => {
  //     console.error(e);
  //   });
  // };

  // useEffect(() => {
  //   if (!StagesStore?.dataJobs?.job?._id) return;
  //   const eventStatus = uWS.subscribe(
  //     `jobs/${StagesStore.dataJobs.job._id}/changeTaskStatus`,
  //     (data: any) => {
  //       if (data.error) {
  //         console.error('Error subscribing to jobs changeTaskStatus:', data.error);
  //         return;
  //       }
  //
  //       // console.log('changeTaskStatus:', data);
  //       // console.log('!StagesStore?.dataJobs?.job?._id', !StagesStore?.dataJobs?.job?._id);
  //       // console.log(
  //       //   'StagesStore?.dataJobs?.job?._id !== data.jobId',
  //       //   StagesStore?.dataJobs?.job?._id !== data.jobId
  //       // );
  //       // console.log('!isModalOpen', !isModalOpen);
  //       if (!StagesStore?.dataJobs?.job?._id) return;
  //
  //       if (StagesStore?.dataJobs?.job?._id !== data.jobId) {
  //         return;
  //       }
  //
  //       // if (!isModalOpen) {
  //       //   return;
  //       // }
  //
  //       console.log('Ljitk');
  //       StagesStore.getJobsId(workspaceId, data.jobId).catch((e) => {
  //         console.error(e);
  //       });
  //       // showModalForce(StagesStore.dataJobs.job._id);
  //     }
  //   );
  //
  //   return () => {
  //     if (eventStatus && StagesStore?.dataJobs?.job?._id)
  //       uWS.unSubscribe(`jobs/${StagesStore.dataJobs.job._id}/changeTaskStatus`);
  //   };
  // }, [StagesStore?.dataJobs?.job?._id]);

  const member = assignedTo ? WorkspacesStore.members[assignedTo]?.userId : null;

  return (
    <>
      <div className={styles.jobColumnItem}>
        <div className={styles.dragHandle} {...dragProps}>
          <Icon slug="drag_handle" />
        </div>
        <div className={styles.right}>
          <p className={styles.title}>{name}</p>

          <div className={styles.infoBlock}>
            <Button variant="delivery_primary" autoWidth onClick={showModal}>
              #{jobId}
              <Icon slug="arrow_vertical" />
            </Button>

            <div className={styles.dateBlock}>
              {dayjs(targetDate).format('HH : mm')}
              <span className={styles.separator}>•</span>
              {dayjs(targetDate).format('DD/MM/YY')}
            </div>

            {member && (
              <div className={styles.member}>
                {member.photo && (
                  <Avatar
                    src={`${config.EXTERNAL_ADDRESS}/image-uploader/${member.photo}`}
                    shape="circle"
                    size={32}
                  />
                )}
                {!member.photo && <div className={styles.picture}>{member.firstname[0]}</div>}
                <p className={styles.name}>{member.firstname}</p>
              </div>
            )}
            <Labels labels={labels} _id={''} name={''} />
          </div>
        </div>
      </div>

      <JobDrawer
        workspaceId={workspaceId}
        name={name}
        jobId={jobId}
        jobData={jobData}
        targetDate={targetDate}
        stageId={stageId}
        assignedTo={assignedTo}
        comments={comments}
        externalId={externalId}
        createdAt={createdAt}
        updatedAt={updatedAt}
        deliveryData={deliveryData}
        jobFor={jobFor}
        labels={labels}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        _id={_id}
      />
    </>
  );
};

export default observer(JobColumnItem);
