/**
 * вся информация про текущего залогиненного пользователя
 *
 *
 */
import axios from 'axios';
import { action, makeAutoObservable, observable } from 'mobx';
import urlJoin from 'url-join';
import { FieldTypetwo } from '../../components/Auth/Create';
import { config } from '../../config';
import { User } from '../Resources/User';
import { basicAuthHeaders } from './basicAuthHeaders';

const AUTH_TOKEN_STORAGE_KEY = 'authToken';
const REFRESH_TOKEN_STORAGE_KEY = 'refreshToken';

export class AuthStoreDataClass {
  /**
   *
   */
  @observable
  ready: boolean = false;

  @observable
  user: User = new User({});

  @observable
  resetPasswordRequestActivityEmailFieldError = false;

  @observable
  resetPasswordRequestError: string | null = null;

  @observable
  resetPasswordRequestInProgress = false;

  @observable
  inputForChangePassword1 = '';

  @observable
  inputForChangePassword2 = '';

  @observable
  inputForChangePasswordError: string | null = null;

  @observable
  signUpSuccess: boolean = false;

  @observable
  loggedTesting: boolean = false;

  @observable
  needProfileCheckPass: boolean = false;

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  /**
   *
   */
  @action
  setReady = (data: boolean) => {
    this.ready = data;
  };

  @action
  setLoggedTesting = (data: boolean) => {
    this.loggedTesting = data;
  };

  @action
  setUser = (data: User) => {
    this.user = data;
  };

  @action
  setResetPasswordRequestError = (data: string | null) => {
    this.resetPasswordRequestError = data;
  };

  @action
  setResetPasswordRequestInProgress = (data: boolean) => {
    this.resetPasswordRequestInProgress = data;
  };

  @action
  setPasswordRequestActivityEmailFieldError = (data: boolean) => {
    this.resetPasswordRequestActivityEmailFieldError = data;
  };

  @action
  setInputForChangePassword1 = (data: string) => {
    this.inputForChangePassword1 = data;
  };

  @action
  setInputForChangePassword2 = (data: string) => {
    this.inputForChangePassword2 = data;
  };

  @action
  setInputForChangePasswordError = (data: string | null) => {
    this.inputForChangePasswordError = data;
  };

  // prevent two simultaneous refreshes
  nowRefreshing = false;

  /**
   *
   */
  @action
  clear = () => {
    window.localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);

    this.ready = false;

    this.resetPasswordRequestError = null;
    this.resetPasswordRequestInProgress = false;
    this.resetPasswordRequestActivityEmailFieldError = false;
    this.inputForChangePassword1 = '';
    this.inputForChangePassword2 = '';
    this.inputForChangePasswordError = null;
  };

  authHeaders() {
    const token = this.loadAccessToken();

    if (token) {
      return {
        Authorization: 'Bearer ' + token,
      };
    }

    return null;
  }

  /**
   *
   */
  async getToken(email: string, password: string) {
    const data = {
      email: email.toLowerCase(),
      password,
    };
    // тут мы не используем обертку из-за циклической зависимости
    try {
      const response = await axios.post(urlJoin(config.EXTERNAL_ADDRESS, '/auth/login'), data, {
        headers: basicAuthHeaders(),
      });
      console.log('---- getToken|response.data=', response.data);
      this.saveAuthToken(response.data.access_token);
      this.saveRefreshToken(response.data.refresh_token);
    } catch (error) {
      console.warn('auth error');
      console.warn(error);
      throw error;
    }
  }

  /**
   *
   */
  async signUp(data: FieldTypetwo) {
    try {
      const response = await axios.post(urlJoin(config.EXTERNAL_ADDRESS, '/auth/signUp'), data, {
        headers: basicAuthHeaders(),
      });
      console.log('---- getToken|response.data=', response.data);
      this.saveAuthToken(response.data.accessToken);
      this.saveRefreshToken(response.data.refreshToken);
    } catch (error) {
      console.warn('auth error');
      console.warn(error);
    }
  }

  /**
   *
   */
  async signUpByEmail(data: FieldTypetwo) {
    try {
      const response = await axios.post(
        urlJoin(config.EXTERNAL_ADDRESS, '/auth/signUpByEmail'),
        data,
        {
          headers: basicAuthHeaders(),
        }
      );
      console.log('---- getToken|response.data=', response.data);
      this.saveAuthToken(response.data.access_token);
      this.saveRefreshToken(response.data.refresh_token);
    } catch (error) {
      console.warn('auth error');
      console.warn(error);
    }
  }

  /**
   *
   */
  async recoveryPass(data: FieldTypeForgot) {
    try {
      const response = await axios.post(
        urlJoin(config.EXTERNAL_ADDRESS, 'forgot/recoveryPass'),
        data,
        {
          headers: basicAuthHeaders(),
        }
      );
      console.log('---- getToken|response.data=', response.data);
      this.saveAuthToken(response.data.access_token);
      this.saveRefreshToken(response.data.refresh_token);
    } catch (error) {
      console.warn('auth error');
      console.warn(error);
    }
  }

  /**
   *
   */
  refreshToken = async (options: { traceparent?: string } = {}) => {
    const traceparent = options.traceparent;

    try {
      const refreshToken = this.loadRefreshToken();
      if (!refreshToken) {
        throw new Error('no refresh token, please login again');
      }

      // здесь мы сознательно делаем запрос без использования обертки axiosMaximus
      // из-за неё циклическая зависимость получается, а функционал тот не нужен
      const response = await axios.post(
        // urlJoin(config.EXTERNAL_ADDRESS, '/openid/refresh-token'),
        urlJoin(config.EXTERNAL_ADDRESS, '/auth/refresh'),
        {
          refreshToken: refreshToken,
        },
        {
          headers: {
            traceparent: traceparent,
          },
        }
      );

      const newAuthToken = response.data.accessToken;
      const newRefreshToken = response.data.refreshToken;

      this.saveAuthToken(newAuthToken);
      this.saveRefreshToken(newRefreshToken);
    } catch (error) {
      // в любой непонятной ситуации тупо очищаем все токены и ~делаем вид. что так и было~ отправляем на авторизацию
      console.log('REFRESH ERROR:', error);
      this.clear();
    }
  };

  /**
   * загружаем сохраненный токен из хранилища
   */
  loadAccessToken = () => {
    return window.localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  };

  /**
   *
   */
  loadRefreshToken = () => {
    return window.localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  };

  /**
   * сохраняем данные в хранилище, оттуда они будут использоваться в запросах
   */
  saveAuthToken = (authToken: string) => {
    window.localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken);
  };

  /**
   *
   */
  saveRefreshToken = (refreshToken: string) => {
    if (refreshToken) {
      window.localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
    } else {
      window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    }
  };
}

const AuthStoreData = new AuthStoreDataClass();

export { AuthStoreData };
