import { makeAutoObservable, observable } from 'mobx';
import { basicRequestGet } from '../../Requests/basicRequestGet';
export class OrganizationsStoreActionsClass {
  @observable loadingError: boolean = false;

  @observable loading: boolean = false;

  @observable loadingDelete: boolean = false;
  @observable dataList: [] = [];
  constructor() {
    makeAutoObservable(this);
  }

  async getList(workspaceId: string) {
    console.log('---- ww|OrganizationsStore|getList');
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.loadingError = false;
    this.dataList = [];

    try {
      const res = await basicRequestGet(`/workspaces/${workspaceId}/organizations`);
      console.log('---- ww|getList|res.data=', res.data);
      // this.dataList = res.data.data.docs.map((i: any, k: number) => {
      //   i.key = k + 1;
      //   // i.tags = [];
      //   return i;
      // });
      this.dataList = res.data.data;
      console.log('organizations: ', this.dataList);
    } catch (error) {
      // throw error;
      console.error('getList|error=', error);
      this.loadingError = true;
    } finally {
      this.loading = false;
    }
  }
}
