import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { AuthStoreData } from '../Stores/AuthStoreDataClass';

const axiosMaximus = axios.create();
let nowRefreshing = false;
let queue: any = [];

axiosMaximus.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    // return Promise.reject(error);

    let originalRequest = error.config;
    const responseStatus = error.response?.status;

    if (responseStatus != 401) {
      return Promise.reject(error);
    }

    // тут важно правильно соблюсти последовательность выполнения - ответ на рефреш должен обрабатываться
    // после того, как запрос добавится в очередь

    if (!nowRefreshing) {
      nowRefreshing = true;

      const traceparent = originalRequest?.headers['traceparent'];

      AuthStoreData.refreshToken({ traceparent })
        .then(() => {
          const token = AuthStoreData.loadAccessToken();
          queue.map((func: any) => func(token));
        })
        .finally(() => {
          nowRefreshing = false;
          queue = [];
        });
    }
    //
    return new Promise((resolve) => {
      queue.push((token: string) => {
        if (!originalRequest) {
          originalRequest = {} as InternalAxiosRequestConfig;
        }
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        resolve(axios(originalRequest));
      });
    });
  }
);

export { axiosMaximus };
