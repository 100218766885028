// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LU8PHuEtABnX_AGYctQH{flex-direction:column;display:flex}.TAMKmJteXryAMks9hsfY{width:50px;background:#ebedef;height:50px;margin-bottom:8px;border:1px solid #e5e5e5;border-radius:50px}.TAMKmJteXryAMks9hsfY i{color:#000}.Mpm89cN8K0x2FSKwPlc1{border:1px solid #2b2b2b;background:linear-gradient(206deg, #2b2b2b 0%, #535353 100%)}.Mpm89cN8K0x2FSKwPlc1 i{color:#fff}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/Menu/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,YAAA,CAEF,sBACE,UAAA,CACA,kBAAA,CACA,WAAA,CACA,iBAAA,CACA,wBAAA,CACA,kBAAA,CAEA,wBACE,UAAA,CAEF,sBACE,wBAAA,CACA,4DAAA,CACA,wBACE,UAAA","sourcesContent":[".menu{flex-direction:column;display:flex}.nav_item{width:50px;background:#ebedef;height:50px;margin-bottom:8px;border:1px solid #e5e5e5;border-radius:50px}.nav_item i{color:#000}.nav_item__active{border:1px solid #2b2b2b;background:linear-gradient(206deg, #2b2b2b 0%, #535353 100%)}.nav_item__active i{color:#fff}"],"sourceRoot":""}]);
// Exports
export var menu = `LU8PHuEtABnX_AGYctQH`;
export var nav_item = `TAMKmJteXryAMks9hsfY`;
export var nav_item__active = `Mpm89cN8K0x2FSKwPlc1`;
export default ___CSS_LOADER_EXPORT___;
