import { FC, ReactNode } from 'react';
import { $class } from '../../../utils';
import Clickable from '../Clickable';
import Icon from '../Icon';
import './index.scss';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'outlined'
  | 'text'
  | 'delivery_secondary'
  | 'delivery_primary'
  | 'delivery_text'
  | 'delivery_outline'
  | 'delivery_primary_full';

interface IProps {
  children?: ReactNode;
  className?: string;
  variant?: ButtonVariant;
  icon?: string;
  loading?: boolean;
  width?: number;
  autoWidth?: boolean;
  onClick?: () => void;
  rippleColor?: string;
  disabled?: boolean;
}

const Button: FC<IProps> = ({
  children,
  className,
  variant = 'primary',
  icon,
  loading,
  width,
  autoWidth = false,
  onClick,
  rippleColor,
  disabled,
}) => {
  return (
    <Clickable
      rippleColor={rippleColor}
      className={$class(
        'ui-button',
        ['ui-button--primary', variant === 'primary'],
        ['ui-button--secondary', variant === 'secondary'],
        ['ui-button--outlined', variant === 'outlined'],
        ['ui-button--delivery_outline', variant === 'delivery_outline'],
        ['ui-button--text', variant === 'text'],
        ['ui-button--delivery_text', variant === 'delivery_text'],
        ['ui-button--loading', loading],
        ['ui-button--delivery_secondary', variant === 'delivery_secondary'],
        ['ui-button--delivery_primary', variant === 'delivery_primary'],
        ['ui-button--delivery_primary_full', variant === 'delivery_primary_full'],

        className
      )}
      onClick={onClick}
      style={{ width: width ? width : autoWidth ? 'fit-content' : '100%' }}
      disabled={disabled}
    >
      <div className="ui-button-wrap">
        {children}
        {icon && <Icon slug={icon} />}
      </div>
      <div className="ui-button-loading">
        <span></span>
      </div>
    </Clickable>
  );
};

export default Button;
