import { action, makeAutoObservable, observable } from 'mobx';

/**
 * стор для хранения данных
 * компонента Header
 */
class HeaderStoreClass {
  @observable title = '';
  @observable setopendrawer = false;
  @observable titlePrivate = '';
  @observable presentationMod = '';
  @observable patientId = '';
  @observable hide = false;
  @observable showLeftMenu = true;
  @observable needNotifUpdate = false;
  @observable breadcrumbs = [];
  @observable locale =
    localStorage.getItem('locale') ||
    (navigator.language === 'ru' || navigator.language === 'ru-RU' ? 'ru' : 'en');

  @observable headerParams = {};
  @observable isOpenNotificationsPopup = false;

  // дефолтная локаль согласно установкам в конфиге системы
  uiDefaultLanguageSetting = '';

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  @action
  setTitle = (title = '') => {
    this.title = title;
  };

  @action
  setHeaderParam = (moreParams = {}) => {
    this.headerParams = { ...this.headerParams, ...moreParams };
  };

  @action
  setTitlePrivate = (titlePrivate = '') => {
    this.titlePrivate = titlePrivate;
  };

  @action
  setPresentationMod = (bool = '') => {
    this.presentationMod = bool;
  };

  @action
  setPatientId = (id = '') => {
    this.patientId = id;
  };

  @action
  setLeftMenuVisibility = (bool = true) => {
    this.showLeftMenu = bool;
  };

  @action
  setNeedNotifUpdate = (bool: boolean) => {
    this.needNotifUpdate = bool;
  };

  @action
  setIsOpenNotificationsPopup = (bool: boolean) => {
    this.isOpenNotificationsPopup = bool;
  };

  @action
  setBreadcrumbs = (bread: any) => {
    this.breadcrumbs = bread;
  };

  loadLocale = () => {
    return window.localStorage.getItem('locale');
  };

  @action
  setLang(value: any) {
    this.locale = value;
    localStorage.setItem('locale', value);
  }

  setOpenRight(value: boolean) {
    this.setopendrawer = value;
  }

  /**
   * эта штука нужна для избавления от циклической зависимости
   */
  @action
  setUiDefaultLanguageSetting = (locale: string) => {
    this.uiDefaultLanguageSetting = locale;
  };
}

const HeaderStore = new HeaderStoreClass();

export { HeaderStore };
