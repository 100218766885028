import * as React from 'react';
import * as styles from './Auth.module.scss';

const NetworkError: React.FC = () => (
  <div className={styles.form_wrp}>
    <p className={styles.link}>Network Error. Try reload page...</p>
  </div>
);

export default NetworkError;
