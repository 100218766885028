import { observer } from 'mobx-react';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { $class } from '../../../../utils';
import Clickable from '../../../ui/Clickable';
import Icon from '../../../ui/Icon';
import * as styles from './index.module.scss';
type WorkspaceIdParams = {
  id?: string;
};
const Menu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<WorkspaceIdParams>();

  return (
    <div className={styles.menu}>
      <Clickable
        onClick={() => navigate(`/workspaces/${id}`)}
        className={$class(styles.nav_item, [
          styles.nav_item__active,
          location.pathname == `/workspaces/${id}`,
        ])}
      >
        <Icon className="ui-icon-button-icon" slug={'fi-rr-box-circle-check'} />
      </Clickable>

      <Clickable
        onClick={() => navigate(`/workspaces/${id}/zoning_management`)}
        className={$class(styles.nav_item, [
          styles.nav_item__active,
          location.pathname == `/workspaces/${id}/zoning_management`,
        ])}
      >
        <Icon className="ui-icon-button-icon" slug={'fi-rr-region-pin-alt'} />
      </Clickable>
      {/* <Clickable
        onClick={() => navigate(`/workspaces/${id}/order_management`)}
        className={$class(styles.nav_item, [
          styles.nav_item__active,
          location.pathname == `/workspaces/${id}/order_management`,
        ])}
      >
        <Icon className="ui-icon-button-icon" slug={'fi-rr-apps-sort'} />
      </Clickable> */}
      {/* <Clickable
        onClick={() => navigate(`/workspaces/${id}/distribution_center`)}
        className={$class(styles.nav_item, [
          styles.nav_item__active,
          location.pathname == `/workspaces/${id}/distribution_center`,
        ])}
      >
        <Icon className="ui-icon-button-icon" slug={'fi-rr-refresh'} />
      </Clickable> */}
      <Clickable
        onClick={() => navigate(`/workspaces/${id}/orderhistory`)}
        className={$class(styles.nav_item, [
          styles.nav_item__active,
          location.pathname == `/workspaces/${id}/orderhistory`,
        ])}
      >
        <Icon className="ui-icon-button-icon" slug={'fi-rr-rectangle-vertical-history'} />
      </Clickable>
      <Clickable
        onClick={() => navigate(`/workspaces/${id}/journal`)}
        className={$class(styles.nav_item, [
          styles.nav_item__active,
          location.pathname == `/workspaces/${id}/search`,
        ])}
      >
        <Icon className="ui-icon-button-icon" slug={'fi-rr-note'} />
      </Clickable>
      <Clickable
        onClick={() => navigate(`/workspaces/${id}/members`)}
        className={$class(styles.nav_item, [
          styles.nav_item__active,
          location.pathname == `/workspaces/${id}/members`,
        ])}
      >
        <Icon className="ui-icon-button-icon" slug={'fi-rr-chart-histogram'} />
      </Clickable>
      <Clickable
        onClick={() => navigate(`/workspaces/${id}/organizations`)}
        className={$class(styles.nav_item, [
          styles.nav_item__active,
          location.pathname == `/workspaces/${id}/organizations`,
        ])}
      >
        <Icon className="ui-icon-button-icon" slug={'fi-rr-corporate-alt'} />
      </Clickable>
    </div>
  );
};
export default observer(Menu);
