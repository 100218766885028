import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import uWS from '../../../uWS';
// import NewJobsBlock from '../NewJobsBlock';
// import styles from './index.module.scss';
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';
import { WorkspacesStore } from '../../../DataSource/Stores/Cabinet/WorkspacesStore';
import DeliveryCenter from '../DeliveryCenter';
import DistributionCenter from '../DistributionCenter';

type WorkspaceIdParams = {
  id?: string;
};

const Workspaces = () => {
  const { id } = useParams<WorkspaceIdParams>();
  // const [view, setView] = useState<'list' | 'columns'>('columns');
  // const navigate = useNavigate();
  useEffect(() => {
    if (!id) return;
    (async () => {
      if (!WorkspacesStore.dataListWork?.length) {
        await WorkspacesStore.getList();
      }

      try {
        const res = await WorkspacesStore.membersGet.call(id);
        WorkspacesStore.setMembers(res.data);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }
    })();
  }, [id]);

  const workspace = WorkspacesStore.dataListWork.find((el) => el._id === id);

  return (
    <>
      {id && workspace && workspace.category === 'order_management' && (
        <DistributionCenter workspaceId={id} name={workspace.name} />
      )}
      {id && workspace && workspace.category === 'delivery_center' && (
        <DeliveryCenter workspaceId={id} name={workspace.name} />
      )}
    </>
  );
};

export default observer(Workspaces);
