import { makeObservable, observable } from 'mobx';

import { basicRequestPost } from '../Requests/basicRequestPost';

export class UserStoreActionsClass {
  @observable
  loading: boolean = false;

  constructor() {
    makeObservable(this);
    // makeAutoObservable(this);
  }

  /**
   *
   */
  async send(email: string) {
    await basicRequestPost('/user/send', {
      email,
    });
    console.log('---- ww|sendRecoveryLink');
  }
}
