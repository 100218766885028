import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { FC, Fragment } from 'react';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import Clickable from '../../../../ui/Clickable';
import * as styles from './index.module.scss';

interface IProps {
  assignedTo?: string;
}

const MemberTasksBlock: FC<IProps> = ({ assignedTo }) => {
  return (
    <div className={styles.fieldsWrap}>
      <p className={styles.title}>ЗАДАЧИ ПОЛЬЗОВАТЕЛЕЙ</p>

      {StagesStore.dataUserTask.map((task) => {
        const membersDropdownMenu: MenuProps['items'] = Object.values(WorkspacesStore.members).map(
          (member) => ({
            key: member._id,
            label: <div>{member.userId.firstname}</div>,
          })
        );

        return (
          <Fragment key={task._id}>
            {/*<Button*/}
            {/*  variant={task.status == 'in_progress' ? 'primary' : 'secondary'}*/}
            {/*  onClick={() => onTask(task._id, task.status)}*/}
            {/*  key={task._id}*/}
            {/*  loading={StagesStore.loadingJob}*/}
            {/*>*/}
            {/*  <div>*/}
            {/*    <p>taskId: {task._id.slice(-4)}</p>*/}
            {/*  </div>*/}
            {/*</Button>*/}
            <div className={styles.field}>
              <p className={styles.name}>{task.title}</p>
              <Dropdown trigger={['click']} disabled>
                <Clickable className={styles.dropdownBtn}>
                  {task.status}
                  <DownOutlined color="#2A3038" />
                </Clickable>
              </Dropdown>
            </div>
            <div className={styles.field}>
              <p className={styles.name}>Ответственный</p>
              <Dropdown menu={{ items: membersDropdownMenu }} trigger={['click']}>
                <Clickable className={styles.dropdownBtn}>
                  {task.assignedTo && assignedTo
                    ? WorkspacesStore.members[assignedTo]?.userId?.firstname
                    : 'Ответственный'}
                  <DownOutlined color="#2A3038" />
                </Clickable>
              </Dropdown>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default MemberTasksBlock;
