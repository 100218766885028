// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aUL09tKRS3hC66idEcXF{display:flex;font-family:"Mulish",sans-serif !important;flex-direction:column;gap:32px;padding:0}.Qd2NCzcE0r9wp63LIScN{display:flex;align-items:center;gap:48px}.Qd2NCzcE0r9wp63LIScN .tzbYHLxSNLr0SdbSkfht{font-size:32px;font-weight:600;color:#000;font-family:"Mulish",sans-serif}.aUL09tKRS3hC66idEcXF button,.aUL09tKRS3hC66idEcXF p,.aUL09tKRS3hC66idEcXF b,.aUL09tKRS3hC66idEcXF h1,.aUL09tKRS3hC66idEcXF h2,.aUL09tKRS3hC66idEcXF div,.aUL09tKRS3hC66idEcXF a{font-family:"Mulish",sans-serif}.CBxxPAG8Msgc6CHEGOG4{display:flex;align-items:center;column-gap:16px}.IcIAPJ9BSHfum3iT4KRU{background-color:#3a6def;border-radius:50px;padding:6px 16px;color:#fff;height:auto}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,0CAAA,CACA,qBAAA,CACA,QAAA,CACA,SAAA,CACA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,4CACE,cAAA,CACA,eAAA,CACA,UAAA,CACA,+BAAA,CAGJ,iLAOE,+BAAA,CAGJ,sBACE,YAAA,CACA,kBAAA,CACA,eAAA,CAEF,sBACE,wBAAA,CACA,kBAAA,CACA,gBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".distributionCenterPage{display:flex;font-family:\"Mulish\",sans-serif !important;flex-direction:column;gap:32px;padding:0}.distributionCenterPageTop{display:flex;align-items:center;gap:48px}.distributionCenterPageTop .title{font-size:32px;font-weight:600;color:#000;font-family:\"Mulish\",sans-serif}.distributionCenterPage button,.distributionCenterPage p,.distributionCenterPage b,.distributionCenterPage h1,.distributionCenterPage h2,.distributionCenterPage div,.distributionCenterPage a{font-family:\"Mulish\",sans-serif}.flex{display:flex;align-items:center;column-gap:16px}.but_neword{background-color:#3a6def;border-radius:50px;padding:6px 16px;color:#fff;height:auto}"],"sourceRoot":""}]);
// Exports
export var distributionCenterPage = `aUL09tKRS3hC66idEcXF`;
export var distributionCenterPageTop = `Qd2NCzcE0r9wp63LIScN`;
export var title = `tzbYHLxSNLr0SdbSkfht`;
export var flex = `CBxxPAG8Msgc6CHEGOG4`;
export var but_neword = `IcIAPJ9BSHfum3iT4KRU`;
export default ___CSS_LOADER_EXPORT___;
