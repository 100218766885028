import { Button, Dropdown, MenuProps, Modal, Table, message } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Member } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import Icon from '../../../ui/Icon';
import * as styles from './index.module.scss';

interface MenuItemType {
  key: string;
  label: string;
}
interface ModalAddProps {
  open: boolean;
  selectedRows: any;
  columns: any;
  jobIds?: any;
  id?: string;
  setSelectedRows: any;
  handleModalCancel: () => void;
}

const ModalDeliv: React.FC<ModalAddProps> = ({
  open,
  selectedRows,
  columns,
  id,
  jobIds,
  setSelectedRows,
  handleModalCancel,
}) => {
  const defaultUserId = 'Администратор';
  const members = Object.values(WorkspacesStore.members);

  const defaultUser = members.find((user: Member) => user.userId.firstname === defaultUserId);

  //console.log(defaultUser, 'defaultUser');

  const defaultUserName = defaultUser ? defaultUser.userId._id : '';
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [selectedUser, setSelectedUser] = useState<string>(defaultUserName);
  const [inUserSelect, setInUserSelect] = useState<string>(
    defaultUser ? defaultUser.userId.firstname : 'Имя курьера'
  );

  const menuItems: MenuProps['items'] = members.map((user: Member) => ({
    key: user.userId._id,
    label: user.userId.firstname,
  })) as MenuItemType[];

  const handleMenuClick = (e: any) => {
    const selectedUserId = e.key;
    const selectedMenuItem = menuItems.find((item) => item && item.key === selectedUserId) as
      | MenuItemType
      | undefined;

    if (selectedMenuItem) {
      setInUserSelect(selectedMenuItem.label);
      setSelectedUser(selectedUserId);
    }
  };
  const giveOrder = async () => {
    //console.log(id, jobIds, selectedUser);
    if (!selectedUser) {
      message.error('Выберите курьера');
    } else {
      id && (await StagesStore.giveOrders(id, jobIds, selectedUser));
      if (StagesStore.loadingErrorGiveOrder) {
        message.error('Error');
      } else {
        message.info('Заказы выданы');
        handleModalCancel();
        clearSelection();
      }
    }
  };

  const handleDelete = (id: string) => {
    setSelectedRows(selectedRows.filter((row: any) => row._id !== id));
  };
  const clearSelection = () => {
    setSelectedRows([]);
  };

  const menu: MenuProps = {
    items: menuItems,
    onClick: handleMenuClick,
    className: styles.customMenu,
  };

  useEffect(() => {
    const initialSelectedUser = menuItems.find((item) => item && item.key === defaultUserName) as
      | MenuItemType
      | undefined;
    if (initialSelectedUser) {
      setInUserSelect(initialSelectedUser.label);
      setSelectedUser(initialSelectedUser.key);
    }
  }, [defaultUserName]);

  return (
    <Modal
      title="Выдать заказы курьеру"
      open={open}
      onOk={handleModalCancel}
      onCancel={handleModalCancel}
      width="100%"
      footer={null}
    >
      <>
        <div className={styles.name_kour}>
          <Dropdown trigger={['click']} menu={menu}>
            <p className={styles.rangeBlock}>
              {inUserSelect} <Icon slug="angle_down" color="#3a6def" />
            </p>
          </Dropdown>
        </div>
        <Table
          columns={[
            ...columns,
            {
              title: 'Действие',
              render: (_, record) => (
                <Button onClick={() => handleDelete(record._id)} type="link" danger>
                  Удалить
                </Button>
              ),
            },
          ]}
          dataSource={selectedRows}
          rowKey="_id"
          pagination={false}
          className="mb-24"
        />

        <div className={styles.btn_center}>
          <Button className={styles.btn} onClick={giveOrder}>
            Выдать заказы
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default observer(ModalDeliv);
