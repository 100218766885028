import React from 'react';
import { useParams } from 'react-router-dom';

interface ThemeProps {
  configs: {
    [key: string]: React.ReactNode;
  };
}

const Theme: React.FC<ThemeProps> = ({ configs }) => {
  const { id } = useParams<{ id: string | undefined }>();

  // Логика выбора компонента на основе id
  let SelectedComponent;

  if (id === '66e170b52fc0a03d58789c24') {
    SelectedComponent = configs.order_management;
  } else if (id === '6707a212598d48bda5860bf0') {
    // Добавьте дополнительные проверки для других id
    SelectedComponent = configs.delivery_center;
  } else if (id === '67220912e6bd539b6a9143bd') {
    // Добавьте дополнительные проверки для других id
    SelectedComponent = configs.metafarm_cex;
  } else if (id === '6732ed08a29f2065aeb56662') {
    SelectedComponent = configs.shokolatye_cex;
  } else {
    return <div>Компонент не найден для id: {id}</div>;
  }

  return <>{SelectedComponent}</>;
};

export default Theme;
