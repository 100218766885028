import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import { AuthStore } from '../../DataSource/Stores/AuthStore';
import AuthLayout from '../../layouts/AuthLayout';
import CabinetLayout from '../../layouts/CabinetLayout';
import CheckingAuth from '../Auth/CheckingAuth';
import CreateByEmail from '../Auth/CreateByEmail';
// import Forgot from '../Auth/Forgot';
import CabinetLayout2 from '../../layouts/CabinetLayout2';
import MobileLayout from '../../layouts/MobileLayout';
import Check from '../Auth/Check';
import SignUp from '../Auth/Create/SignUp';
import Forgot from '../Auth/Forgot';
import Login from '../Auth/Login';
import NetworkError from '../Auth/NetworkError';
import NewPass from '../Auth/NewPass';
import ZakazBuketov from '../Auth/ZakazBuketov';
import History1 from '../Cabinet/DeliveryCenter/History';
import Distribution from '../Cabinet/DeliveryCenter/Kanban/Distribution';
import ManagingOrder from '../Cabinet/DeliveryCenter/Kanban/ManagingOrder';
import ManagingZoning from '../Cabinet/DeliveryCenter/Kanban/ManagingZoning';
import Mobile from '../Cabinet/DeliveryCenter/Mobile';
import OrganizationsPage from '../Cabinet/DeliveryCenter/Organizations';
import Profile2 from '../Cabinet/DeliveryCenter/Profile/index';
import Search2 from '../Cabinet/DeliveryCenter/Search';
import History from '../Cabinet/DistributionCenter/History';
import Profile from '../Cabinet/DistributionCenter/Profile/index';
import Statistics from '../Cabinet/DistributionCenter/Statistics';
import User from '../Cabinet/DistributionCenter/User';
import Main from '../Cabinet/Main';
import Members from '../Cabinet/Members';
import Mobile2 from '../Cabinet/MetafarmCex/User';
import Search from '../Cabinet/Search';
import History2 from '../Cabinet/ShokolatyeCex/History';
import Profile3 from '../Cabinet/ShokolatyeCex/Profile/index';
import Statistics2 from '../Cabinet/ShokolatyeCex/Statistics';
import Mobile3 from '../Cabinet/ShokolatyeCex/User';
import Workspaces from '../Cabinet/Workspaces';
import Failure from '../Failure';
import Invite from '../Invite';
import InviteSuccess from '../InviteSuccess';
import Success from '../Success';
import Theme from '../Theme/Theme';
import Waiting from '../Waiting';
import Journal from '../Cabinet/DeliveryCenter/Journal';

const App = observer(() => {
  useEffect(() => {
    if (!AuthStore.currentUserData.ready) {
      AuthStore.testCurrentLogged();
    }
  }, []);

  if (AuthStore.networkError) {
    return (
      <RouterProvider
        router={createBrowserRouter([
          {
            element: <AuthLayout />,
            children: [
              {
                path: '*',
                element: <NetworkError />,
              },
            ],
          },
        ])}
      />
    );
  }

  if (AuthStore.loggedTesting || AuthStore.loggedTesting === null) {
    return (
      <RouterProvider
        router={createBrowserRouter([
          {
            element: <Outlet />,
            children: [
              {
                path: '*',
                element: <CheckingAuth />,
              },
            ],
          },
        ])}
      />
    );
  }

  if (!AuthStore.currentUserData.ready) {
    return (
      <RouterProvider
        router={createBrowserRouter([
          {
            element: <AuthLayout />,
            children: [
              {
                path: '/success',
                element: <Success />,
              },
              {
                path: '/failure',
                element: <Failure />,
              },
              {
                path: '/waiting',
                element: <Waiting />,
              },
              {
                path: '/create',
                // element: <Create />,
                element: <CreateByEmail />,
              },
              {
                path: '/admin/create',
                // element: <Create />,
                element: <CreateByEmail />,
              },
              {
                path: '/login',
                element: <Login />,
              },
              {
                path: '/forgot',
                element: <Forgot />,
              },
              {
                path: '/new_password',
                element: <NewPass />,
              },
              {
                path: '/check',
                element: <Check />,
              },
              {
                path: '/register',
                element: <SignUp />,
              },
              {
                path: '/invite/:workspaceId/:email/:code',
                element: <Invite />,
              },
              {
                path: '/zakazbuketov',
                element: <ZakazBuketov />,
              },
              {
                path: '*',
                element: <Login />,
              },
            ],
          },
        ])}
      />
    );
  }

  const parts = [];

  parts.push({
    element: <CabinetLayout />,
    children: [
      {
        path: '/workspaces/:id',
        element: <Workspaces />,
      },
      {
        path: '/workspaces/:id/orderhistory',
        element: (
          <Theme
            configs={{
              order_management: <History />,
              delivery_center: <History1 />,
              shokolatye_cex: <History2 />,
            }}
          />
        ),
      },
      {
        path: '/workspaces/:id/distribution_center',
        element: <Distribution />,
      },
      {
        path: '/workspaces/:id/zoning_management',
        element: <ManagingZoning />,
      },
      {
        path: '/workspaces/:id/order_management',
        element: <ManagingOrder />,
      },
      {
        path: '/workspaces/:id/organizations',
        element: <OrganizationsPage />,
      },

      {
        path: '/workspaces/:id/search',
        element: <Search />,
      },
      {
        path: '/workspaces/:id/journal',
        element: (
          <Theme
            configs={{
              order_management: <Search />,
              delivery_center: <Journal />,
            }}
          />
        ),
      },
      {
        path: '/workspaces/:id/journal/:job_for',
        element: (
          <Theme
            configs={{
              order_management: <Search />,
              delivery_center: <Journal />,
            }}
          />
        ),
      },
      {
        path: '/workspaces/:id/members',
        element: <Members />,
      },
      {
        path: '/workspaces/:id/statistics',
        element: (
          <Theme
            configs={{
              order_management: <Statistics />,
              delivery_center: <Statistics />,
              shokolatye_cex: <Statistics2 />,
            }}
          />
        ),
      },

      {
        path: '/workspaces/:id/profile',
        element: (
          <Theme
            configs={{
              order_management: <Profile />,
              delivery_center: <Profile2 />,
              shokolatye_cex: <Profile3 />,
            }}
          />
        ),
      },

      {
        path: '/login',
        element: <Navigate to="/" />, // Редир
      },
      {
        path: '/zakazbuketov',
        element: <Navigate to="/workspaces/66e170b52fc0a03d58789c24/user" />, // Редир
      },

      {
        path: '/forgot',
        element: <Navigate to="/" />, // Редир
      },
      {
        path: '/invite/:workspaceId/:email/:code',
        element: <Invite />,
      },
      {
        path: '/invite_success/:workspaceId/',
        element: <InviteSuccess />,
      },
    ],
  });

  parts.push({
    element: <CabinetLayout2 />,
    children: [
      {
        path: '/',
        element: <Main />,
      },
    ],
  });
  parts.push({
    element: <MobileLayout />,
    children: [
      {
        path: '/workspaces/:id/user',
        // element: <User />,<MemberApp />
        element: (
          <Theme
            configs={{
              order_management: <User />,
              delivery_center: <Mobile />,
              metafarm_cex: <Mobile2 />,
              shokolatye_cex: <Mobile3 />,
            }}
          />
        ),
      },
    ],
  });

  const router = createBrowserRouter(parts);
  return <RouterProvider router={router} />;
});
export default App;
