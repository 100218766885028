import { Collapse, Image, Modal, Space } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Fragment, useState } from 'react';
import { Comment } from '../../../ types';
import { config } from '../../../../config';
import Icon from '../../../ui/Icon';
import ImageUpload from '../User/TaskItem/ImageUpload';
import * as styles from './index.module.scss';

export interface AccordionProp {
  jobData?: {
    text: string;
    photoFiles: string[];
    bitrixOrderData?: string;
  }[];
  comm?: Comment[];
  targetDate?: string;
  createdAt?: string;
  task?: boolean;
  jobId?: string;
  workId?: string;
}

const Accordion: React.FC<AccordionProp> = ({ jobData, comm, targetDate, jobId, task, workId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState('');
  const [modalUrl, setModalUrl] = useState('');

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Space direction="vertical" className={styles.cols_main}>
        {task && (
          <Collapse
            expandIconPosition="right"
            expandIcon={() => <Icon slug="expandIcon" />}
            collapsible="header"
            defaultActiveKey={['1']}
            items={[
              {
                key: '1',
                label: 'ФОТО ГОТОВОГО ПРОДУКТА',
                children: (
                  <div>
                    {jobId && <ImageUpload jobId={jobId} workspaceId={workId ? workId : ''} />}
                    <div>
                      <p>До завершения осталось:</p>
                      {/* <div className={styles.flex_timer_cont}>
                        <Timer targetDateTimer={targetDateTimer} />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Dropdown menu={menu} open={visible} trigger={['click']}>
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                setVisible(!visible);
                              }}
                            >
                              <Icon slug="icons_add_time" />
                            </a>
                          </Dropdown>
                        </div>
                      </div> */}
                    </div>
                  </div>
                ),
              },
            ]}
          />
        )}

        {jobData && jobData?.[0]?.photoFiles?.length > 0 ? (
          <Collapse
            expandIconPosition="right"
            collapsible="header"
            defaultActiveKey={['1']}
            expandIcon={() => <Icon slug="expandIcon" />}
            items={[
              {
                key: '1',
                label: 'референс',
                children: (
                  <>
                    <div className={styles.img_parent}>
                      {jobData?.[0]?.photoFiles?.map((file, index) => (
                        <Image width={200} src={`${config.EXTERNAL_ADDRESS}/public/${file}`} />
                      ))}
                    </div>
                    <p className={styles.text_jobdata}>{jobData[0]?.text}</p>
                    {jobData?.[0]?.bitrixOrderData && (
                      <p className={styles.text_jobdata}>
                        {jobData[0].bitrixOrderData
                          .replace(/\[\/b\]/g, '')
                          .replace(/\[b\]/g, '')
                          .split('_')
                          .map((line, i) => (
                            <Fragment key={i}>
                              {line}
                              <br />
                            </Fragment>
                          ))}
                      </p>
                    )}
                  </>
                ),
              },
            ]}
          />
        ) : (
          ''
        )}
        <Collapse
          expandIconPosition="right"
          collapsible="header"
          expandIcon={() => <Icon slug="expandIcon" />}
          defaultActiveKey={['2']}
          items={[
            {
              key: '2',
              label: 'сроки',
              children: (
                <>
                  <div>
                    <div className={styles.flex_jobid}>
                      <p>Цель</p>
                      <div className={styles.dateBlock}>
                        {dayjs(targetDate).format('HH : mm')}
                        <span className={styles.separator}>•</span>
                        {dayjs(targetDate).format('DD/MM/YY')}
                      </div>
                    </div>
                    {/* <div className={styles.flex_jobid}>
                        <p>Прогноз</p>
                        <div className={styles.dateBlock}>
                          {dayjs(createdAt).format('HH : mm')}
                          <span className={styles.separator}>•</span>
                          {dayjs(createdAt).format('DD/MM/YY')}
                        </div>
                      </div> */}
                  </div>
                </>
              ),
            },
          ]}
        />
        {comm && comm.length > 0 && (
          <Collapse
            expandIconPosition="right"
            collapsible="header"
            expandIcon={() => <Icon slug="expandIcon" />}
            defaultActiveKey={['3']}
            items={[
              {
                key: '3',
                label: 'Комментарии',
                children: (
                  <>
                    <div>
                      {comm?.map((comment, index) => (
                        <div key={index} className={styles.commments}>
                          <div className={styles.dateBlock}>
                            {dayjs(comment?.createdAt).format('HH : mm')}
                            <span className={styles.separator}>•</span>
                            {dayjs(comment?.createdAt).format('DD/MM/YY')}
                          </div>
                          <p>{comment?.text}</p>

                          {comment?.fileUrls.length > 0 &&
                            comment?.fileUrls?.map((phot, index) => (
                              <div key={index} className={styles.link}>
                                <Image
                                  src={
                                    phot.includes('https')
                                      ? phot
                                      : `${config.EXTERNAL_ADDRESS}/image-uploader/${phot}`
                                  }
                                />
                                {/* <p className={styles.linkk}>
                                  <Button
                                    variant="text"
                                    icon="arrow_vertical"
                                    autoWidth
                                    onClick={() => {
                                      setModalImageUrl(
                                        phot.includes('https')
                                          ? phot
                                          : `${config.EXTERNAL_ADDRESS}/public/${phot}`
                                      );
                                      showModal();
                                    }}
                                  >
                                    Фото
                                  </Button>
                                </p> */}
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  </>
                ),
              },
            ]}
          />
        )}
      </Space>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        className={styles.modal}
        onCancel={handleOk}
      >
        <img src={modalImageUrl} />
      </Modal>
    </>
  );
};

export default observer(Accordion);
