import React, { useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { default as CourierItemProgressDrag, CourierItemProps } from '../CourierItemProgressDrag';
import { COURIER_DATA } from '../Distribution/data';
import * as styles from './index.module.scss';

const DropBox: React.FC = () => {
  const [items, setItems] = useState<CourierItemProps[]>([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropboxRef = useRef<HTMLDivElement>(null);

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'CourierItem',
    drop: (item: CourierItemProps) => handleDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const handleDrop = (item: CourierItemProps) => {
    setItems([item]);
    setIsDropdownVisible(false);
  };

  const toggleDropdown = () => {
    setIsDropdownVisible((prevState) => !prevState);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropboxRef.current && !dropboxRef.current.contains(event.target as Node)) {
      setIsDropdownVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleItemClick = (courier: (typeof COURIER_DATA)[number]) => {
    setItems([
      {
        id: courier.key,
        title: courier.title,
        percent: courier.percent,
        theme: courier.theme,
        photo: courier.image,
      },
    ]);
    setIsDropdownVisible(false);
  };

  return (
    <div
      ref={drop}
      className={isOver && canDrop ? styles.drap_wrp + ' ' + styles.drap_block : styles.drap_wrp}
      onClick={toggleDropdown}
    >
      {items.length > 0 ? (
        <CourierItemProgressDrag
          key={items[0].id!}
          title={items[0].title}
          percent={items[0].percent}
          theme={items[0].theme}
          photo={items[0].photo}
        />
      ) : (
        <>
          <span></span>
          <p>Перетащите или выберите курьера</p>
        </>
      )}

      {isDropdownVisible && (
        <div ref={dropboxRef} className={styles.listdrp}>
          {COURIER_DATA.map((courier, index) => (
            <div
              key={index}
              style={{
                padding: '4px',
                cursor: 'pointer',
                backgroundColor: items[0]?.id === courier.key ? '#f1f1f1' : 'transparent',
              }}
              onClick={() => {
                handleItemClick(courier);
              }}
            >
              <CourierItemProgressDrag
                percent={courier.percent}
                theme={courier.theme}
                title={courier.title}
                photo={courier.image}
                size={32}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropBox;
