import { Collapse, CollapseProps, Flex } from 'antd';
import dayjs from 'dayjs';
import { FC, Fragment, ReactNode } from 'react';
import { StagesStore } from '../../../../../../DataSource/Stores/Cabinet/StagesStore';
import * as styles from './index.module.scss';

interface IProps {
  expandIcon: (panelProps: any) => ReactNode;
}

const SystemTasksBlock: FC<IProps> = ({ expandIcon }) => {
  const systemTasksCollapseItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'СИСТЕМНЫЕ ЗАДАЧИ',
      children: (
        <Flex vertical gap={8}>
          {StagesStore.dataSystemTask.map((task) => (
            <Fragment key={task._id}>
              <div className={styles.field}>
                <p className={styles.name}>
                  #{task._id.slice(-4)}: {task.status}
                </p>
                {dayjs(task.updatedAt).format('DD / MM / YYYY, HH:mm:ss')}
              </div>
              {/*<div className={styles.field}>*/}
              {/*  <p className={styles.name}>UpdatedAt</p>*/}
              {/*  <DatePicker*/}
              {/*    showTime*/}
              {/*    allowClear={false}*/}
              {/*    suffixIcon={null}*/}
              {/*    variant="filled"*/}
              {/*    format="DD / MM / YYYY, HH:mm"*/}
              {/*    style={{ backgroundColor: '#F3F5F6E2', borderRadius: '8px', padding: '4px 8px' }}*/}
              {/*  />*/}
              {/*</div>*/}
            </Fragment>
          ))}
        </Flex>
      ),
    },
  ];

  return (
    <Collapse
      items={systemTasksCollapseItems}
      expandIcon={expandIcon}
      expandIconPosition="right"
      bordered={false}
      defaultActiveKey={[1]}
      ghost
    />
  );
};

export default SystemTasksBlock;
