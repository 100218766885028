import { DownOutlined } from '@ant-design/icons';
import { Dropdown, message, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import Button from '../../components/ui/Button';
import Chip from '../../components/ui/Chip';
import Icon from '../../components/ui/Icon';
import IconButton from '../../components/ui/IconButton';
import { WorkspacesStore } from '../../DataSource/Stores/Cabinet/WorkspacesStore';
import { IWorkspace } from '../../DataSource/Stores/Cabinet/WorkspacesStoreActionsClass';
import LayoutStore from '../../DataSource/Stores/LayoutStore';
import { ModalProps } from '../../layout/Modals';
import * as styles from './index.module.scss';

interface IProps {
  workspace: IWorkspace;
}

const WorkspaceSettingsModal = ({ data, closeModal }: ModalProps<IProps>) => {
  const [workspace, setWorkspace] = useState<IWorkspace | null>(null);

  const workspaceGet = async (workspaceId: string) => {
    try {
      const res = await WorkspacesStore.workspaceById.call(workspaceId);
      setWorkspace(res.data);
    } catch (e: any) {
      console.error(e);
      message.error(e.message.message);
    }
  };

  const inviteReSend = async (email: string) => {
    try {
      if (!workspace) return;
      await WorkspacesStore.inviteReSend.call(workspace._id, email);
      message.success('Email отправлен');
    } catch (e: any) {
      console.error(e);
      message.error(e.message.message);
    }
  };

  const inviteDel = async (email: string) => {
    try {
      if (!workspace) return;
      await WorkspacesStore.inviteDel.call(workspace._id, email);
      await WorkspacesStore.workspaces.call();
      await workspaceGet(workspace._id);
      closeModal();
      message.success('Invite удален');
    } catch (e: any) {
      console.error(e);
      message.error(e.message.message);
    }
  };

  const memberDel = async (userId: string) => {
    try {
      if (!workspace) return;
      await WorkspacesStore.memberDel.call(workspace._id, userId);
      await WorkspacesStore.workspaces.call();
      await workspaceGet(workspace._id);
      message.success('Member удален');
    } catch (e: any) {
      console.error(e);
      message.error(e.message.message);
    }
  };

  useEffect(() => {
    workspaceGet(data.workspace._id).then();
  }, [data]);

  if (!workspace) return null;

  return (
    <div className={styles.wrap}>
      <div className={styles.name}>
        <span>
          Настройки для <span style={{ marginLeft: 6 }}>"{workspace.name}"</span>
        </span>
        <IconButton variant="clear" onClick={closeModal} icon="fi-rr-cross-small" />
      </div>
      <div className={styles.users}>
        <div className={styles.usersTop}>
          <h4>Участники</h4>
          <Button
            autoWidth
            onClick={() => {
              closeModal();
              LayoutStore.openModal('workspaceAddMember', { workspaceId: workspace?._id });
            }}
          >
            Добавить
          </Button>
        </div>
        <div className={styles.usersList}>
          {workspace.members &&
            workspace.members.map((item: any, index: number) => (
              <div key={index} className={styles.usersList__Item}>
                <span>
                  <div>{item.userId.firstname}</div>
                  <div>{item.userId.email}</div>
                </span>
                <div>
                  <Chip color="success" variant="flat">
                    {item.status}
                  </Chip>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: '1',
                          danger: true,
                          label: (
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                memberDel(item._id).then();
                              }}
                            >
                              <Icon slug="fi-rr-trash" />
                              Delete
                            </a>
                          ),
                        },
                      ],
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </div>
              </div>
            ))}
          {workspace.invites &&
            workspace.invites.map((item: any, index: number) => (
              <div key={index} className={styles.usersList__Item}>
                <span>{item.email}</span>
                <div>
                  <Chip color="alert" variant="flat">
                    Pending
                  </Chip>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: '1',
                          label: (
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                inviteReSend(item._id).then();
                              }}
                            >
                              Re-send invitation
                            </a>
                          ),
                        },
                        {
                          key: '2',
                          danger: true,
                          label: (
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                inviteDel(item._id).then();
                              }}
                            >
                              <Icon slug="fi-rr-trash" />
                              Delete
                            </a>
                          ),
                        },
                      ],
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default observer(WorkspaceSettingsModal);
