import { HeaderStore } from '../../DataSource/Stores/HeaderStore';

interface IProps {
  str: string;
  section: string;
}

const translation: any = {
  default: {
    newchall: { ru: 'Новый челлендж', en: 'New challenge', kz: 'Артқа' },
    statususer: { ru: 'Статус', en: 'Status', kz: 'Status' },
    addadmin: { ru: 'Добавить админа', en: 'Add admin', kz: 'Артқа' },
    forgotpas: { ru: 'Забыли пароль', en: 'Forgot password', kz: 'Forgot password' },
    reg: { ru: 'Создайте аккаунт', en: 'Create an account', kz: 'Create an account' },
    add: { ru: 'Добавить', en: 'Add', kz: 'Артқа' },
    runAction: { ru: 'Выполнить', en: 'Run', kz: '' },
    mychall: { ru: 'Мои челленджи', en: 'My challenges', kz: 'Артқа' },
    leaderboard: { ru: 'Список лидеров', en: 'Leaderboard', kz: 'Сақтау' },
    profile: { ru: 'Профиль', en: 'My Profile', kz: 'Растау' },
    support: { ru: 'Поддержка', en: 'Support', kz: 'Растау' },
    close: { ru: 'закрыть', en: 'close', kz: 'жабу' },
    market: { ru: 'Рынок', en: 'Market', kz: 'Нарық' },
    favorites: { ru: 'Избранное', en: 'Watchlist', kz: 'Таңдаулылар' },
    portfolio: { ru: 'Портфель', en: 'Wallet', kz: 'Портфель' },
    all: { ru: 'Все', en: 'All', kz: 'Барлығы' },
    ok: { ru: 'Ок', en: 'Okay', kz: 'Ок' },
    search: { ru: 'Поиск', en: 'Search', kz: 'Іздеу' },
    yes: { ru: 'Да', en: 'Yes', kz: 'Иә' },
    no: { ru: 'Нет', en: 'No', kz: 'Жоқ' },
    error: { ru: 'Ошибка', en: 'Error', kz: 'Қате' },
    send: { ru: 'Отправить', en: 'Send', kz: 'Жіберу' },
    download_pdf: { ru: 'Поделиться', en: 'Share', kz: 'Бөлісу' },
    pieces: { ru: 'шт.', en: 'pcs.', kz: 'нәрсе' },
    clear: { ru: 'очистить', en: 'clear', kz: 'тазалау' },
    open: { ru: 'открыть', en: 'open', kz: 'ашу' },
    source: { ru: 'Источник', en: 'Source', kz: 'Ақпарат көзі' },
    apply: { ru: 'Применить', en: 'Apply', kz: 'Қолдану' },
    exit: { ru: 'Выйти', en: 'Log out', kz: 'Шығу' },
    copied: { ru: 'Скопировано', en: 'Copied', kz: 'Көшірілді' },
    orderpreview: { ru: 'Просмотр заказа', en: 'Order preview', kz: '' },
    tryagain: { ru: 'Пробовать снова', en: ' Try again', kz: '' },
    chooseamount: { ru: 'Выберите сумму', en: 'Choose Product', kz: '' },
    challengedescription: { ru: 'Описание челленджа', en: 'Product description', kz: '' },
    challengeconditions: { ru: 'Условия челленджа', en: 'Product conditions', kz: '' },
    email: { ru: 'Электронная почта', en: 'Email', kz: '' },
    secondname: { ru: 'Фамилия', en: ' Second name', kz: '' },
    firstname: { ru: 'Имя', en: ' First name', kz: '' },
    confirm: { ru: 'Подтвердить', en: 'Confirm', kz: '' },
    delete: { ru: 'Удалить', en: 'Delete', kz: '' },
    cancel: { ru: 'Отменить', en: 'Cancel', kz: '' },
    save: { ru: 'Сохранить', en: 'Save', kz: '' },
    users: { ru: 'Пользователи', en: 'Users', kz: '' },
    challengelist: { ru: 'Список продуктов', en: 'Product list', kz: '' },
    orderslist: { ru: 'Список ордеров', en: 'Orders list', kz: '' },
    ordersparameters: { ru: 'Параметры ордеров', en: 'Orders parameters', kz: '' },
    usersparameters: { ru: 'Параметры пользователей', en: 'Users parameters', kz: '' },
    organizations: {
      ru: 'Органиации',
      en: 'Organizations',
      kz: '',
    },
    organizationsParameters: {
      ru: 'Параметры организаций',
      en: 'Organizations parameters',
      kz: '',
    },
    orders: {
      ru: 'Ордера V2',
      en: 'Orders V2',
      kz: '',
    },
    ordersParameters: {
      ru: 'Параметры ордеров V2',
      en: 'Orders parameters V2',
      kz: '',
    },
    groups: {
      ru: 'Группы',
      en: 'Groups',
      kz: '',
    },
    groupsParameters: {
      ru: 'Параметры групп',
      en: 'Groups parameters',
      kz: '',
    },
    categories: {
      ru: 'Категории',
      en: 'Categories',
      kz: '',
    },
    categoriesParameters: {
      ru: 'Параметры категорий',
      en: 'Categories parameters',
      kz: '',
    },
    products: {
      ru: 'Продукты V2',
      en: 'Products V2',
      kz: '',
    },
    productsParameters: {
      ru: 'Параметры продуктов V2',
      en: 'Products parameters V2',
      kz: '',
    },
    modifiers: {
      ru: 'Модификаторы',
      en: 'Modifiers',
      kz: '',
    },
    modifiersParameters: {
      ru: 'Параметры модификаторов',
      en: 'Modifiers parameters',
      kz: '',
    },
    cities: {
      ru: 'Города',
      en: 'Cities',
      kz: '',
    },
    citiesParameters: {
      ru: 'Параметры городов',
      en: 'Cities parameters',
      kz: '',
    },
    challengeparameters: { ru: 'Параметры продукта', en: 'Product parameters', kz: '' },
    iikoIntegration: { ru: 'Интеграция с айкой', en: 'Iiko intagration', kz: '' },

    newemailadred: {
      ru: 'Введите новый адрес электронной почты',
      en: 'Enter a new email address',
      kz: '',
    },
    newemailaddress: { ru: 'Новый адрес электронной почты', en: 'New email address', kz: '' },
    startbuy: {
      ru: 'Начните бесплатную пробную версию',
      en: 'Start Free Trial',
      kz: '',
    },
    startbuy1: {
      ru: 'Купите прямо сейчас',
      en: 'Buy now',
      kz: '',
    },
    startbuy2: {
      ru: 'Начните бесплатную пробную версию/Купите прямо сейчас',
      en: 'Start Free Trial/Buy now',
      kz: '',
    },

    paymentStatusWaiting: {
      ru: 'Мы обрабатываем ваш запрос в банк. Пожалуйста, подождите.',
      en: 'We are processing your request with the bank. Please wait a moment.',
      kz: '',
    },
    paymentStatusFailed: {
      ru: 'К сожалению, произошла ошибка. Пожалуйста, попробуйте позже.',
      en: 'Oops! Something went wrong. Please try again later.',
      kz: '',
    },
    paymentStatusSuccess: {
      ru: 'Ваш платеж был успешно обработан.',
      en: 'Success! Your payment has been processed successfully.',
      kz: '',
    },
    refresh: { ru: 'Обновить', en: 'Refresh', kz: '' },
    tryAgain: { ru: 'Попробовать еще раз', en: 'Try Again', kz: '' },
    orderNotFound: { ru: 'Заказ не найден', en: 'Order not found', kz: '' },
    internalServerError: { ru: 'Внутренняя ошибка сервера', en: 'Internal server error', kz: '' },
    buy: { ru: 'Купить', en: 'Buy', kz: '' },
    'Sure to cancel?': { ru: 'Уверены, что хотите отменить?', en: 'Sure to cancel?', kz: '' },
    ensure_pass: {
      ru: 'Пожалуйста, убедитесь, что пароль:',
      en: 'Please ensure that your password:',
      kz: 'Құпия сөздің мынаны тексеріңіз:',
    },
    ensure_pass_2: {
      ru: '- Содержит не менее 8 символов',
      en: '- is at least 8 characters long',
      kz: '- Кемінде 8 таңбадан тұрады',
    },
    ensure_pass_3: {
      ru: '- Включает как минимум одну заглавную букву и одну строчную букву',
      en: '- includes at least one uppercase letter and one lowercase letter',
      kz: '- Кем дегенде бір бас әріп пен бір кіші әріпті қамтиды',
    },
    ensure_pass_4: {
      ru: '- Содержит как минимум одну цифру или специальный символ (!, @, #, и т.д.)',
      en: '- contains at least one number or special character (!, @, #, etc.)',
      kz: '- Кемінде бір санды немесе арнайы таңбаны (!, @, #, т.б.) қамтиды',
    },
    pass_8_characters: {
      ru: 'Пароль должен содержать не менее 8 символов',
      en: 'The password must contain at least 8 characters',
      kz: 'Құпия сөз кемінде 8 таңбадан тұруы керек',
    },
    pass_unsafe: {
      ru: 'Пароль не соответствует требованиям безопасности',
      en: 'Password does not meet security requirements',
      kz: 'Құпия сөз қауіпсіздік талаптарына сәйкес келмейді',
    },

    'Passwords do not match': { ru: 'Пароли не совпадают', en: 'Passwords do not match', kz: '' },
    'Password must be at least 8 characters long': {
      ru: 'Длина пароля должна составлять не менее 8 символов',
      en: 'Password must be at least 8 characters long',
      kz: '',
    },
    'Password must contain at least one uppercase letter': {
      ru: 'Пароль должен содержать хотя бы одну заглавную букву',
      en: 'Password must contain at least one uppercase letter',
      kz: '',
    },
    'Password must contain at least one lowercase letter': {
      ru: 'Пароль должен содержать хотя бы одну строчную букву',
      en: 'Password must contain at least one lowercase letter',
    },
    'Password must contain at least one digit or special character (!, @, #, etc.)': {
      ru: 'Пароль должен содержать хотя бы одну цифру или специальный символ (!, @, # и т.д.).',
      en: 'Password must contain at least one digit or special character (!, @, #, etc.)',
    },
    'First name': { ru: 'Имя', en: 'First name', kz: '' },
    'Second name': { ru: 'Фамилия', en: 'Second name', kz: '' },
    Password: { ru: 'Пароль', en: 'Password', kz: '' },
    'Confirm password': { ru: 'Подтвердите пароль', en: 'Confirm password', kz: '' },
    'Please input your first name!': {
      ru: 'Пожалуйста, введите свое имя!',
      en: 'Please input your first name!',
      kz: '',
    },
    'Please input your second name!': {
      ru: 'Пожалуйста, введите фамилию!',
      en: 'Please input your second name!',
      kz: '',
    },
    'Please input your password!': {
      ru: 'Пожалуйста, введите свой пароль!',
      en: 'Please input your password!',
      kz: '',
    },
    'Please confirm password!': {
      ru: 'Пожалуйста, подтвердите пароль!',
      en: 'Please confirm password!',
      kz: '',
    },
    price: {
      ru: 'Стоимость',
      en: 'Price',
      kz: '',
    },
  },
};

export default ({ str, section }: IProps) => {
  if (!section) section = 'default';
  if (!translation[section]) {
    console.error('Нет указанной секции в базе переводов', { str, section });
    return '';
  }
  if (!translation[section][str]) {
    console.error('Нет перевода для указанного ключа', { str, section });
    return '';
  }
  return translation[section][str][HeaderStore.locale];
};
