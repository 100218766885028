import { UniqueIdentifier } from '@dnd-kit/core';
import { message, Result, Spin } from 'antd';
import { observer } from 'mobx-react';
import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';

import { Task } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { arrayDifference } from '../../../../DataSource/Utils/array';
import Button from '../../../ui/Button';
import Icon from '../../../ui/Icon';
import * as styles from '../Mobile/index.module.scss';
import TaskItem from './TaskItem';
import SelectedTask from './TaskItem/SelectedTask';

interface IProps {
  workspaceId: string;
}

const ordersFilter = (task: Task) =>
  !['6707a43f598d48bda5860bfd', '670cc7cd1f9ba41db1b99dd0'].includes(task.stageId);

const activeOrdersFilter = (task: Task) =>
  ['6707a43f598d48bda5860bfd', '670cc7cd1f9ba41db1b99dd0'].includes(task.stageId);

const calculateSortedByOrders = (orders: Task[]) => {
  try {
    let savedItems: string[] = JSON.parse(localStorage.getItem('dc-active-orders') || 'error');
    const orderIds = orders.map((x) => x._id);

    let diff = arrayDifference(
      orders.map((x) => x._id),
      savedItems
    );

    console.log('diff ===========', diff);

    if (diff.length === 0) {
      return savedItems;
    }

    if (orderIds.length > savedItems.length) {
      return [...new Set([...diff, ...savedItems])];
    }

    return savedItems.filter((id) => orderIds.includes(id));
  } catch (e: any) {
    return orders.map((x) => x._id);
  }
};

const JobTabActive: FC<IProps> = ({ workspaceId }) => {
  const wrapOrdRef = useRef<HTMLDivElement>(null);

  const [autoRouteLoading, setAutoRouteLoading] = useState(false);

  const activeOrders = StagesStore.dataMyTasks?.filter(activeOrdersFilter);
  const orders = StagesStore.dataMyTasks?.filter(ordersFilter);

  const [sorted, setSorted] = useState<UniqueIdentifier[]>(calculateSortedByOrders(orders));

  useEffect(() => {
    setSorted(calculateSortedByOrders(orders));
  }, [StagesStore.dataMyTasks]);

  const autoRouteForTasks = async () => {
    if (!navigator?.geolocation) {
      return message.error('Авто маршрут недоступен');
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        console.log(position.coords.latitude, position.coords.longitude);
        setAutoRouteLoading(true);
        const newOrders = await StagesStore.autoRouteForTasks(
          workspaceId,
          `${position.coords.latitude}`,
          `${position.coords.longitude}`
        );
        if (newOrders) setSorted(newOrders.filter(ordersFilter).map((x) => x._id));
        setAutoRouteLoading(false);
      },
      (err) => {
        console.error(err.message);
        message.error('Не удалось получить ваше местополжение');
      },
      {
        enableHighAccuracy: true, // повышенная точность
        timeout: 5000, // максимальное время ожидания
        maximumAge: 0, // не использовать кешированные данные
      }
    );
  };

  const handleTaskItemClick = (task: Task) => {
    StagesStore.setShowTextTaskDelivery(true);
    StagesStore.setLayoutPageDelivery('editor');
    StagesStore.setSelectedTask(task);
    StagesStore.setIsSelectedTask(true);
  };
  console.log(StagesStore.showTextTaskDelivery, 'showTextTaskDelivery');

  useEffect(() => {
    if (!StagesStore.selectedTask?._id) return;
    StagesStore.getMyTaskOpen(workspaceId, StagesStore.selectedTask?._id).catch((e) => {
      console.error(e);
    });
  }, [StagesStore.selectedTask]);

  return (
    <>
      <div className={styles.prompt_wrap} ref={wrapOrdRef}>
        <div className={styles.task_flex}>
          {StagesStore.layoutPageDelivery === 'library' ? (
            <div className={styles.task_child_flex}>
              <div className={styles.jobsGroupTop}>
                <h4>Активный заказ:</h4>
              </div>
              {activeOrders.map((task_i: Task) => (
                <TaskItem
                  key={task_i._id}
                  _id={task_i._id}
                  name={task_i.name}
                  jobId={task_i.jobId}
                  className={styles.jobItemActive}
                  onClick={() => {
                    handleTaskItemClick(task_i);
                  }}
                  stageId={task_i.stageId}
                  workspaceId={task_i.workspaceId}
                  labels={task_i.labels}
                  jobIdShort={task_i.jobIdShort}
                  targetDate={task_i.targetDate}
                  deliveryData={task_i.deliveryData}
                  comments={task_i.comments}
                  isSelectedTask={
                    StagesStore.isSelectedTask && StagesStore.selectedTask?._id === task_i._id
                  }
                  status={''}
                  dueDate={''}
                  submitBtnName={'Завершить заказ'}
                />
              ))}

              <div className={styles.jobsGroupTop}>
                <h4>Все заказы:</h4>

                <Button
                  autoWidth
                  loading={StagesStore.loadingQueue || autoRouteLoading}
                  variant="primary"
                  icon="refresh"
                  onClick={autoRouteForTasks}
                >
                  Авто-маршрут
                </Button>
              </div>

              <div className={styles.task_child_flex}>
                {StagesStore.loadingErrorMyTasks && 'Error loading'}

                {StagesStore.loadingMyTasks && StagesStore.dataMyTasks?.length === 0 ? (
                  <Spin size="small" />
                ) : !orders.length ? (
                  <div className={styles.no_tasks}>
                    <Result status="warning" title="Новых заявок пока нет" />
                  </div>
                ) : (
                  <DraggableCards
                    sorted={sorted}
                    setSorted={setSorted}
                    items={orders}
                    onClick={handleTaskItemClick}
                  />

                  // StagesStore.dataMyTasks
                  //   ?.filter(
                  //     (task_i: Task) =>
                  //       !['6707a43f598d48bda5860bfd', '670cc7cd1f9ba41db1b99dd0'].includes(
                  //         task_i.stageId
                  //       )
                  //   )
                  //   .map((task_i) => (
                  //     <TaskItem
                  //       key={task_i._id}
                  //       _id={task_i._id}
                  //       name={task_i.name}
                  //       jobId={task_i.jobId}
                  //       onClick={() => {
                  //         handleTaskItemClick(task_i);
                  //       }}
                  //       stageId={task_i.stageId}
                  //       workspaceId={task_i.workspaceId}
                  //       jobIdShort={task_i.jobIdShort}
                  //       targetDate={task_i.targetDate}
                  //       deliveryData={task_i.deliveryData}
                  //       comments={task_i.comments}
                  //       isSelectedTask={
                  //         StagesStore.isSelectedTask && StagesStore.selectedTask?._id === task_i._id
                  //       }
                  //       status={''}
                  //       dueDate={''}
                  //       submitBtnName={'Начать выполнение'}
                  //     />
                  //   ))
                )}
              </div>
            </div>
          ) : (
            <SelectedTask
              btnName={
                ['6707a43f598d48bda5860bfd', '670cc7cd1f9ba41db1b99dd0'].includes(
                  StagesStore.selectedTask?.stageId
                )
                  ? 'Завершить заказ'
                  : 'Начать выполнение'
              }
              workId={workspaceId}
            />
          )}
        </div>
      </div>
    </>
  );
};

const DraggableCards = ({
  sorted,
  setSorted,
  items,
  onClick,
}: {
  sorted: UniqueIdentifier[];
  setSorted: Dispatch<SetStateAction<UniqueIdentifier[]>>;
  items: Task[];
  onClick: (task: Task) => void;
}) => {
  useEffect(() => {
    // Сохраняем порядок в localStorage при изменении
    localStorage.setItem('dc-active-orders', JSON.stringify(sorted));
  }, [sorted]);

  const handleMoveUp = (id: string) => {
    setSorted((sortedIds) => {
      const index = sortedIds.indexOf(id);
      if (index > 0) {
        return [
          ...sortedIds.slice(0, index - 1),
          sortedIds[index],
          sortedIds[index - 1],
          ...sortedIds.slice(index + 1),
        ];
      }
      return sortedIds;
    });
  };

  const handleMoveDown = (id: string) => {
    setSorted((sortedIds) => {
      const index = sortedIds.indexOf(id);
      if (index < sortedIds.length - 1) {
        return [
          ...sortedIds.slice(0, index),
          sortedIds[index + 1],
          sortedIds[index],
          ...sortedIds.slice(index + 2),
        ];
      }
      return sortedIds;
    });
  };

  const sortedItems = sorted
    .map((id) => items.find((item) => item._id === id))
    .filter(Boolean) as Task[];

  return (
    <div>
      {sortedItems.map((task_i) => (
        <DraggableItem
          key={task_i._id}
          id={task_i._id}
          onMoveUp={() => handleMoveUp(task_i._id)}
          onMoveDown={() => handleMoveDown(task_i._id)}
        >
          <TaskItem
            key={task_i._id}
            _id={task_i._id}
            name={task_i.name}
            jobId={task_i.jobId}
            onClick={() => {
              onClick(task_i);
            }}
            stageId={task_i.stageId}
            workspaceId={task_i.workspaceId}
            //bitrixId2={task_i.externalId?.bitrixId}
            //jiraId2={task_i.externalId?.jiraId}
            jobIdShort={task_i.jobIdShort}
            targetDate={task_i.targetDate}
            labels={task_i.labels}
            deliveryData={task_i.deliveryData}
            comments={task_i.comments}
            isSelectedTask={
              StagesStore.isSelectedTask && StagesStore.selectedTask?._id === task_i._id
            }
            status={''}
            dueDate={''}
            submitBtnName={'Начать выполнение'}
          />
        </DraggableItem>
      ))}
    </div>
  );
};

const DraggableItem = ({
  children,
  id,
  onMoveUp,
  onMoveDown,
}: {
  children: ReactNode;
  id: string;
  onMoveUp: () => void;
  onMoveDown: () => void;
}) => {
  return (
    <div className={styles.flex_block} style={{ flexDirection: 'row-reverse' }}>
      {children}
      <div className={styles.actionButtons}>
        <button onClick={onMoveUp}>
          <Icon slug="fi fi-rr-arrow-up" />
        </button>
        <button onClick={onMoveDown}>
          <Icon slug="fi fi-rr-arrow-down" />
        </button>
      </div>
    </div>
  );
};

export default observer(JobTabActive);
