import { makeObservable, observable } from 'mobx';
import { UserStoreActionsClass } from '../Stores/UserStoreActionsClass';

export class User extends UserStoreActionsClass {
  @observable
  photo: string = '';

  @observable
  firstname: string = '';

  @observable
  secondname: string = '';

  @observable
  _id: string = '';

  @observable
  email: string | null = null;

  @observable
  password: string | null = null;

  @observable
  identifier: string | null = null;

  @observable
  active: boolean = false;

  @observable
  token: undefined = undefined;

  @observable
  role: undefined = undefined;

  /**
   * новй пароль мы должны хранить в отдельном месте, чтобы понимать, хотят его поменять или нет
   */
  @observable
  _newPassword = '';

  constructor(data: any) {
    super();
    makeObservable(this);
    // makeAutoObservable(this);
    // console.log('---- ww|User|data=', data);

    this.email = data.email?.toLowerCase();

    this.photo = data.photo;
    this.firstname = data.firstname;
    this.secondname = data.secondname;
    this.role = data.role;
    this.identifier = data.identifier;
    this._id = data._id;

    this.active = !data || !data.hasOwnProperty('active') || !!data.active; // по умолчанию активен

    // if (data.hasOwnProperty('password')) {
    //   this.password = data.password;
    // }
  }

  get isAdminOrSuperAdmin() {
    return this.role == 'super_admin' || this.isAdmin;
  }

  get isAdmin() {
    return this.role == 'admin';
  }

  get isSuperAdmin() {
    return this.role == 'super_admin';
  }
}
