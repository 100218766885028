import { config } from '../../config';

/**
 *
 */
export function basicAuthHeaders() {
  return {
    Authorization: 'Basic ' + btoa(config.get('CLIENT_ID') + ':' + config.get('CLIENT_SECRET')),
  };
}
