// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VZEwrTBNopibsBxPbC81{display:flex;flex-direction:column;align-items:center;justify-content:center}.KmY4A57NG3qLVp1J65cJ{position:relative;align-items:center;display:flex}.KmY4A57NG3qLVp1J65cJ p{font-weight:400;font-size:15px;text-transform:none;margin:0 0 0 8px;color:#000}.ENLAWGtpphpPOCUoJpuA{position:absolute;top:0;left:0;width:100%;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.ENLAWGtpphpPOCUoJpuA img{width:36px;height:36px;border-radius:50%}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/Kanban/CourierItemProgress/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,iBAAA,CACA,kBAAA,CACA,YAAA,CACA,wBACE,eAAA,CACA,cAAA,CACA,mBAAA,CACA,gBAAA,CACA,UAAA,CAIJ,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,0BACE,UAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".bl_progress_course{display:flex;flex-direction:column;align-items:center;justify-content:center}.progress_container{position:relative;align-items:center;display:flex}.progress_container p{font-weight:400;font-size:15px;text-transform:none;margin:0 0 0 8px;color:#000}.progress_content{position:absolute;top:0;left:0;width:100%;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.progress_content img{width:36px;height:36px;border-radius:50%}"],"sourceRoot":""}]);
// Exports
export var bl_progress_course = `VZEwrTBNopibsBxPbC81`;
export var progress_container = `KmY4A57NG3qLVp1J65cJ`;
export var progress_content = `ENLAWGtpphpPOCUoJpuA`;
export default ___CSS_LOADER_EXPORT___;
