import { Result } from 'antd';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Lang from './Lang';

const Success = observer(() => (
  <div className="not_found_wrp">
    <div className="not_found">
      <Result
        status="success"
        title={Lang({ str: 'paymentStatusSuccess', section: 'default' })}
        // subTitle="The payment has been accepted for processing"
        extra={[
          <Link className="main_page_but" key={'success'} to={'/product_accounts'}>
            {Lang({ str: 'mychall', section: 'default' })}
          </Link>,
        ]}
      />
    </div>
  </div>
));

export default Success;
