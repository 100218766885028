import { action, makeAutoObservable, observable } from 'mobx';

export class ManagerStoreActionsClass {
  @observable isModalOpen: boolean = false;
  @observable ModalOpenOrder: boolean = false;
  @observable isModalOpenCouriers: boolean = false;
  @observable modalOpenOrderItem: boolean = false;
  @observable isModalOpenZone: boolean = false;
  @observable messageOpenBl: boolean = false;
  @observable modalOpenCompany: boolean = false;

  @observable typePage: string = '';
  @observable layoutPage: string = 'library';

  @observable title: string = '';

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  @action
  setLayoutPage(value: string) {
    this.layoutPage = value;
  }

  @action
  setTitle(newTitle: string) {
    this.title = newTitle;
  }
}
