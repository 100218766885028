// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cP9aAsov4QMWOORkPBMF{width:30%;border:1px solid #e2e2e1;border-radius:20px;padding:24px 0px}._slHq9W9IeS9_Olpaxk_{top:32px !important}.GviyVt4HZzbN5HPAVFQI{color:#000;font-size:20px;font-weight:500}.S1coEF_aa7qnmcqS4F2H{background-color:#3a6def;border-radius:50px;padding:6px 16px;border:0;color:#fff;height:auto}.S1coEF_aa7qnmcqS4F2H:hover{background-color:#3a6def !important;color:#fff !important}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/Kanban/ManagingZoning/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA,CACA,wBAAA,CACA,kBAAA,CACA,gBAAA,CAEF,sBACE,mBAAA,CAGF,sBACE,UAAA,CACA,cAAA,CACA,eAAA,CAEF,sBACE,wBAAA,CACA,kBAAA,CACA,gBAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,4BACE,mCAAA,CACA,qBAAA","sourcesContent":[".new_zone{width:30%;border:1px solid #e2e2e1;border-radius:20px;padding:24px 0px}.tabl_bl__buttons{top:32px !important}.title{color:#000;font-size:20px;font-weight:500}.but_neword{background-color:#3a6def;border-radius:50px;padding:6px 16px;border:0;color:#fff;height:auto}.but_neword:hover{background-color:#3a6def !important;color:#fff !important}"],"sourceRoot":""}]);
// Exports
export var new_zone = `cP9aAsov4QMWOORkPBMF`;
export var tabl_bl__buttons = `_slHq9W9IeS9_Olpaxk_`;
export var title = `GviyVt4HZzbN5HPAVFQI`;
export var but_neword = `S1coEF_aa7qnmcqS4F2H`;
export default ___CSS_LOADER_EXPORT___;
