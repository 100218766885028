import { message, Result, Spin } from 'antd';
import dayjs from 'dayjs';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { JobsDataType, StagesDataType } from '../../ types';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import uWS from '../../../uWS';
import JobsKanbanDeck from '../JobsKanbanDeck';
import * as styles from './index.module.scss';
import JobColumn from '../JobsKanbanDeck/Column';
import JobColumnItem from './JobColumnItem';

interface IProps {
  workspaceId: string;
}

const NewJobsBlock: FC<IProps> = ({ workspaceId }) => {
  useEffect(() => {
    if (workspaceId) {
      StagesStore.getList(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  }, [workspaceId]);

  useEffect(() => {
    StagesStore.getListJobs(workspaceId).catch((e) => {
      console.error(e);
    });
  }, [workspaceId]);

  useEffect(() => {
    if (!workspaceId) return;
    const eventStatus = uWS.subscribe(`workspaces/${workspaceId}/changeJob`, (data: any) => {
      if (data.error) {
        console.error('Error subscribing to workspace changeJob:', data.error);
        return;
      }

      console.log('changeJob:', data);
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    });

    return () => {
      if (eventStatus) uWS.unSubscribe(`workspaces/${workspaceId}/changeJob`);
    };
  }, [workspaceId]);

  const onDropHandler = async (job: JobsDataType, stage: StagesDataType) => {
    console.log('DROP', toJS(job), toJS(stage));

    StagesStore.setDataJobList(
      StagesStore.dataJobsList.map((item) =>
        item._id === job._id ? { ...item, stageId: stage._id } : item
      )
    );

    await StagesStore.updateJobStage(workspaceId, job._id, stage._id);

    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.isModalOpen = false;
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  const calculateJobHeight = (job: JobsDataType) => {
    let height = 120;

    if (job.assignedTo) {
      height += 40;
    }

    if (job.expectedDate && job.stageId === '66e194a01725b77d117c4d5a') {
      height += 25;
    }

    return height + 8;
  };

  return (
    <div className={styles.newJobsBlock}>
      <div className={styles.newJobsBlockBody}>
        {StagesStore.loadingError && 'Error loading'}
        {StagesStore.loading && StagesStore.dataList.length === 0 && <Spin size="small" />}
        {!StagesStore.loading && StagesStore.dataList.length === 0 && (
          <div>
            <Result status="warning" title="You don't have any stages" />
          </div>
        )}

        {!StagesStore.loading && StagesStore.dataList.length !== 0 && (
          <JobsKanbanDeck
            columns={StagesStore.dataList}
            items={toJS(StagesStore.dataJobsList)}
            columnKeyField={'stageId'}
            renderColumn={(column) => (
              <JobColumn {...column} variant="ZakazBuketov" children={null} />
            )}
            onDrop={onDropHandler}
            sort={(a, b) => {
              if (
                a.stageId === '66e194591725b77d117c4d54' ||
                a.stageId === '66e194841725b77d117c4d57' ||
                a.stageId === '66e194a01725b77d117c4d5a'
              ) {
                return dayjs(b.targetDate).unix() + dayjs(a.targetDate).unix();
              } else {
                return dayjs(b.targetDate).unix() - dayjs(a.targetDate).unix();
              }
            }}
            rowHeight={calculateJobHeight}
          >
            {(item, dragProps) => <JobColumnItem {...item} dragProps={dragProps} />}
          </JobsKanbanDeck>
        )}
      </div>
    </div>
  );
};

export default observer(NewJobsBlock);
