import { Button, Flex, message, Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { JobFor, JobsDataType } from '../../ types';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../DataSource/Stores/Cabinet/WorkspacesStore';
import { ManagerStore } from '../../../DataSource/Stores/ManagerStore';
import { useDebounce } from '../../../utils';
import Icon from '../../ui/Icon';
import Input from '../../ui/Input';
import JobDrawer2 from '../DeliveryCenter/Kanban/JobColumnItem/JobDrawer';
import ModalAdd from '../DeliveryCenter/Kanban/ManagingOrder/Modal/Add';
import JobDrawer1 from '../NewJobsBlock/JobColumnItem/JobDrawer';
import * as styles from './index.module.scss';

const Search: React.FC = () => {
  const { id, job_for } = useParams();

  const [filteredData, setFilteredData] = useState<JobsDataType[]>([]);
  const [query, setQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [selectedJob, setSelectedJob] = useState<JobsDataType | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const debouncedQuery = useDebounce(query, 500);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!id) return;

      if (!job_for) {
        await StagesStore.getListJobs(id);
        setFilteredData(StagesStore.dataJobsList);
      } else {
        const res = await WorkspacesStore.getJobsByJobFor.call(id, job_for as JobFor);
        setFilteredData(res.data.data);
      }

      await StagesStore.getList(id);

      try {
        const res = await WorkspacesStore.membersGet.call(id);
        WorkspacesStore.setMembers(res.data);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }
    })();
  }, [id, job_for]);

  useEffect(() => {
    if (debouncedQuery) {
      getJobsById(parseInt(debouncedQuery));
    } else {
      setFilteredData(StagesStore.dataJobsList);
    }
  }, [debouncedQuery]);

  const getJobsById = async (value: number) => {
    if (!id) return;
    setIsSearching(true);
    try {
      const res = await WorkspacesStore.getJobsById.call(id, value);
      setFilteredData(res.data);
    } catch (e) {
      setFilteredData([]);
      console.error(e);
    }
    setIsSearching(false);
  };

  const handleCopy = async (value: any) => {
    try {
      await navigator.clipboard.writeText(value);
      message.info('Скопировано', 2);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleRowClick = (record: JobsDataType) => {
    setSelectedJob(record);

    StagesStore.getJobsId(id ? id : '', record._id).catch((e) => {
      console.error(e);
    });
    setIsDrawerOpen(true);
  };

  const columns: TableColumnsType<JobsDataType> = [
    {
      title: 'Job ID',
      dataIndex: 'jobId',
      key: 'jobId',
      render: (text: number) =>
        text || text === 0 ? (
          <Flex align="center" gap={12}>
            {text}
            <div
              className={styles.copyBtn}
              onClick={(e) => {
                e.stopPropagation();
                handleCopy(text);
              }}
            >
              <Icon slug="copy" />
            </div>
          </Flex>
        ) : (
          '-'
        ),
    },
    {
      title: 'External ID',
      dataIndex: 'externalId',
      key: 'externalId',
      render: (_: string, record: JobsDataType) =>
        record.externalId?.bitrixId ? (
          <Flex gap={12}>
            {record.externalId?.bitrixId}
            <div
              className={styles.copyBtn}
              onClick={(e) => {
                e.stopPropagation();
                handleCopy(record.externalId?.bitrixId as string);
              }}
            >
              <Icon slug="copy" />
            </div>
          </Flex>
        ) : (
          '-'
        ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Этап',
      dataIndex: 'stageId',
      key: 'stageId',
      render: (_: string, record: JobsDataType) =>
        StagesStore.dataList.find((item) => item._id === record.stageId)?.name,
    },
    {
      title: 'Комментарии',
      dataIndex: 'comments',
      key: 'comments',
      render: (text: Comment[]) => text.length,
    },
    {
      title: 'JobFor',
      dataIndex: 'jobFor',
      key: 'jobFor',
      render: (text: JobFor) => (text ? text.toUpperCase() : '-'),
    },
    {
      title: 'Создан',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => dayjs(text).format('HH:mm | DD.MM.YYYY'),
      sorter: (a: any, b: any) => {
        if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) return -1;
        if (dayjs(a.createdAt).isAfter(dayjs(b.createdAt))) return 1;
        return 0;
      },
    },
    {
      title: 'Целевая дата',
      dataIndex: 'targetDate',
      key: 'targetDate',
      render: (text: string) => dayjs(text).format('HH:mm | DD.MM.YYYY'),
      sorter: (a: any, b: any) => {
        if (dayjs(a.targetDate).isBefore(dayjs(b.targetDate))) return -1;
        if (dayjs(a.targetDate).isAfter(dayjs(b.targetDate))) return 1;
        return 0;
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Изменен',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => dayjs(text).format('HH:mm | DD.MM.YYYY'),
      sorter: (a: any, b: any) => {
        if (dayjs(a.updatedAt).isBefore(dayjs(b.updatedAt))) return -1;
        if (dayjs(a.updatedAt).isAfter(dayjs(b.updatedAt))) return 1;
        return 0;
      },
    },
    {
      title: 'Исполнитель',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      render: (_: string, record: JobsDataType) =>
        record.assignedTo && WorkspacesStore.members[record.assignedTo]?.userId?.firstname,
    },
  ];

  return (
    <div className={styles.searchPage}>
      <div className={styles.searchPageTop}>
        <div className={styles.title}>
          {id === '6707a212598d48bda5860bf0' &&
            (job_for ? `Журнал ${job_for.toUpperCase()}` : 'Журнал Заказов')}
          {id !== '6707a212598d48bda5860bf0' && 'Поиск'}
        </div>
        <Input
          className={styles.searchInput}
          width="400px"
          placeholder="Поиск по Job ID / External ID"
          icon="search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>

      {id === '6707a212598d48bda5860bf0' && (
        <Flex gap={24}>
          {!job_for &&
            ['sau', 'lulu', 'metabody'].map((item) => (
              <Button key={item} onClick={() => navigate(`/workspaces/${id}/journal/${item}`)}>
                {item.toUpperCase()}
              </Button>
            ))}

          <Button
            className={styles.btn}
            onClick={() => (ManagerStore.ModalOpenOrder = true)}
            type="primary"
          >
            Новый заказ
          </Button>
        </Flex>
      )}

      <Table
        columns={columns}
        dataSource={filteredData}
        loading={StagesStore.loadingArchives || isSearching}
        rowKey="_id"
        onRow={(record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
          };
        }}
        components={{
          body: {
            wrapper: (props: any) => {
              if (StagesStore.loadingArchivesEr) {
                return (
                  <div>
                    <p>Error loading data: {StagesStore.loadingArchivesEr}</p>
                  </div>
                );
              }
              return <tbody {...props} />;
            },
          },
        }}
      />

      <ModalAdd _jobFor={job_for as JobFor} />

      {selectedJob && id === '66e170b52fc0a03d58789c24' && (
        <JobDrawer1
          {...selectedJob}
          _id={selectedJob._id}
          workspaceId={id}
          bitrixId={selectedJob?.externalId?.bitrixId}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}

      {selectedJob && id === '6707a212598d48bda5860bf0' && (
        <JobDrawer2
          {...selectedJob}
          _id={selectedJob._id}
          workspaceId={id}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}
    </div>
  );
};

export default observer(Search);
