import { basicRequestGet } from '../../Requests/basicRequestGet';
import { observable } from 'mobx';
import { PaymentOrderStatusResult, PaymentResult } from '../../../components/Waiting';

class PaymentStoreClass {
  @observable
  loadingError: boolean = false;

  @observable
  loading: boolean = false;

  /**
   *
   */
  async getPaymentStatus(data: PaymentResult) {
    console.log('---- ww|getPaymentStatus');

    this.loading = true;
    this.loadingError = false;

    try {
      const res = await basicRequestGet(
        `/payment/order/${data.pg_order_id}/status/${data.pg_payment_id}/payment/${data.payment}`
      );
      console.log('---- ww|getPaymentStatus|res.data=', res.data);
      return res.data as PaymentOrderStatusResult;
    } catch (error) {
      console.error('getPaymentStatus|error=', error);
      this.loadingError = true;
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const PaymentStore: PaymentStoreClass = new PaymentStoreClass();

export { PaymentStore };
