import { HomeFilled } from '@ant-design/icons';
import { observer } from 'mobx-react';
import img404 from '../assets/404.svg';
import * as styles from './Auth/Auth.module.scss';

const Login = observer(() => (
  <div className="not_found_wrp">
    <div className="not_found">
      <img src={img404} />
      <h4 className={styles.title}>404</h4>
      <p>Sorry, page not found</p>
      <span>This page you are looking not available</span>
      <a href="/">
        <HomeFilled /> Back To Home
      </a>
    </div>
  </div>
));

export default Login;
