import { observer } from 'mobx-react-lite';
import { ChangeEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { WorkspacesStore } from '../../DataSource/Stores/Cabinet/WorkspacesStore';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import { ModalProps } from '../../layout/Modals';
import * as styles from './index.module.scss';

const WorkspaceCreateModal = ({ closeModal }: ModalProps) => {
  const [form, setForm] = useState({ name: '' });

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onCreate = async () => {
    try {
      const res = await WorkspacesStore.createWorkspace.call(form);
      WorkspacesStore.workspaces.mutate((x) => [...x, res.data]);
      closeModal();
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  return (
    <div className={styles.wrap}>
      <h4>Новая компания</h4>
      <Input value={form.name} name="name" onChange={onChangeHandler} />
      <Button onClick={onCreate} loading={WorkspacesStore.createWorkspace.loading}>
        Создать
      </Button>
    </div>
  );
};

export default observer(WorkspaceCreateModal);
