import { KeyOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography, type FormProps } from 'antd';
import { useLocation } from 'react-router-dom';
import * as styles from './Auth.module.scss';

type FieldType = {
  email?: string;
};

function NewPass() {
  const [form] = Form.useForm();

  const location = useLocation();

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {};

  const confirmPasswordValidator = (rule: any, value: any, callback: any) => {
    const passwordFieldValue = form.getFieldValue('password');
    if (value && passwordFieldValue && value !== passwordFieldValue) {
      callback('Passwords do not match');
    } else {
      callback();
    }
  };

  const onFinishCreateFailed: FormProps<FieldTypeForgot>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
    // setActive(true);
  };
  const { Title } = Typography;

  return (
    <div className={styles.fon_wrp}>
      <div>
        <Title level={2}> Введите новый пароль</Title>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinishFailed={onFinishCreateFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item<FieldTypeForgot>
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password prefix={<KeyOutlined />} placeholder="Новый пароль" />
          </Form.Item>
          <Form.Item<FieldTypeForgot>
            name="confirmpassword"
            rules={[
              { required: true, message: 'Please confirm password!' },
              { validator: confirmPasswordValidator },
            ]}
          >
            <Input.Password prefix={<KeyOutlined />} placeholder="Введите повторно" />
          </Form.Item>

          <Form.Item>
            <Button className={styles.button_prime} type="primary" htmlType="submit">
              Подтвердить
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default NewPass;
