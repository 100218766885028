import { useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { WorkspacesStore } from '../../DataSource/Stores/Cabinet/WorkspacesStore';
import { AuthStoreData } from '../../DataSource/Stores/AuthStoreDataClass';
import toast from 'react-hot-toast';
import { IWorkspace } from '../../DataSource/Stores/Cabinet/WorkspacesStoreActionsClass';

const InviteSuccess = () => {
  const { workspaceId } = useParams();
  const [workspace, setWorkspace] = useState<IWorkspace | null>(null);

  useEffect(() => {
    if (!workspaceId) return;
    (async () => {
      try {
        const res = await WorkspacesStore.workspaceById.call(workspaceId);
        setWorkspace(res.data);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }
    })();
  }, [workspaceId]);

  if (!workspace) return null;

  return (
    <div style={{ paddingTop: 100 }}>
      <p>{AuthStoreData.user.firstname}</p>
      <p>Успешно добавлен в "{workspace.name}"</p>
      {workspace.category === 'order_management' && (
        <Link to={`/workspaces/${workspace._id}`}>Открыть</Link>
      )}
      {workspace.category === 'aiAssistant' && (
        <a href={`/aida/${workspace._id}/schedule`}>Открыть</a>
      )}
    </div>
  );
};

export default observer(InviteSuccess);
