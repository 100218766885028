import { DatePicker, Dropdown, Flex, Image, Modal, Table, TableColumnsType } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { MemberUser, StagesDataType, Statistic } from '../../../ types';
import { AuthStore } from '../../../../DataSource/Stores/AuthStore';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import uWS from '../../../../uWS';
import Clickable from '../../../ui/Clickable';
import Icon from '../../../ui/Icon';

import { config } from '../../../../config';
import * as styles from './index.module.scss';

type WorkspaceIdParams = {
  id?: string;
};

type DateRange = 'Сегодня' | 'Вчера' | 'Неделя' | 'Месяц' | 'Выбрать свой';

const { RangePicker } = DatePicker;

const MobileStat: React.FC = () => {
  // const workspaceId = config.WORKSPACE_ID;
  const workspaceId = '66e170b52fc0a03d58789c24';

  const { id } = useParams<WorkspaceIdParams>();

  const [loading, setLoading] = useState(false);
  const [visibleCartStatic, setVisibleCartStatic] = useState(false);
  const [stages, setStages] = useState<StagesDataType[]>([]);
  const [selectedUser, setSelectedUser] = useState<MemberUser | null>(null);
  const user = AuthStore.currentUserData.user;
  const [dateRange, setDateRange] = useState<DateRange>('Сегодня');
  const [customDateRange, setCustomDateRange] = useState<[Dayjs | null, Dayjs | null] | null>(null);

  useEffect(() => {
    if (!id) return;
    (async () => {
      setLoading(true);
      try {
        // if (!Object.keys(StagesStore.dataStatus)?.length) {
        //   await StagesStore.getUserStatus(id);
        // }
        const res = await WorkspacesStore.membersGet.call(id);
        WorkspacesStore.setMembers(res.data);
        console.log(res);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }

      try {
        const res = await WorkspacesStore.getJobsInWork.call(id);
        WorkspacesStore.setInWorkJobs(res.data);
        console.log(res.data, 'sfdfdff');
        console.log(res.data);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }

      await StagesStore.getList(id);
      setStages(StagesStore.dataList);
      setLoading(false);
    })();
  }, [id]);

  // вот это как пример подписки на смену статуса операторов в ра��очем пространстве
  useEffect(() => {
    if (!id) return;
    const eventStatus = uWS.subscribe(`workspaces/${id}/userOnline`, (data: any) => {
      if (data.error) {
        console.error('Error subscribing to workspace user online:', data.error);
        return;
      }

      console.log('Online user:', data);
      // OperatorsStore.setOnlineUser(data);
      (async () => {
        // setLoading(true);
        try {
          // await StagesStore.getUserStatus(id);
          const res = await WorkspacesStore.membersGet.call(id);
          WorkspacesStore.setMembers(res.data);
          console.log(res);
        } catch (e: any) {
          console.error(e);
          toast.error(e.message.message);
        }

        try {
          const res = await WorkspacesStore.getJobsInWork.call(id);
          WorkspacesStore.setInWorkJobs(res.data);
          console.log(res.data);
        } catch (e: any) {
          console.error(e);
          toast.error(e.message.message);
        }

        await StagesStore.getList(id);
        setStages(StagesStore.dataList);
        setLoading(false);
      })();
    });

    return () => {
      if (eventStatus) uWS.unSubscribe(`workspaces/${id}/userOnline`);
    };
  }, [id]);
  const getDateRange = (range: string) => {
    let startDate, endDate;

    switch (range) {
      case 'Сегодня':
        startDate = dayjs().startOf('day').toDate();
        endDate = dayjs().endOf('day').toDate();
        break;

      case 'Вчера':
        startDate = dayjs().subtract(1, 'day').startOf('day').toDate();
        endDate = dayjs().subtract(1, 'day').endOf('day').toDate();
        break;

      case 'Неделя':
        startDate = dayjs().subtract(6, 'day').startOf('day').toDate();
        endDate = dayjs().endOf('day').toDate();
        break;

      case 'Месяц':
        startDate = dayjs().subtract(30, 'day').startOf('day').toDate();
        endDate = dayjs().toDate();
        break;

      case 'Выбрать свой':
        if (customDateRange && customDateRange[0] && customDateRange[1]) {
          startDate = customDateRange[0].startOf('day').toDate();
          endDate = customDateRange[1].endOf('day').toDate();
        } else {
          startDate = dayjs().toDate();
          endDate = dayjs().toDate();
        }
        break;

      default:
        startDate = dayjs().toDate();
        endDate = dayjs().toDate();
    }

    return { startDate, endDate };
  };

  const handleDateRange = (value: DateRange) => {
    if (!user) return;
    setDateRange(value);

    const { startDate, endDate } = getDateRange(value);
    StagesStore.getUserStatistic(workspaceId, user._id, startDate, endDate).catch((e) => {
      console.error(e);
    });

    console.log(startDate, endDate, 'startDaddddte');
  };

  // Функция для обработки пользовательского диапазона дат
  const handleCustomDateRange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    setCustomDateRange(dates);

    if (!selectedUser || !dates || !dates[0] || !dates[1]) return;
    const startDate = dates[0].startOf('day').toDate();
    const endDate = dates[1].endOf('day').toDate();

    StagesStore.getUserStatistic(workspaceId, user._id, startDate, endDate).catch((e) => {
      console.error(e);
    });
  };

  const columns2: TableColumnsType<Statistic> = [
    {
      title: 'BitrixId',
      dataIndex: 'bitrixId',
      key: 'bitrixId',
      render: (text: any, record: Statistic) => (
        <p className={styles.name}>{record.externalId?.bitrixId}</p>
      ),
    },

    {
      title: 'Дата и Время',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: any, record: Statistic) => (
        <p className={styles.name}> {dayjs(record.createdAt).format('DD/MM/YYYY HH : mm')}</p>
      ),
    },
    {
      title: 'Длительность',
      dataIndex: 'duration',
      key: 'duration',

      render: (text: any, record: Statistic) => (
        <p className={styles.name}> {Math.round((record.duration / 60) * 100) / 100} мин.</p>
      ),
      sorter: (a, b) => a.duration / 60 - b.duration / 2,
    },
  ];

  useEffect(() => {
    console.log('Selected User:', user._id);

    if (user) {
      handleDateRange(dateRange);
    }
  }, [dateRange, user]);

  const UserDuration: React.FC = () => {
    const [averageDuration, setAverageDuration] = useState<string>('0,00');

    useEffect(() => {
      const userStatistics = StagesStore.dataUserStatistic;

      if (userStatistics.length > 0) {
        const totalDuration = userStatistics.reduce((total, item) => total + item.duration, 0);
        const average = totalDuration / userStatistics.length;
        const result = (average / 60).toFixed(2).replace('.', ',');
        setAverageDuration(result);
      } else {
        setAverageDuration('0,00');
      }
    }, [StagesStore.dataUserStatistic]);

    return <span>{averageDuration}</span>;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<string | undefined>(undefined);

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedRowData(undefined);
  };

  const [selectedJobData, setSelectedJobData] = useState<any>(null);

  const handleRowClick = (jobid: string) => {
    try {
      const userStatistics = StagesStore.dataUserStatistic;
      const selectedJob = userStatistics.find((item) => item.jobId === jobid);

      if (selectedJob) {
        setSelectedRowData(jobid);
        setSelectedJobData(selectedJob);
        setIsModalVisible(true);
      } else {
        console.warn('Данные по указанному jobId не найдены');
      }
    } catch (error) {
      console.error('Ошибка при обработке клика:', error);
    }
  };
  return (
    <div className={styles.membersPage}>
      <div className={styles.block_stat} style={{ display: 'block' }}>
        <div className={styles.second_bl_block}>
          <Flex vertical gap={16}>
            <div className={styles.flex}>
              <p className={styles.title_ad}>Статистика заказов</p>
            </div>

            <Flex vertical gap={8}>
              <div className={styles.rangePickerWrap}>
                <p className={styles.name}>Диапазон:</p>
                <Dropdown
                  menu={{
                    items: [
                      { key: 1, label: 'Сегодня', onClick: () => handleDateRange('Сегодня') },
                      { key: 2, label: 'Вчера', onClick: () => handleDateRange('Вчера') },
                      { key: 3, label: 'Неделя', onClick: () => handleDateRange('Неделя') },
                      { key: 4, label: 'Месяц', onClick: () => handleDateRange('Месяц') },
                      {
                        key: 5,
                        label: 'Выбрать свой',
                        onClick: () => handleDateRange('Выбрать свой'),
                      },
                    ],
                  }}
                  trigger={['click']}
                >
                  <Clickable className={styles.rangePickerBtn}>
                    {dateRange} <Icon slug="angle_down" color="#F1B729" />
                  </Clickable>
                </Dropdown>
              </div>
              <div className={styles.rangeBlock}>
                {dateRange === 'Сегодня' && dayjs().format('DD / MM / YYYY')}
                {dateRange === 'Вчера' && dayjs().subtract(1, 'day').format('DD / MM / YYYY')}
                {dateRange === 'Неделя' && (
                  <>
                    {dayjs().subtract(6, 'day').format('DD / MM / YYYY')}
                    <span>—</span>
                    {dayjs().format('DD / MM / YYYY')}
                  </>
                )}
                {dateRange === 'Месяц' && (
                  <>
                    {dayjs().subtract(30, 'day').format('DD / MM / YYYY')}
                    <span>—</span>
                    {dayjs().format('DD / MM / YYYY')}
                  </>
                )}
                {dateRange === 'Выбрать свой' && (
                  <RangePicker
                    value={customDateRange}
                    onChange={(dates) => handleCustomDateRange(dates)}
                  />
                )}
              </div>
            </Flex>
          </Flex>

          <Flex vertical gap={0} className="mt-24">
            <div
              onClick={() => setVisibleCartStatic(true)}
              className={visibleCartStatic ? styles.card + ' ' + styles.active : styles.card}
            >
              <p>
                <Icon slug="cake" />
                Выполнено (шт.): <span>{StagesStore.dataUserStatistic.length}</span>
              </p>
            </div>
            <div className={styles.card}>
              <p>
                <Icon slug="clock" />
                Среднее время (мин.): <UserDuration />
              </p>
            </div>
          </Flex>
        </div>

        <div className={styles.thid_bl}>
          <Table
            className={styles.table}
            columns={columns2}
            pagination={false}
            dataSource={StagesStore.dataUserStatistic}
            loading={loading}
            showSorterTooltip={{ target: 'sorter-icon' }}
            scroll={{ y: 480 }}
            rowKey={(record) => `${record.jobId}`}
            onRow={(record) => ({
              onClick: () => handleRowClick(record.jobId ? record.jobId : ''),
            })}
          />
          <Modal title="Детали" open={isModalVisible} onCancel={handleModalClose} footer={null}>
            {selectedRowData && (
              <>
                <p>
                  <b>jobID:</b> {selectedJobData.jobId}
                </p>
                <p>
                  <b>bitrixId:</b> {selectedJobData.externalId?.bitrixId}
                </p>
                <p>
                  <b>Продолжительность: </b>
                  {selectedJobData.duration
                    ? Math.round((selectedJobData.duration / 60) * 100) / 100
                    : 'Данные отсутствуют'}{' '}
                  мин
                </p>

                <p>
                  <b>Создано: </b>
                  {dayjs(selectedJobData.createdAt).format('DD/MM/YYYY HH:mm')}
                </p>
                {selectedJobData.jobData?.[0]?.text && (
                  <p>
                    <b>Описание: </b>
                    {selectedJobData.jobData[0].text.split('\\n').map((line: any, i: any) => (
                      <Fragment key={i}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                  </p>
                )}
                {selectedJobData.jobData?.[0]?.bitrixOrderData && (
                  <p className={styles.text_jobdata}>
                    {selectedJobData.jobData[0].bitrixOrderData
                      .replace(/\[\/b\]/g, '')
                      .replace(/\[b\]/g, '')
                      .split('_')
                      .map((line: any, i: any) => (
                        <Fragment key={i}>
                          {line}
                          <br />
                        </Fragment>
                      ))}
                  </p>
                )}

                <br></br>

                <div className={styles.photosWrap}>
                  {selectedJobData.jobData?.[0]?.photoFiles?.map((url: any) => (
                    <Image
                      key={url}
                      className={styles.photo}
                      height={200}
                      src={`${config.EXTERNAL_ADDRESS}/public/${url}`}
                    />
                  ))}
                </div>
              </>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default observer(MobileStat);
