import LayoutStore, { IActiveModal } from '../../DataSource/Stores/LayoutStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { WorkspaceCreateModal } from '../../modals';
import WorkspaceSettingsModal from '../../modals/WorkspaceSettings';
import { $class } from '../../utils';
import './index.scss';
import WorkspaceAddMember from '../../modals/WorkspaceAddMember';

export interface ModalProps<T = any> {
  closeModal: () => void;
  data: T;
}

interface IModal {
  component: FC<ModalProps>;
}

interface IModalMap {
  [key: string]: IModal;
}

const modals: IModalMap = {
  workspaceCreate: {
    component: WorkspaceCreateModal,
  },
  workspaceSettings: {
    component: WorkspaceSettingsModal,
  },
  workspaceAddMember: {
    component: WorkspaceAddMember,
  },
};

const Modals = () => {
  const activeModals = LayoutStore.activeModals;
  const activeOverlay = activeModals.length > 0;

  const ModalContent = (modal: IActiveModal) => {
    if (modal.key in modals) {
      const Base: FC<ModalProps> = modals[modal.key].component;

      return (
        <Base
          data={modal.data}
          closeModal={() => LayoutStore.closeModal(modal.key)}
          key={modal.key}
        />
      );
    }
    return null;
  };

  return (
    <div
      className={$class('modal-wrapper', ['active', activeOverlay])}
      style={{ pointerEvents: activeOverlay ? 'all' : 'none' }}
    >
      {activeModals.map((modal) => (
        <div key={modal.key} className={$class('modal', modal.open && 'modal--open')}>
          {ModalContent(modal)}
        </div>
      ))}

      <div
        onClick={() => LayoutStore.closeLastModal()}
        className={$class('modal-shadow', ['active', activeOverlay])}
      ></div>
    </div>
  );
};

export default observer(Modals);
