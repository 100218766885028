import { KeyOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography, message, type FormProps } from 'antd';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { AuthStore } from '../../../DataSource/Stores/AuthStore';
import * as styles from '../Auth.module.scss';

type FieldType = {
  email?: string;
  firstname?: string;
};

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
  // setActive(true);
};

const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
  console.log('Success:', values);

  if (values.email) {
    try {
      await AuthStore.sendRegistrationLinkByEmail(values.email);
      AuthStore.signUpSuccess = true;
    } catch (error: any) {
      if (error?.message == 'Request failed with status code 409') {
        message.error('User with this email already exists');
      } else {
        console.error('signUp exception:error?.status=', error?.message);
        AuthStore.signUpError = true;
      }
    }
  }
};

function SignUp() {
  const { Title, Text } = Typography;
  return (
    <div className={styles.fon_wrp}>
      <div>
        <Title level={2}>Создайте аккаунт</Title>
        <p className={styles.text_add}>
          Уже есть аккаунт? <Link to="/login">Войти</Link>
        </p>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item<FieldType>
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input prefix={<MailOutlined />} placeholder="Почта" />
          </Form.Item>
          <Form.Item<FieldType>
            name="firstname"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Имя" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }]}
          >
            <Input.Password prefix={<KeyOutlined />} placeholder="Пароль" />
          </Form.Item>
          <Form.Item>
            <Button className={styles.button_prime} type="primary" htmlType="submit">
              Создать
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default observer(SignUp);
