// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k5rxhHqufc0wTH_iRBgY{display:flex;margin:8px 16px;align-items:end;justify-content:space-between;grid-column-start:2}.k5rxhHqufc0wTH_iRBgY .AQj0qjGusBKgpXIQViBm{width:112px}.k5rxhHqufc0wTH_iRBgY .v9rUcx8udWH5HAE_b5Pv{border-radius:8px;color:#2a3038;font-size:20px;display:flex;align-items:center;gap:16px}.k5rxhHqufc0wTH_iRBgY .ecEVcaL3MsHaXwSHF5sO{display:flex;align-items:center;column-gap:8px;padding:12px;border-radius:12px;background-color:#e2e5e9}.k5rxhHqufc0wTH_iRBgY .ecEVcaL3MsHaXwSHF5sO p{font-size:15px;font-weight:500}.zYo0lQs1phCc5QbhLzpR{position:relative}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA,sBAME,YAAA,CACA,eAAA,CACA,eAAA,CACA,6BAAA,CACA,mBAAA,CAEA,4CACE,WAAA,CAGF,4CACE,iBAAA,CACA,aAAA,CACA,cAAA,CAEA,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,cAAA,CAEA,YAAA,CACA,kBAAA,CACA,wBAAA,CAEA,8CACE,cAAA,CACA,eAAA,CAIN,sBACE,iBAAA","sourcesContent":[".header{display:flex;margin:8px 16px;align-items:end;justify-content:space-between;grid-column-start:2}.header .logo{width:112px}.header .dropdownBtn{border-radius:8px;color:#2a3038;font-size:20px;display:flex;align-items:center;gap:16px}.header .userCard{display:flex;align-items:center;column-gap:8px;padding:12px;border-radius:12px;background-color:#e2e5e9}.header .userCard p{font-size:15px;font-weight:500}.flex_bl{position:relative}"],"sourceRoot":""}]);
// Exports
export var header = `k5rxhHqufc0wTH_iRBgY`;
export var logo = `AQj0qjGusBKgpXIQViBm`;
export var dropdownBtn = `v9rUcx8udWH5HAE_b5Pv`;
export var userCard = `ecEVcaL3MsHaXwSHF5sO`;
export var flex_bl = `zYo0lQs1phCc5QbhLzpR`;
export default ___CSS_LOADER_EXPORT___;
