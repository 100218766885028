import { Button, Checkbox, Form, FormProps, Input, Modal, Select, message } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { basicRequestGet } from '../../../../../../DataSource/Requests/basicRequestGet';
import { WorkspacesStore } from '../../../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import { ManagerStore } from '../../../../../../DataSource/Stores/ManagerStore';
import { DeliveryZonesStore } from '../../../../../../DataSource/Stores/Site/DeliveryZonesStore';
import StatusTitle from '../../../StatusTitle';
import CourierItemProgress from '../../CourierItemProgress';
import * as styles from './index.module.scss';

type FieldType = {
  geoJson: string;
  name?: string;
  status?: string;
  couriers?: any;
};

interface GeoJson {}

export interface DeliveryZone {
  _id: string;
  name: string;
  workspaceId: string;
  geoJson: GeoJson;
  area: number;
  couriers: [];
  status: string;
}

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};
interface ModalProfileProps {
  // forceUpdate: () => void;
}

type WorkspaceIdParams = {
  id?: string;
};

const Couriers: React.FC<ModalProfileProps> = ({}) => {
  const [data, setData] = useState<DeliveryZone>();
  const { id } = useParams<WorkspaceIdParams>();
  const [form] = Form.useForm();
  const [selectedIds, setSelectedIds] = useState([]);

  const handleChangeChesk = (checkedValues: any) => {
    // Отслеживайте значения чекбоксов
    setSelectedIds(checkedValues);

    console.log('Отмеченные элементы:', checkedValues);
  };

  const handleOk = () => {
    ManagerStore.isModalOpenZone = false;
    DeliveryZonesStore.zoneId = '';
    form.resetFields();
    setSelectedIds([]);
    setData(undefined);
  };
  const handleCancel = () => {
    ManagerStore.isModalOpenZone = false;
    DeliveryZonesStore.zoneId = '';
    form.resetFields();
    setSelectedIds([]);
    setData(undefined);
  };
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    if (!id) return;

    // Если нет данных, значит мы создаем новую зону
    if (!data) {
      const name = values.name || '';
      let geo;

      try {
        geo = JSON.parse(values.geoJson); // Пробуем парсить geoJson
      } catch (err) {
        console.error(err);
        return message.error('некорректные гео данные');
      }

      const area = 0; // Необходимо определить, как вы будете рассчитывать area
      const status = values.status || '';
      const couriers = selectedIds; // Используем выбранные курьеры
      await DeliveryZonesStore.addZone(id, name, geo, area, couriers, status);

      if (DeliveryZonesStore.zoneError) {
        message.error('Add Error');
      } else {
        message.info('Success');
        ManagerStore.isModalOpenZone = false;
      }

      return;
    }

    // В этом блоке происходит обновление существующей зоны
    const name = values.name || ''; // Получаем имя из формы
    let geoJson;
    try {
      geoJson = JSON.parse(values.geoJson); // Пробуем парсить geoJson
    } catch (err) {
      console.error(err);
      return message.error('некорректные гео данные');
    }

    const area = 0; // Обновите, если нужно
    const couriers = selectedIds; // Используем выбранные курьеры
    const status = values.status || '';

    // Вызов метода обновления
    const res = await DeliveryZonesStore.updateZone(
      id, // workspaceId (это может быть ваш id)
      DeliveryZonesStore.zoneId,
      name,
      geoJson,
      area,
      couriers,
      status
    );

    if (res) {
      setData(res.data); // Обновляем локальные данные
      message.success('Обновлено');
      ManagerStore.isModalOpenZone = false;
    } else {
      message.error('Ошибка');
    }
  };

  // const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
  //   console.log('Success:', values);
  //   if (!id) return;
  //   const name = values.name || '';
  //   let geo;
  //   try {
  //     geo = JSON.parse(values.geoJson);
  //   } catch (err) {
  //     console.error(err);
  //     return message.error('некорректные гео данные');
  //   }
  //   const area = 0;
  //   const stat = values.status || '';
  //   const cour = selectedIds;
  //   await DeliveryZonesStore.addZone(id, name, geo, area, cour, stat);
  //   if (DeliveryZonesStore.zoneError) {
  //     message.error('Add Error');
  //   } else {
  //     message.info('Success');
  //     ManagerStore.isModalOpenZone = false;
  //   }
  // };
  useEffect(() => {
    console.log('DeliveryZonesStore.zoneId: ', DeliveryZonesStore.zoneId);

    if (DeliveryZonesStore.zoneId) {
      (async () => {
        try {
          const url = `/workspaces/${id}/delivery-zones/${DeliveryZonesStore.zoneId}`;
          const zoneData = await basicRequestGet(url);
          if (zoneData.data) {
            console.log('zoneData.data: ', zoneData.data);
            setData(zoneData.data);
            form.setFieldsValue({
              geoJson: JSON.stringify(zoneData.data.geoJson), // Преобразуем в строку
              name: zoneData.data.name,
              status: zoneData.data.status,
              couriers: zoneData.data.couriers,
            });
          } else {
            console.error('Данные зоны не найдены');
            form.resetFields();
          }
        } catch (error) {
          console.error('Ошибка при получении данных зоны:', error);
        }
      })();
    } else {
      form.resetFields();
    }
  }, [DeliveryZonesStore.zoneId, id]);

  return (
    <Modal
      title={'зона доставки'}
      className={styles.modal}
      open={ManagerStore.isModalOpenZone}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <div className={styles.moda_cont}>
        <div
        //className={styles.child_flex}
        >
          <StatusTitle title="активен" theme="active" />
          <Form
            name="basic"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={{ status: 'active' }}
          >
            <Form.Item<FieldType>
              name="geoJson"
              // rules={[{ required: true, message: 'Введите geoJSON' }]}
            >
              <Input placeholder="geoJSON" />
            </Form.Item>
            <div className={styles.one_block}>
              <p className={styles.one_tit}>Параметры</p>
              <div className={styles.flex_input}>
                <Form.Item<FieldType>
                  label="Название"
                  name="name"
                  rules={[{ required: true, message: 'Введите название' }]}
                >
                  <Input placeholder="Алатауский район" />
                </Form.Item>
                <Form.Item<FieldType>
                  label="Статус"
                  name="status"
                  rules={[{ required: true, message: 'Выберите статус' }]}
                >
                  <Select
                    onChange={handleChange}
                    options={[
                      { value: 'active', label: 'Активен' },
                      { value: 'inactive', label: 'Не активен' },
                    ]}
                  />
                </Form.Item>
              </div>
            </div>

            <div className={styles.one_block}>
              <div className={styles.cour_zon}>
                <p>Курьеры</p>
                <Form.Item<FieldType> name="couriers" style={{ border: 0 }}>
                  <Checkbox.Group className={styles.checkboxGroup} onChange={handleChangeChesk}>
                    {Object.values(WorkspacesStore.members).map((member, i) => (
                      <Checkbox key={i} value={member.userId._id}>
                        <CourierItemProgress
                          title={member.userId.firstname}
                          photo={member.userId.photo}
                          percent={10}
                        />
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </div>

            <div className={styles.bottom}>
              <Button type="primary" className={styles.button} htmlType="submit">
                Сохранить
              </Button>
              <Button className={styles.button} onClick={handleCancel}>
                Отменить
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
export default observer(Couriers);
