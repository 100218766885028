import { action, makeAutoObservable, observable } from 'mobx';
import { basicRequestDelete } from '../../Requests/basicRequestDelete';
import { basicRequestGet } from '../../Requests/basicRequestGet';
import { basicRequestPost } from '../../Requests/basicRequestPost';
import { basicRequestPut } from '../../Requests/basicRequestPut';

interface DeliveryZoneRequest {
  name: string;
  workspaceId: string;
  geoJson: any;
  area: string;
  couriers: string[];
  status: string;
}

export class DeliveryZonesStoreActionsClass {
  @observable loadingError: boolean = false;

  @observable loading: boolean = false;

  @observable loadingDelete: boolean = false;
  @observable dataList: [] = [];
  @observable zoneId: string = '';
  @observable zoneError: boolean = false;
  @observable zoneLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getList(workspaceId: string) {
    console.log('---- ww|DeliveryZonesStore|getList');
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.loadingError = false;
    this.dataList = [];

    try {
      const res = await basicRequestGet(`/workspaces/${workspaceId}/delivery-zones`);
      console.log('---- ww|getList|res.data=', res.data);

      this.dataList = res.data;
      console.log('delivery-zones: ', this.dataList);
    } catch (error) {
      // throw error;
      console.error('getList|error=', error);
      this.loadingError = true;
    } finally {
      this.loading = false;
    }
  }

  @action
  async setZoneId(zoneId: string) {
    this.zoneId = zoneId;
  }

  // @action
  // async addZone(workspaceId: string, zone: DeliveryZoneRequest) {
  //   try {
  //     const res = await basicRequestPost(`/workspaces/${workspaceId}/delivery-zones`, zone);
  //     return res;
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  @action
  async addZone(
    workspaceId: string,
    name: string,
    geoJson: any,
    area: number,
    couriers: string[],
    status: string
  ) {
    this.zoneLoading = true;
    this.zoneError = false;
    try {
      const res = await basicRequestPost(`/workspaces/${workspaceId}/delivery-zones`, {
        workspaceId,
        name,
        geoJson,
        area,
        couriers,
        status,
      });
    } catch (error) {
      console.error('addZoning|error=', error);
      this.zoneError = true;
    } finally {
      this.zoneLoading = false;
    }
  }

  @action
  async updateZone(
    workspaceId: string,
    zoneId: string,
    name: string,
    geoJson: any,
    area: number,
    couriers: string[],
    status: string
  ) {
    try {
      const res = await basicRequestPut(`/workspaces/${workspaceId}/delivery-zones/${zoneId}`, {
        workspaceId,
        name,
        geoJson,
        area,
        couriers,
        status,
      });
      return res;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  async deleteZone(workspaceId: string, zoneId: string) {
    try {
      const res = await basicRequestDelete(`/workspaces/${workspaceId}/delivery-zones/${zoneId}`);
      return res;
    } catch (e) {
      console.error(e);
    }
  }
}
