import { observer } from 'mobx-react';
import * as styles from './index.module.scss';

export type StatusVariant = 'work' | 'active' | 'create' | 'indelivery';
interface Props {
  title: string;
  theme?: StatusVariant;
}

const StatusTitle = ({ title, theme }: Props) => {
  let themeClass = styles.theme;

  if (theme) {
    themeClass = `${styles.theme} ${styles[theme]}`;
  }

  return (
    <div className={`${styles.text_block} ${themeClass}`}>
      <p>{title}</p>
    </div>
  );
};
export default observer(StatusTitle);
