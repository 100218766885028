import { observer } from 'mobx-react';
import { FC } from 'react';
import { JobsDataType } from '../../../../../ types';
import * as styles from './index.module.scss';

const Labels: FC<JobsDataType> = ({ labels, _id }) => {
  return (
    <>
      <div className={styles.flex_labels}>
        {_id ? _id : ''}
        {labels && labels.length > 0
          ? labels.map((label, index) => (
              <p key={index} className={styles[label.style]}>
                {label.caption}
              </p>
            ))
          : null}
      </div>
    </>
  );
};

export default observer(Labels);
