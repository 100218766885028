import dayjs from 'dayjs';
import { FC, Fragment } from 'react';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import * as styles from './index.module.scss';

const SystemTasksBlock: FC = () => {
  return (
    <div className={styles.fieldsWrap}>
      <p className={styles.title}>СИСТЕМНЫЕ ЗАДАЧИ</p>
      {StagesStore.dataSystemTask.map((task) => (
        <Fragment key={task._id}>
          <div className={styles.field}>
            <p className={styles.name}>
              #{task._id.slice(-4)}: {task.status}
            </p>
            <div className={styles.copyBlock}>
              {dayjs(task.updatedAt).format('DD / MM / YYYY, HH:mm:ss')}
            </div>
          </div>
          {/*<div className={styles.field}>*/}
          {/*  <p className={styles.name}>UpdatedAt</p>*/}
          {/*  <DatePicker*/}
          {/*    showTime*/}
          {/*    allowClear={false}*/}
          {/*    suffixIcon={null}*/}
          {/*    variant="filled"*/}
          {/*    format="DD / MM / YYYY, HH:mm"*/}
          {/*    style={{ backgroundColor: '#F3F5F6E2', borderRadius: '8px', padding: '4px 8px' }}*/}
          {/*  />*/}
          {/*</div>*/}
        </Fragment>
      ))}
    </div>
  );
};

export default SystemTasksBlock;
