import { Image } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { Comment } from '../../../../ types';
import { formatDate } from '../../../../../DataSource/Utils/date';
import { config } from '../../../../../config';
import * as styles from './index.module.scss';

const CommentBlock: FC<Comment> = ({ targetDate, createdAt, text, fileUrls, author }) => {
  return (
    <div className={styles.comment}>
      <div className={styles.dateBlock}>
        {dayjs(createdAt).format('HH : mm')}
        <span className={styles.separator}>•</span>
        {dayjs(createdAt).format('MM / DD / YYYY')}
      </div>
      <p className={styles.text}>{text}</p>
      <div className={styles.dates}>
        {targetDate && (
          <p>
            <span>Дата забора:</span> <span>{formatDate(targetDate)}</span>
          </p>
        )}
      </div>
      <div className={styles.photosWrap + ' photo'}>
        {fileUrls.map((url, i) => (
          <Image src={`${config.EXTERNAL_ADDRESS}/public/${url}`} />
          // <Button
          //   key={i}
          //   variant="text"
          //   icon="arrow_vertical"
          //   autoWidth
          //   onClick={() => window.open(`${config.EXTERNAL_ADDRESS}/public/${url}`, '_blank')}
          // >
          //   Фото
          // </Button>
        ))}
      </div>
      <p className={styles.author}>Автор: {author}</p>
    </div>
  );
};

export default CommentBlock;
