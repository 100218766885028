import { ConfigProvider } from 'antd';
import { useEffect } from 'react';
import * as styles from '../Workspaces/index.module.scss';
import NewJobsBlock from './NewJobsBlock';

interface IProps {
  workspaceId: string;
  name: string;
}

const DistributionCenter = ({ workspaceId, name }: IProps) => {
  useEffect(() => {
    document.title = 'Пчелка';

    return () => {
      document.title = 'Пчелка';
    };
  }, []);

  return (
    <div className={styles.distributionCenterPage} id="dist-tasks-scroll">
      <div className={styles.distributionCenterPageTop}>
        <div className={styles.title}>{name}</div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Inter',
          },
        }}
      >
        <NewJobsBlock workspaceId={workspaceId} />
      </ConfigProvider>
    </div>
  );
};

export default DistributionCenter;
