import { configure as configureMobx } from 'mobx';
import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { AuthStore } from './DataSource/Stores/AuthStore';
import { HeaderStore } from './DataSource/Stores/HeaderStore';
import App from './components/App/App';
import { config } from './config';
import { Modals } from './layout/index';
import './scss/index.scss';
import uWS from './uWS';

configureMobx({
  // enforceActions: 'observed',
  enforceActions: 'never',
});

const mobxStores = {
  AuthStore,
  HeaderStore,
};

// загрузка информации о версии приложения после конфига
config.load().then(() => {
  uWS.init();

  const root = ReactDOM.createRoot(document.getElementById('root') as Element);
  root.render(
    <Provider {...mobxStores}>
      <React.StrictMode>
        <Modals />
        <App />
        <Toaster />
      </React.StrictMode>
    </Provider>
  );
});
