import { MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography, message, type FormProps } from 'antd';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import * as styles from '../Auth.module.scss';
// import { Link } from 'react-router-dom';
import { AuthStore } from '../../../DataSource/Stores/AuthStore';
import Lang from '../../Lang';

type FieldType = {
  email?: string;
};

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
  // setActive(true);
};

const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
  console.log('Success:', values);

  if (values.email) {
    try {
      await AuthStore.sendRegistrationLinkByEmail(values.email);
      AuthStore.signUpSuccess = true;
      message.success('Вам отправлено сообщение на ваш email');
    } catch (error: any) {
      if (error?.message == 'Request failed with status code 409') {
        message.error('User with this email already exists');
      } else {
        console.error('signUp exception:error?.status=', error?.message);
        AuthStore.signUpError = true;
      }
    }
  }
};

function SignUp() {
  const { Title, Text } = Typography;
  return (
    <div className={styles.fon_wrp}>
      <div>
        <Title level={2}>{Lang({ str: 'reg', section: 'default' })}</Title>
        <p className={styles.text_add}>
          Есть аккаунт? <Link to="/login">Войти</Link>
        </p>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item<FieldType>
            name="email"
            rules={[{ required: true, message: 'Please enter email!' }]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button className={styles.button_prime} type="primary" htmlType="submit">
              {Lang({ str: 'add', section: 'default' })}
            </Button>
          </Form.Item>
          {/*<p className={styles.link}>*/}
          {/*  Already have an account?<Link to="/login">Sign in</Link>*/}
          {/*</p>*/}
        </Form>
      </div>
    </div>
  );
}

export default observer(SignUp);
