import { Drawer, Dropdown, Flex } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { FC, useEffect, useRef, useState } from 'react';
import { Condition2, StageParam, StagesDataType } from '../../ types';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import Button from '../../ui/Button';
import IconButton from '../../ui/IconButton';
import Input from '../../ui/Input';
import RuleBlock from './RuleBlock';
import * as styles from './index.module.scss';

interface IProps extends StagesDataType {
  isOpen: boolean;
  onClose: () => void;
}

const StageDrawer: FC<IProps> = ({
  isOpen,
  onClose,
  _id,
  name,
  description,
  workspaceId,
  status,
  conditions,
  taskTypesAndParams,
  isStarting,
  isFinal,
  createdAt,
  updatedAt,
  __v,
}) => {
  // const [stage, setStage] = useState<StagesDataType>({
  //   _id,
  //   name,
  //   description,
  //   workspaceId,
  //   status,
  //   conditions,
  //   taskTypesAndParams,
  //   isStarting,
  //   isFinal,
  //   createdAt,
  //   updatedAt,
  //   __v,
  // });
  const [stageConditions, setStageConditions] = useState(conditions);
  const [stageParams, setStageParams] = useState<StageParam[]>([]);
  const [changesMade, setChangesMade] = useState(false);
  const initialState = useRef(stageConditions);

  useEffect(() => {
    (async () => {
      const res = await StagesStore.getStageParams(workspaceId, _id);
      if (res) setStageParams(res.data);
    })();
  }, []);

  useEffect(() => {
    setChangesMade(JSON.stringify(initialState.current) !== JSON.stringify(stageConditions));
  }, [stageConditions]);

  const clearChanges = () => {
    setStageConditions(initialState.current);
  };

  const addRule = () => {
    setStageConditions((prev) => [
      ...prev,
      {
        name: '',
        status: 'active',
        toStageId: '',
        conditions: [[{ paramId: '', sign: '', paramValue: '' }]],
      },
    ]);
  };

  const removeRule = (ruleIndex: number) => {
    setStageConditions((prev) => prev.filter((_, i) => i !== ruleIndex));
  };

  const addParam = (ruleIndex: number) => {
    setStageConditions((prev) =>
      prev.map((item, i) => {
        if (i === ruleIndex) {
          return {
            ...item,
            conditions: [[...item.conditions[0], { paramId: '', sign: '', paramValue: '' }]],
          };
        }
        return item;
      })
    );
  };

  const removeParam = (ruleIndex: number, paramIndex: number) => {
    setStageConditions((prev) =>
      prev.map((item, i) => {
        if (i === ruleIndex) {
          return {
            ...item,
            conditions: [[...item.conditions[0].filter((_, i) => i !== paramIndex)]],
          };
        }
        return item;
      })
    );
  };

  const setParam = (ruleIndex: number, paramIndex: number, param: Condition2) => {
    setStageConditions((prev) =>
      prev.map((item, i) => {
        if (i === ruleIndex) {
          return {
            ...item,
            conditions: [
              item.conditions[0].map((condition, i) => {
                if (i === paramIndex) {
                  return param;
                }
                return condition;
              }),
            ],
          };
        }
        return item;
      })
    );
  };

  const setRuleName = (ruleIndex: number, value: string) => {
    setStageConditions((prev) =>
      prev.map((item, i) => (i === ruleIndex ? { ...item, name: value } : item))
    );
  };

  const setRuleStatus = (ruleIndex: number, value: string) => {
    setStageConditions((prev) =>
      prev.map((item, i) => (i === ruleIndex ? { ...item, status: value } : item))
    );
  };

  const onSave = async () => {
    const res = await StagesStore.updateStageRules(workspaceId, _id, stageConditions);
    if (res) setStageConditions(res.data.conditions);
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} width={540}>
        <div className={styles.stageDrawer}>
          <div className={styles.stageDrawerTop}>
            <p className={styles.title}>{name}</p>
            <Dropdown
              menu={{
                items: [
                  {
                    key: '1',
                    label: 'Создать правило',
                    onClick: addRule,
                  },
                ],
              }}
              trigger={['click']}
            >
              <IconButton icon="three_dots" variant="secondary" />
            </Dropdown>
          </div>

          {changesMade && (
            <Flex align="center" gap={16} style={{ marginBottom: '32px' }}>
              <Button onClick={onSave}>Сохранить</Button>
              <Button className={styles.cancelBtn} onClick={clearChanges}>
                Отменить
              </Button>
            </Flex>
          )}

          <div className={styles.fieldsWrap}>
            <div className={styles.field}>
              <p className={styles.name}>Наименование этапа</p>
              <Input style={{ padding: '4px 8px' }} value={name} disabled />
            </div>
            <div className={styles.field}>
              <p className={styles.name}>Обновлено</p>
              <div className={styles.dateBlock}>
                {dayjs(updatedAt).format('DD / MM / YYYY, HH:mm')}
              </div>
            </div>
          </div>

          {stageConditions.map((condition, i) => (
            <RuleBlock
              key={i}
              index={i}
              stageParams={stageParams}
              removeRule={removeRule}
              addParam={addParam}
              removeParam={removeParam}
              setParam={setParam}
              setRuleName={setRuleName}
              setRuleStatus={setRuleStatus}
              {...condition}
            />
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default observer(StageDrawer);
