import { animated, useSpring } from '@react-spring/web';
import { Result, Spin } from 'antd';
import { observer } from 'mobx-react';
import { FC, useRef } from 'react';
import { JobsDataType } from '../../ types';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import Icon from '../../ui/Icon';
import * as styles from '../DistributionCenter/User/index.module.scss';
import JobItem from './JobItem';
interface IProps {
  workspaceId: string;
}
const JobTab: FC<IProps> = ({ workspaceId }) => {
  // const [showText, setShowText] = useState(false);
  const wrapOrdRef = useRef<HTMLDivElement>(null);
  // const widthnew: any = wrapOrdRef?.current?.clientWidth;
  const widthnew = wrapOrdRef.current ? wrapOrdRef.current.clientWidth : 0;

  const jobClick = (job: JobsDataType) => {
    StagesStore.setShowText(true);
    StagesStore.setLayoutPageJob('jobeditor');
    StagesStore.setSelectedJob(job);
    StagesStore.setIsSelectedJob(true);
  };

  // console.log('dgdfg', StagesStore.layoutPageJob);

  const onBackJob = () => {
    StagesStore.setShowText(false);
    StagesStore.setLayoutPageJob('joblay');
    StagesStore.setIsSelectedJob(false);
    StagesStore.setSelectedJob(null);
  };

  const { x } = useSpring({
    x: StagesStore.layoutPageJob === 'joblay' ? 0 : -widthnew,
  });

  // const workspaceId = '66e170b52fc0a03d58789c24';
  // useEffect(() => {
  //   StagesStore.getListJobsQueue(workspaceId, '66e194841725b77d117c4d57').catch((e) => {
  //     console.error(e);
  //   });
  // }, []);

  // useEffect(() => {
  //   if (!workspaceId) return;
  //   const eventStatus = uWS.subscribe(`workspaces/${workspaceId}/changeJob`, (data: any) => {
  //     if (data.error) {
  //       console.error('Error subscribing to workspace changeJob:', data.error);
  //       return;
  //     }
  //
  //     console.log('changeJob:', data);
  //     StagesStore.getListJobsQueue(workspaceId, '66e194841725b77d117c4d57').catch((e) => {
  //       console.error(e);
  //     });
  //
  //     if (data.jobId && StagesStore.selectedJob && data.jobId === StagesStore.selectedJob._id) {
  //       onBackJob();
  //     }
  //   });
  //
  //   return () => {
  //     if (eventStatus) uWS.unSubscribe(`workspaces/${workspaceId}/changeJob`);
  //   };
  // }, []);

  const textAnimation = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: StagesStore.showText ? 1 : 0,
    },
    delay: 500, // Задержка перед появлением текста
    config: { duration: 400 }, // Длительность анимации
  });

  return (
    <div className={styles.prompt_wrap} ref={wrapOrdRef}>
      <div className={styles.task_flex}>
        {StagesStore.layoutPageJob === 'joblay' ? (
          <div className={styles.task_child_flex}>
            {StagesStore.loadingQueueError && 'Error loading'}
            {StagesStore.loadingQueue && StagesStore.dataQueueList?.length === 0 ? (
              <Spin size="small" />
            ) : StagesStore.dataQueueList?.length === 0 ? (
              <div className={styles.no_tasks}>
                <Result status="warning" title="Новых заявок пока нет" />
              </div>
            ) : (
              StagesStore.dataQueueList?.map((task_i) => (
                <JobItem
                  key={task_i._id}
                  _id={task_i._id}
                  name={task_i.name}
                  jobId={task_i.jobId}
                  onClick={jobClick}
                  stageId={task_i.stageId}
                  workspaceId={task_i.workspaceId}
                  bitrixId2={task_i.externalId?.bitrixId}
                  jiraId2={task_i.externalId?.jiraId}
                  jobData={task_i.jobData}
                  targetDate={task_i.targetDate}
                  comments={task_i.comments}
                  isSelectedJob={
                    StagesStore.isJobSelected && StagesStore.selectedJob?._id === task_i._id
                  }
                />
              ))
            )}
          </div>
        ) : (
          <div className={styles.task_child_flex}>
            <div>
              <div className={styles.flex_block}>
                <animated.p className={styles.back} onClick={onBackJob} style={textAnimation}>
                  <Icon slug="arrow_back" /> Все "Новые"
                </animated.p>
                {/* <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: <p>текст</p>,
                      },
                      {
                        key: '2',
                        danger: true,
                        label: <p>текст</p>,
                      },
                    ],
                  }}
                >
                  <a className="button_add" onClick={(e) => e.preventDefault()}>
                    <Icon slug="icons_menu_point" />
                  </a>
                </Dropdown> */}
              </div>

              {StagesStore.selectedJob && StagesStore.layoutPageJob == 'jobeditor' && (
                <JobItem
                  key={StagesStore.selectedJob._id}
                  targetDate={StagesStore.selectedJob.targetDate}
                  _id={StagesStore.selectedJob._id}
                  name={StagesStore.selectedJob.name}
                  jobId={StagesStore.selectedJob.jobId}
                  workspaceId={StagesStore.selectedJob.workspaceId}
                  bitrixId2={StagesStore.selectedJob.bitrixId2}
                  jiraId2={StagesStore.selectedJob.jiraId2}
                  jobData={StagesStore.selectedJob.jobData}
                  comments={StagesStore.selectedJob.comments}
                  onClick={() => {}}
                  isSelectedJob={StagesStore.isJobSelected}
                  stageId={''}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(JobTab);
