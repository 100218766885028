// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TgJ3QaI82vQmNTYeh2fK{width:fit-content;background-color:#fff;border:1px solid #e0e0e0;border-radius:8px;display:flex;flex-direction:column;gap:12px;padding:12px}.TgJ3QaI82vQmNTYeh2fK .xrAcx3lbX0Rrlmhz9m3i{height:42px;width:42px;background-color:#f1f2f4;border:none;border-radius:8px}.TgJ3QaI82vQmNTYeh2fK .xrAcx3lbX0Rrlmhz9m3i.rg6KxEf_kDp912vSl1tQ{background-color:#f1b729}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DistributionCenter/Nav/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,qBAAA,CACA,wBAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,YAAA,CAEA,4CACE,WAAA,CACA,UAAA,CACA,wBAAA,CACA,WAAA,CACA,iBAAA,CAEA,iEACE,wBAAA","sourcesContent":[".nav{width:fit-content;background-color:#fff;border:1px solid #e0e0e0;border-radius:8px;display:flex;flex-direction:column;gap:12px;padding:12px}.nav .navBtn{height:42px;width:42px;background-color:#f1f2f4;border:none;border-radius:8px}.nav .navBtn.active{background-color:#f1b729}"],"sourceRoot":""}]);
// Exports
export var nav = `TgJ3QaI82vQmNTYeh2fK`;
export var navBtn = `xrAcx3lbX0Rrlmhz9m3i`;
export var active = `rg6KxEf_kDp912vSl1tQ`;
export default ___CSS_LOADER_EXPORT___;
