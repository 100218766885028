import dayjs from 'dayjs';
import { FC, Fragment } from 'react';
import { Comment } from '../../../../../ types';
import * as styles from './index.module.scss';

const CommentBlock: FC<Comment> = ({ createdAt, text, fileUrls, author }) => {
  return (
    <div className={styles.comment}>
      <div className={styles.dateBlock}>
        {dayjs(createdAt).format('HH : mm')}
        <span>•</span>
        {dayjs(createdAt).format('MM / DD / YYYY')}
      </div>
      <p className={styles.text}>
        {text.split('\n').map((line, i) => (
          <Fragment key={i}>
            {line}
            <br />
          </Fragment>
        ))}
      </p>
      {/*<div className={styles.photosWrap}>*/}
      {/*  {fileUrls.map((url, i) => (*/}
      {/*    <Button*/}
      {/*      key={i}*/}
      {/*      variant="delivery_text"*/}
      {/*      icon="arrow_vertical"*/}
      {/*      autoWidth*/}
      {/*      onClick={() => window.open(`${config.EXTERNAL_ADDRESS}/public/${url}`, '_blank')}*/}
      {/*    >*/}
      {/*      Фото*/}
      {/*    </Button>*/}
      {/*  ))}*/}
      {/*</div>*/}
      <p className={styles.author}>Автор: {author}</p>
    </div>
  );
};

export default CommentBlock;
