import { ConfigProvider, Layout } from 'antd';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';
import { HeaderStore } from '../DataSource/Stores/HeaderStore';

function CabinetLayout() {
  const onClose = () => {
    HeaderStore.setOpenRight(false);
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 990px)' });
  document.body.style.overflow = 'hidden';

  return (
    <ConfigProvider>
      <Layout style={{ overflow: 'hidden' }}>
        <div className="mobile_block" style={{ padding: '24px 0 0 0' }}>
          <Outlet />
        </div>
      </Layout>
    </ConfigProvider>
  );
}
export default observer(CabinetLayout);
