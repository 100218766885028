import {
  Button,
  ConfigProvider,
  Dropdown,
  message,
  Popconfirm,
  Space,
  Table,
  TableColumnsType,
  TableProps,
} from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Icon from '../../../../ui/Icon';
import StatusTag from '../../StatusTag';

import { useParams } from 'react-router-dom';
import { WorkspacesStore } from '../../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import { ManagerStore } from '../../../../../DataSource/Stores/ManagerStore';
import { DeliveryZonesStore } from '../../../../../DataSource/Stores/Site/DeliveryZonesStore';
import * as styles from './index.module.scss';
import Zone from './Modal/Zone';

type OnChange = NonNullable<TableProps<DataType>['onChange']>;
type Filters = Parameters<OnChange>[1];

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

interface DataType {
  _id: string;
  name: string;
  workspaceId: string;
  geoJson: GeoJson;
  area: number;
  couriers: [];
  status: string;
}

interface GeoJson {
  type: string;
  features: Array<{
    type: string;
    properties: object;
    geometry: {
      type: string;
      coordinates: number[][][]; // Массив координат для полигона
    };
  }>;
}

// interface DeliveryZone {
//   _id: string;
//   name: string;
//   workspaceId: string;
//   geoJson: GeoJson;
//   area: number;
//   couriers: [];
//   status: string;
// }
// const data: DataType[] = [
//   {
//     key: '1',
//     name: 'Алатауский район',
//     age: 32,
//     kolvo: '103,22 км²',
//     status: 'Активна',
//   },
//   {
//     key: '2',
//     name: 'Алатауский район',
//     age: 42,
//     status: 'Активна',
//     kolvo: '103,22 км²',
//   },
//   {
//     key: '3',
//     name: 'Алатауский район',
//     age: 32,
//     status: 'Неактивна',
//     kolvo: '103,22 км²',
//   },
//   {
//     key: '4',
//     name: 'Алатауский район',
//     age: 32,
//     status: 'Активна',
//     kolvo: '103,22 км²',
//   },
// ];

type WorkspaceIdParams = {
  id?: string;
};

const ManagingZoning = () => {
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});
  const [deliveryZones, setDeliveryZones] = useState<DataType[]>([]);

  const { id } = useParams<WorkspaceIdParams>();

  useEffect(() => {
    console.log('ManagingZoning workspaceId: ', id);
    getZones();
  }, [id]);

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const res = await WorkspacesStore.membersGet.call(id);
        WorkspacesStore.setMembers(res.data);
        console.log(res);
      } catch (e: any) {
        console.error(e);
      }
    })();
  }, [id]);

  const getZones = async () => {
    if (!id) return;
    await DeliveryZonesStore.getList(id);
    console.log('DeliveryZonesStore.dataList: ', DeliveryZonesStore.dataList);
    if (DeliveryZonesStore.dataList.length) {
      const deliveryZones: DataType[] = DeliveryZonesStore.dataList;
      //   .map(
      // (deliveryZone: DeliveryZone) => ({
      //   key: deliveryZone._id,
      //   name: deliveryZone.name,
      //   age: 0,
      //   status: deliveryZone.status,
      //   kolvo: '',
      // })
      // );
      console.log({ deliveryZones });
      setDeliveryZones(deliveryZones);
    }
    if (!DeliveryZonesStore.dataList.length) setDeliveryZones([]);
  };

  const showModal = (zoneId?: string) => {
    console.log('showModal', zoneId);
    if (zoneId) {
      DeliveryZonesStore.setZoneId(zoneId);
    } else {
      DeliveryZonesStore.setZoneId('');
    }
    ManagerStore.isModalOpenZone = true;
  };

  const handleChange: OnChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter as Sorts);
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setActiveFilter(null);
  };

  const acFilter = () => {
    setFilteredInfo({
      status: ['Активна'],
    });
    setActiveFilter('activefil');
  };

  const unactiveFilter = () => {
    setFilteredInfo({
      status: ['Неактивна'],
    });
    setActiveFilter('unactivefil');
  };

  const deleteZone = async (zoneId: string) => {
    if (!id) return;
    await DeliveryZonesStore.deleteZone(id, zoneId);
    getZones();
    message.success('Удалено');
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <p>{text}</p>,
    },
    // {
    //   title: 'Кол-во заказов',
    //   dataIndex: 'age',
    //   key: 'age',
    //   // sorter: (a, b) => a.age - b.age,
    //   sortOrder: sortedInfo.columnKey === 'age' ? sortedInfo.order : null,
    //   ellipsis: true,
    // },
    {
      title: 'Курьеры',
      dataIndex: 'age',
      key: 'age',
      // sorter: (a, b) => a.age - b.age,
      sortOrder: sortedInfo.columnKey === 'age' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <>{record.couriers.length}</>,
    },
    {
      title: 'Площадь',
      dataIndex: 'area',
      key: 'area',
      // sorter: (a, b) => a.kolvo.length - b.kolvo.length,
      // sortOrder: sortedInfo.columnKey === 'kolvo' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Активна', value: 'Активна' },
        { text: 'Неактивна', value: 'Неактивна' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value as string),
      ellipsis: true,
      render: (text, record) => (
        <>
          {record.status === 'active' ? (
            <StatusTag theme="create" title={text} />
          ) : (
            <StatusTag theme="unactive" title={text} />
          )}
        </>
      ),
    },
    {
      title: '',
      dataIndex: '',
      width: '5%',
      key: 'x',
      render: (_, record) => (
        <Dropdown
          className="menuprogile"
          trigger={['click']}
          menu={{
            items: [
              {
                key: '1',
                label: <a onClick={() => showModal(record._id)}>Редактировать</a>,
              },
              {
                key: '2',
                label: (
                  <Popconfirm title="Вы уверены?" onConfirm={() => deleteZone(record._id)}>
                    <a>Удалить</a>
                  </Popconfirm>
                ),
              },
            ],
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Icon slug={'fi-rr-menu-dots-vertical'} />
          </a>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <p className="title_delivery">Управление зонированием</p>

      <div className="flex_group mt-24">
        <div className={styles.new_zone} style={{ display: 'none' }}>
          {/* <DistrictMap /> */}
        </div>
        <div className="tabl_bl">
          <Space className={styles.tabl_bl__buttons + ' tabl_bl__buttons'}>
            <p className={styles.title}>Зоны доставок</p>
            <Button className={styles.but_neword} onClick={() => showModal('')}>
              Добавить Зону
            </Button>
            <Button
              style={{ display: 'none' }}
              onClick={clearFilters}
              className={`filter_button ${activeFilter === null ? 'active' : ''}`}
            >
              Все
            </Button>
            <Button
              style={{ display: 'none' }}
              onClick={acFilter}
              className={`filter_button ${activeFilter === 'activefil' ? 'active' : ''}`}
            >
              Активен
            </Button>
            <Button
              style={{ display: 'none' }}
              onClick={unactiveFilter}
              className={`filter_button ${activeFilter === 'unactivefil' ? 'active' : ''}`}
            >
              Неактивен
            </Button>
          </Space>
          <Table
            columns={columns}
            pagination={{ position: ['topRight'] }}
            dataSource={deliveryZones}
            onChange={handleChange}
          />
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Mulish',
          },
        }}
      >
        <Zone />
      </ConfigProvider>
    </>
  );
};

export default observer(ManagingZoning);
