import { Result, Spin, message } from 'antd';
import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import uWS from '../../../../uWS';
import JobColumnItem from './JobColumnItem';
import * as styles from './index.module.scss';
import JobsKanbanDeck from '../../JobsKanbanDeck';
import { toJS } from 'mobx';
import dayjs from 'dayjs';
import { JobsDataType, StagesDataType } from '../../../ types';
import Column from '../../JobsKanbanDeck/Column';

interface IProps {
  workspaceId: string;
}

const Kanban: FC<IProps> = ({ workspaceId }) => {
  console.log(workspaceId, 'workspaceId');
  useEffect(() => {
    if (workspaceId) {
      StagesStore.getList(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  }, [workspaceId]);

  useEffect(() => {
    StagesStore.getListJobs(workspaceId).catch((e) => {
      console.error(e);
    });
  }, [workspaceId]);

  useEffect(() => {
    if (!workspaceId) return;
    const eventStatus = uWS.subscribe(`workspaces/${workspaceId}/changeJob`, (data: any) => {
      if (data.error) {
        console.error('Error subscribing to workspace changeJob:', data.error);
        return;
      }

      console.log('changeJob:', data);
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    });

    return () => {
      if (eventStatus) uWS.unSubscribe(`workspaces/${workspaceId}/changeJob`);
    };
  }, [workspaceId]);

  const onDropHandler = async (job: JobsDataType, stage: StagesDataType) => {
    console.log('DROP', toJS(job), toJS(stage));

    StagesStore.setDataJobList(
      StagesStore.dataJobsList.map((item) =>
        item._id === job._id ? { ...item, stageId: stage._id } : item
      )
    );

    await StagesStore.updateJobStage(workspaceId, job._id, stage._id);

    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.isModalOpen = false;
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  const calculateJobHeight = (job: JobsDataType) => {
    let height = 160;

    if (job.assignedTo) {
      height += 40;
    }

    if (!!job.labels?.length) {
      height += 26;
    }

    return height + 8;
  };

  return (
    <div className={styles.newJobsBlock}>
      <div className={styles.newJobsBlockBody}>
        <div className={styles.columns}>
          {StagesStore.loadingError && 'Error loading'}
          {StagesStore.loading && StagesStore.dataList.length === 0 && <Spin size="small" />}
          {!StagesStore.loading && StagesStore.dataList.length === 0 && (
            <div>
              <Result status="warning" title="You don't have any stages" />
            </div>
          )}

          {!StagesStore.loading && StagesStore.dataList.length !== 0 && (
            <JobsKanbanDeck
              columns={StagesStore.dataList}
              items={toJS(StagesStore.dataJobsList)}
              columnKeyField={'stageId'}
              renderColumn={(column) => (
                <Column {...column} variant="DeliveryCenter" children={null} />
              )}
              onDrop={onDropHandler}
              sort={(a, b) => dayjs(b.targetDate).unix() - dayjs(a.targetDate).unix()}
              rowHeight={calculateJobHeight}
              variant="DeliveryCenter"
            >
              {(item, dragProps) => <JobColumnItem {...item} dragProps={dragProps} />}
            </JobsKanbanDeck>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(Kanban);
