import { Avatar } from 'antd';
import { observer } from 'mobx-react';
import avatar from '../../../../assets/save.svg';
import Clickable from '../../../ui/Clickable';
import Icon from '../../../ui/Icon';
import * as styles from './index.module.scss';
export interface CourierItemProps {
  title: string;
  icon: boolean;
}

const CourierItem = ({ title, icon }: CourierItemProps) => {
  return (
    <Clickable onClick={() => {}} className={styles.courier_item}>
      <p>
        <Avatar size={30} src={avatar} /> {title}
      </p>
      {icon ? <Icon className="ui-icon-button-icon" slug={'fi-rr-arrow-up-right'} /> : ''}
    </Clickable>
  );
};
export default observer(CourierItem);
