// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YYP6NJd32mmBMEGQK3cw{display:flex;flex-direction:column;gap:10px;padding:0;height:0;flex:1;overflow-y:hidden;overflow-x:auto}.YYP6NJd32mmBMEGQK3cw::-webkit-scrollbar{width:8px}.YYP6NJd32mmBMEGQK3cw::-webkit-scrollbar-track{background:#f3f5f6}.YYP6NJd32mmBMEGQK3cw::-webkit-scrollbar-thumb{background-color:#ddd;border-radius:20px;border:2px solid #f3f5f6}.Az8Htm9yYv_3iLfZ7x1s{display:flex;align-items:center;gap:12px}.Az8Htm9yYv_3iLfZ7x1s .EeCo21JxaxoeuLHbk2Hj{height:35px;width:280px;background-color:#fff;border:1px solid #e0e0e0;border-radius:8px;box-shadow:0 2px 2px 0 #eaeaea;color:rgba(42,48,56,.4);font-size:14px;font-weight:800;text-transform:uppercase;display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/Workspaces/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,SAAA,CAEA,QAAA,CACA,MAAA,CACA,iBAAA,CACA,eAAA,CAEA,yCACE,SAAA,CAEF,+CACE,kBAAA,CAEF,+CACE,qBAAA,CACA,kBAAA,CACA,wBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,4CACE,WAAA,CACA,WAAA,CACA,qBAAA,CACA,wBAAA,CACA,iBAAA,CACA,8BAAA,CACA,uBAAA,CACA,cAAA,CACA,eAAA,CACA,wBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".distributionCenterPage{display:flex;flex-direction:column;gap:10px;padding:0;height:0;flex:1;overflow-y:hidden;overflow-x:auto}.distributionCenterPage::-webkit-scrollbar{width:8px}.distributionCenterPage::-webkit-scrollbar-track{background:#f3f5f6}.distributionCenterPage::-webkit-scrollbar-thumb{background-color:#ddd;border-radius:20px;border:2px solid #f3f5f6}.distributionCenterPageTop{display:flex;align-items:center;gap:12px}.distributionCenterPageTop .title{height:35px;width:280px;background-color:#fff;border:1px solid #e0e0e0;border-radius:8px;box-shadow:0 2px 2px 0 #eaeaea;color:rgba(42,48,56,.4);font-size:14px;font-weight:800;text-transform:uppercase;display:flex;align-items:center;justify-content:center}"],"sourceRoot":""}]);
// Exports
export var distributionCenterPage = `YYP6NJd32mmBMEGQK3cw`;
export var distributionCenterPageTop = `Az8Htm9yYv_3iLfZ7x1s`;
export var title = `EeCo21JxaxoeuLHbk2Hj`;
export default ___CSS_LOADER_EXPORT___;
