import dayjs from 'dayjs';
import moment from 'moment';

export function toLocalDate(utcDate: string) {
  return moment.utc(utcDate).local().format('YYYY-MM-DD HH:mm:ss');
}

export function formatDate(date: string) {
  return dayjs(date).format('HH : mm・MM / DD / YYYY');
}

// ASC
export function jobSorterByDate(a: any, b: any, field: string) {
  if (dayjs(a[field]).isBefore(dayjs(b[field]))) return 1;
  if (dayjs(a[field]).isAfter(dayjs(b[field]))) return -1;
  return 0;
}
