import { axiosMaximus } from './axiosMaximus';
import { makeConfig, makeUrl } from './basicHelpers';

export const basicRequestDelete = async (relativeUrl: string, requestConfig = {}) => {
  try {
    return await axiosMaximus.delete(makeUrl(relativeUrl), makeConfig(requestConfig));
  } catch (error) {
    // @ts-ignore
    delete error.stack;
    throw error;
  }
};
