// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._ifKr3EpvkhxoOsEn3Nj{display:flex;flex-direction:column;align-items:center;justify-content:center}.PQjrM7auf3_v944P3Cmj{position:relative;align-items:center;display:flex}.PQjrM7auf3_v944P3Cmj p{font-weight:400;font-size:15px;margin:0 0 0 8px;color:#000}.nBsqFlVbhccFpOPwQwFS{position:absolute;top:0;left:0;width:100%;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.nBsqFlVbhccFpOPwQwFS img{width:36px;height:36px;border-radius:50%}.ztPHhNYO3_lRorK004GY img{width:26px !important;height:26px !important}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/Kanban/CourierItemProgressDrag/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,iBAAA,CACA,kBAAA,CACA,YAAA,CACA,wBACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CAIJ,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,0BACE,UAAA,CACA,WAAA,CACA,iBAAA,CAIF,0BACE,qBAAA,CACA,sBAAA","sourcesContent":[".bl_progress_course{display:flex;flex-direction:column;align-items:center;justify-content:center}.progress_container{position:relative;align-items:center;display:flex}.progress_container p{font-weight:400;font-size:15px;margin:0 0 0 8px;color:#000}.progress_content{position:absolute;top:0;left:0;width:100%;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.progress_content img{width:36px;height:36px;border-radius:50%}.size_32 img{width:26px !important;height:26px !important}"],"sourceRoot":""}]);
// Exports
export var bl_progress_course = `_ifKr3EpvkhxoOsEn3Nj`;
export var progress_container = `PQjrM7auf3_v944P3Cmj`;
export var progress_content = `nBsqFlVbhccFpOPwQwFS`;
export var size_32 = `ztPHhNYO3_lRorK004GY`;
export default ___CSS_LOADER_EXPORT___;
