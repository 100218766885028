import type { MenuProps, RadioChangeEvent } from 'antd';
import { Dropdown, Popconfirm, message } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { JobsDataType, StagesDataType } from '../../../../ types';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import Button from '../../../../ui/Button';
import Icon from '../../../../ui/Icon';
import IconButton from '../../../../ui/IconButton';
import SliderButton from '../../../SliderButton';
import Accordion from '../../Accordion';
import * as styles from './index.module.scss';

interface JobItemProps extends JobsDataType {
  onClick: (job: JobsDataType) => void;
  isSelectedJob: boolean;
}

const JobItem: FC<JobItemProps> = ({
  name,
  _id,
  onClick,
  jobId,
  targetDate,
  workspaceId,
  bitrixId2,
  comments,
  jiraId2,
  jobData,
  isSelectedJob = false,
}) => {
  const [selectedStages, setSelectedStages] = useState('');
  const [selectedJobId, setSelectedJobId] = useState('');
  const stages = StagesStore.dataList;
  const stageNewJobs: StagesDataType | null = stages[2] || null;
  const stageNewJobs1: StagesDataType | null = stages[1] || null;

  const onChange = (e: RadioChangeEvent) => {
    setSelectedStages(e.target.value);
    console.log(e.target.value);
  };

  useEffect(() => {
    setSelectedJobId(_id);
  }, []);

  const onChangeStages = async () => {
    //console.log(selectedJobId, selectedStages, workspaceId);
    await StagesStore.updateJobStage(workspaceId, selectedJobId, stageNewJobs._id);
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.setLayoutPageJob('joblay');
      StagesStore.setTabsIndex(1);
      StagesStore.setSelectedJob(null);
      StagesStore.getListJobsQueue(workspaceId, stageNewJobs1._id).catch((e) => {
        console.error(e);
      });
    }
  };
  const dateCreate = dayjs(targetDate);

  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedBitrixId, setIsCopiedBitrixId] = useState(false);

  const textToCopy = jobId;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      message.info('Скопировано', 2);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  const handleCopyBitrixId = async () => {
    if (bitrixId2 !== undefined) {
      try {
        await navigator.clipboard.writeText(bitrixId2);
        setIsCopiedBitrixId(true);
        message.info('Bitrix ID скопировано', 2);
        setTimeout(() => setIsCopiedBitrixId(false), 2000);
      } catch (err) {
        console.error('Failed to copy Bitrix ID: ', err);
      }
    } else {
      console.error('Bitrix ID is undefined');
    }
  };

  const changeArchived = async (isArchived: boolean) => {
    await StagesStore.updateJobArchived(workspaceId, _id, isArchived);
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };
  const topDropdownMenu: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <>
          <Popconfirm
            title="Bы действительно хотите архивировать заказ?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => changeArchived(true)}
          >
            <div>Архивировать</div>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      {isSelectedJob == false ? (
        <div
          onClick={() =>
            onClick({
              name,
              _id,
              jobId,
              workspaceId,
              bitrixId2,
              jiraId2,
              comments,
              jobData,
              targetDate,
            })
          }
        >
          <div className={styles.flex_bl}>
            <p className={styles.name}>{name}</p>
            <div className={styles.add_text}>
              <p className={styles.id}>
                #{jobId} <Icon slug="arrow_vertical" />
              </p>
              <div className={styles.dateBlock}>
                {dayjs(targetDate).format('HH : mm')}
                <span className={styles.separator}>•</span>
                {dayjs(targetDate).format('DD/MM/YY')}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.flex_block}>
            <p className={styles.name2}>{name}</p>
            <Dropdown menu={{ items: topDropdownMenu }} trigger={['click']}>
              <IconButton icon="three_dots" variant="secondary" />
            </Dropdown>
          </div>
          <div>
            <div className={styles.flex_jobid}>
              <p>Job ID:</p>
              <span>
                {jobId}
                <Button
                  variant="outlined"
                  icon="copy_icon"
                  onClick={handleCopy}
                  children={undefined}
                />
              </span>
            </div>
            <div className={styles.flex_jobid}>
              {bitrixId2 ? <p>Bitrix Id:</p> : <p>Jira Id:</p>}
              <span>
                {bitrixId2 ? bitrixId2 : jiraId2}
                <Button
                  variant="outlined"
                  icon="copy_icon"
                  onClick={handleCopyBitrixId}
                  children={undefined}
                />
              </span>
            </div>
            <div className={styles.colasp_bl}>
              <Accordion jobData={jobData} comm={comments} targetDate={targetDate} />
            </div>
          </div>

          <SliderButton name={'Взять в работу'} onClick={onChangeStages} />
        </div>
      )}
    </>
  );
};

export default observer(JobItem);
