// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A4uoIgaQEsDfYoHsGvQW{justify-content:space-between;display:flex;padding:0 16px;align-items:center}.A4uoIgaQEsDfYoHsGvQW .eS1jIpteofdfVcUoqS6n span{font-weight:300;font-size:14px;text-transform:uppercase;color:#878787}.A4uoIgaQEsDfYoHsGvQW .eS1jIpteofdfVcUoqS6n p{font-weight:600;font-size:20px;color:#000}.cuJ0_FFEgLKmImvnlkYf{justify-content:space-between;display:flex;align-items:center;column-gap:8px}.mdfyeAB0dNIX7dsKZBAG{font-weight:400;margin-bottom:16px;font-size:15px;color:#000;margin-top:16px;padding-left:16px}.qLdfnJQiuBo_LFzJa_vm{padding:0 8px}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/Kanban/NewOrders/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,6BAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA,CAEE,iDACE,eAAA,CACA,cAAA,CACA,wBAAA,CACA,aAAA,CAEF,8CACE,eAAA,CACA,cAAA,CACA,UAAA,CAIN,sBACE,6BAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CAGF,sBACE,eAAA,CACA,kBAAA,CACA,cAAA,CACA,UAAA,CACA,eAAA,CACA,iBAAA,CAEF,sBACE,aAAA","sourcesContent":[".flex_bl{justify-content:space-between;display:flex;padding:0 16px;align-items:center}.flex_bl .text span{font-weight:300;font-size:14px;text-transform:uppercase;color:#878787}.flex_bl .text p{font-weight:600;font-size:20px;color:#000}.d_flex{justify-content:space-between;display:flex;align-items:center;column-gap:8px}.order_text{font-weight:400;margin-bottom:16px;font-size:15px;color:#000;margin-top:16px;padding-left:16px}.order_list{padding:0 8px}"],"sourceRoot":""}]);
// Exports
export var flex_bl = `A4uoIgaQEsDfYoHsGvQW`;
export var text = `eS1jIpteofdfVcUoqS6n`;
export var d_flex = `cuJ0_FFEgLKmImvnlkYf`;
export var order_text = `mdfyeAB0dNIX7dsKZBAG`;
export var order_list = `qLdfnJQiuBo_LFzJa_vm`;
export default ___CSS_LOADER_EXPORT___;
