import dayjs from 'dayjs';
import { FC } from 'react';
import * as styles from './index.module.scss';

interface IProps {
  targetDate?: string;
  expectedDate?: Date;
}

const DeadlineBlock: FC<IProps> = ({ targetDate, expectedDate }) => {
  return (
    <div className={styles.fieldsWrap}>
      <p className={styles.title}>СРОКИ</p>
      <div className={styles.field}>
        <p className={styles.name}>Цель</p>
        <div className={styles.copyBlock}>{dayjs(targetDate).format('DD / MM / YYYY, HH:mm')}</div>
        {/*<DatePicker*/}
        {/*  showTime*/}
        {/*  allowClear={false}*/}
        {/*  suffixIcon={null}*/}
        {/*  variant="filled"*/}
        {/*  format="DD / MM / YYYY, HH:mm"*/}
        {/*  value={dayjs(targetDate)}*/}
        {/*  onChange={handleDateChange}*/}
        {/*  style={{ backgroundColor: '#F3F5F6E2', borderRadius: '8px', padding: '4px 8px' }}*/}
        {/*/>*/}
      </div>
      <div className={styles.field}>
        <p className={styles.name}>Прогноз</p>
        <div className={styles.copyBlock}>
          {expectedDate ? dayjs(expectedDate).format('DD / MM / YYYY, HH:mm') : '-'}
        </div>
      </div>
      {/*<div className={styles.field}>*/}
      {/*  <p className={styles.name}>Прогноз</p>*/}
      {/*  <DatePicker*/}
      {/*    showTime*/}
      {/*    allowClear={false}*/}
      {/*    suffixIcon={null}*/}
      {/*    variant="filled"*/}
      {/*    format="DD / MM / YYYY, HH:mm"*/}
      {/*    style={{ backgroundColor: '#F3F5F6E2', borderRadius: '8px', padding: '4px 8px' }}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};

export default DeadlineBlock;
