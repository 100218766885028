import { Dropdown, MenuProps, message } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import Icon from '../../../ui/Icon';
import Timer from '../Timer';
import * as styles from './index.module.scss';
dayjs.extend(duration);

interface TimerProps {
  exDate: any;
  workId: string;
  jobId?: string;
  taskId: string;
}
const TimerAddMinuts: React.FC<TimerProps> = ({ workId, exDate, jobId, taskId }) => {
  const [selectedMinutes, setSelectedMinutes] = useState<number | null>(null);
  const [targetDateTimer, setTargetDateTimer] = useState<number>(dayjs(exDate).valueOf());
  const [visible, setVisible] = useState(false);
  // const [adedtime, setAdedtime] = useState<Date>(exDate);

  //console.log('exDate', exDate);

  useEffect(() => {
    if (!StagesStore?.dataJobs?.job?.expectedDate) {
      return StagesStore.setExDate();
    }
    StagesStore.setExDate(StagesStore?.dataJobs?.job?.expectedDate);
  }, [StagesStore?.dataJobs?.job?._id]);

  useEffect(() => {
    const targetDateTimer = dayjs(
      StagesStore.ExDate ? StagesStore.ExDate : StagesStore?.dataJobs?.job?.expectedDate
    ).valueOf();
    setTargetDateTimer(targetDateTimer);
  }, [StagesStore?.dataJobs?.job?.expectedDate, StagesStore.ExDate]);

  const handleMinutesClick = (minutes: number) => {
    setSelectedMinutes(minutes);
    setVisible(true);
  };

  // console.log('adedtime', adedtime);

  // console.log(selectedMinutes, '23232');

  const handleAddClick = () => {
    // console.log(selectedMinutes, 'selectedMinutes');
    //console.log(dayjs(StagesStore.ExDate).format('HH : mm'), 'StagesStore.ExDate');
    if (selectedMinutes !== null) {
      // console.log(adedtime);
      const newDate = dayjs(StagesStore.ExDate || StagesStore?.dataJobs?.job?.expectedDate)
        .add(selectedMinutes, 'minute')
        .toDate();

      StagesStore.setExDate(newDate);
      // setTargetDateTimer(newDate.valueOf());
      setVisible(false);
      setSelectedMinutes(null);
      changeExpextDate();
      // console.log('newdate', newDate.format('DD/MM/YY HH : mm'));
      //console.log(dayjs(StagesStore.ExDate).format('HH : mm'), 'StagesStore.ExDate');
    }
  };

  const changeExpextDate = async () => {
    // const dateWithDuration = adedtime;

    // console.log('adedtime', adedtime);

    if (jobId && StagesStore.ExDate) {
      await StagesStore.updateTaskexpectedDate(workId, jobId, StagesStore.ExDate);
      if (StagesStore.taskUpdateDateError) {
        message.error('Update Error');
      } else {
        message.info('Success');
        console.log('expectedDate', StagesStore?.dataJobs?.job?.expectedDate);

        StagesStore.getJobsId(workId, jobId ? jobId : '').catch((e) => {
          console.error(e);
        });
        setSelectedMinutes(null);
      }
    }
  };

  const menu: MenuProps = {
    items: [
      {
        label: (
          <a
            className={`${styles.minut_but} ${selectedMinutes === 15 ? styles.active_but : ''}`}
            onClick={() => {
              handleMinutesClick(15);
            }}
          >
            15 минут
          </a>
        ),
        key: '15',
      },
      {
        label: (
          <a
            className={`${styles.minut_but} ${selectedMinutes === 25 ? styles.active_but : ''}`}
            onClick={() => {
              handleMinutesClick(25);
            }}
          >
            25 минут
          </a>
        ),
        key: '25',
      },
      {
        label: (
          <a
            className={`${styles.minut_but} ${selectedMinutes === 45 ? styles.active_but : ''}`}
            onClick={() => {
              handleMinutesClick(45);
            }}
          >
            45 минут
          </a>
        ),
        key: '45',
      },
      { type: 'divider' },
      {
        label: (
          <a className={styles.but_add} onClick={handleAddClick}>
            <Icon slug="icons_add_but" />
            Добавить
          </a>
        ),
        key: 'add',
      },
    ],
  };

  return (
    <div className={styles.timer_date}>
      <p>До завершения осталось:</p>
      <div className={styles.flex_timer_cont}>
        {StagesStore.ExDate && (
          <Timer targetDateTimer={targetDateTimer} expectedDate={StagesStore.ExDate} />
        )}{' '}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Dropdown menu={menu} open={visible} trigger={['click']}>
            <a
              onClick={(e) => {
                e.preventDefault();
                setVisible(!visible);
              }}
            >
              <Icon slug="icons_add_time" />
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default observer(TimerAddMinuts);
