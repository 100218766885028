import { animated, useSpring } from '@react-spring/web';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import Icon from '../../../../ui/Icon';
import TaskItem from '../TaskItem';
import * as styles from './index.module.scss';

export interface SelectProp {
  workId?: string;
}
const SelectedTask: FC<SelectProp> = ({ workId }) => {
  const textAnimation = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: StagesStore.showTextTask ? 1 : 0,
    },
    delay: 500,
    config: { duration: 400 },
  });

  const onBack = () => {
    StagesStore.setShowTextTask(false);
    StagesStore.setLayoutPage('library');
    StagesStore.setIsSelectedTask(false);
  };

  return (
    <div className={styles.prompts_create}>
      <div className={styles.flex_block}>
        <animated.p className={styles.back} onClick={onBack} style={textAnimation}>
          <Icon slug="arrow_back" />
          Все "В работе"
        </animated.p>
      </div>

      {StagesStore.selectedTask && (
        <TaskItem
          _id={StagesStore?.selectedTask?._id || ''}
          dueDate={StagesStore?.selectedTask?.dueDate || ''}
          title={StagesStore?.selectedTask?.title || ''}
          status={StagesStore?.selectedTask?.status || ''}
          description={StagesStore?.selectedTask?.description || ''}
          stageName={StagesStore?.selectedTask?.stageName || ''}
          onClick={() => {}}
          comments={StagesStore?.selectedTask?.comments || []}
          jobId={StagesStore?.selectedTask?.jobId}
          jobIdShort={StagesStore?.selectedTask?.jobIdShort}
          expectedDate={StagesStore?.selectedTask?.expectedDate}
          isSelectedTask={StagesStore.isSelectedTask}
          workspaceId={workId ? workId : ''}
        />
      )}
    </div>
  );
};

export default observer(SelectedTask);
