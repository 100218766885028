import type { GetProps, TabsProps } from 'antd';
import { Input, Tabs } from 'antd';
import { observer } from 'mobx-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CourierItemProgressDrag from '../CourierItemProgressDrag';
import NewOrders from '../NewOrders';
import { COURIER_DATA } from './data';
import * as styles from './index.module.scss';

const onChange = (key: string) => {
  console.log(key);
};
console.log(styles, 'dfddf');
const items: TabsProps['items'] = [
  {
    key: '1',
    label: (
      <p>
        Все <span>15</span>
      </p>
    ),
    children: (
      <div className={styles.tabs_bl}>
        <p>
          Алатау<span>3</span>
        </p>
        <div className={styles.flex_courier}>
          {COURIER_DATA.map((courier) => (
            <CourierItemProgressDrag
              key={courier.key}
              percent={courier.percent}
              theme={courier.theme}
              title={courier.title}
              photo={courier.image}
            />
          ))}
        </div>
      </div>
    ),
  },
  {
    key: '2',
    label: (
      <p>
        Алатау <span>3</span>
      </p>
    ),
    children: 'Content of Tab Pane 2',
  },
  {
    key: '3',
    label: (
      <p>
        Алмалы <span>3</span>
      </p>
    ),
    children: 'Content of Tab Pane 3',
  },
  {
    key: '4',
    label: (
      <p>
        Ауэзов <span>3</span>
      </p>
    ),
    children: 'Content of Tab Pane 3',
  },
  {
    key: '5',
    label: (
      <p>
        Бостандык <span>3</span>
      </p>
    ),
    children: 'Content of Tab Pane 3',
  },
  {
    key: '6',
    label: (
      <p>
        Медео <span>3</span>
      </p>
    ),
    children: 'Content of Tab Pane 3',
  },
  {
    key: '7',
    label: (
      <p>
        Наурызбай <span>3</span>
      </p>
    ),
    children: 'Content of Tab Pane 3',
  },
  {
    key: '8',
    label: (
      <p>
        Турксиб <span>3</span>
      </p>
    ),
    children: 'Content of Tab Pane 3',
  },
];

type SearchProps = GetProps<typeof Input.Search>;
const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

const Distribution = () => {
  const { Search } = Input;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.flex_group}>
        <div className={styles.new_orders}>
          <NewOrders />
        </div>
        <div className={styles.courier_bl}>
          <div className={styles.courier_bl__top}>
            <p>курьеры</p>

            <Search
              placeholder="Найдите заказ по id, адресу или курьеру"
              onSearch={onSearch}
              style={{ width: 200 }}
            />
          </div>
          <div className={styles.courier_bl__cont}>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default observer(Distribution);
