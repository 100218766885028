import { Button, Flex, message } from 'antd';
import dayjs from 'dayjs';
import { JournalDataType } from '.';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import Icon from '../../../ui/Icon';
import * as styles from './index.module.scss';
import { WorkspacesStore } from '../../../../DataSource/Stores/Cabinet/WorkspacesStore';

export const renderCell = (
  value: any,
  record: JournalDataType,
  index: number,
  action?: (...args: any) => void
): React.ReactNode => {
  const key = getKeyByValue(record, value);

  if (key === 'jobId') {
    return value || value === 0 ? (
      <Flex align="center" gap={6}>
        <Button size="small" type="text" onClick={() => action && action(record)}>
          {value}
        </Button>
        <div
          className={styles.copyBtn}
          onClick={(e) => {
            e.stopPropagation();
            handleCopy(value);
          }}
        >
          <Icon slug="copy" />
        </div>
      </Flex>
    ) : (
      '-'
    );
  }

  if (key === 'name') return value || '-';

  if (key === 'stageId') {
    return StagesStore.dataList.find((item) => item._id === record.stageId)?.name;
  }

  if (key === 'jobFor') {
    return value ? value.toUpperCase() : '-';
  }

  if (key === 'createdAt') {
    return dayjs(value).format('DD.MM.YYYY | HH:mm');
  }

  if (key === 'targetDate') {
    return dayjs(value).format('DD.MM.YYYY | HH:mm');
  }

  if (key === 'assignedTo') {
    return `${record.member?.userId.firstname} ${record.member?.userId.secondname}` || '-';
  }

  if (key === 'createdBy') {
    if (value in WorkspacesStore.members) {
      const { firstname, secondname } = WorkspacesStore.members[value].userId;
      return `${firstname} ${secondname}`;
    }
  }

  return value?.toString();
};

const getKeyByValue = <T,>(obj: T, value: any): keyof T | undefined => {
  return (Object.keys(obj as any) as (keyof T)[]).find((key) => obj[key] === value);
};

const handleCopy = async (value: any) => {
  try {
    await navigator.clipboard.writeText(value);
    message.info('Скопировано', 2);
  } catch (err) {
    console.error('Failed to copy text: ', err);
  }
};
