import anime from 'animejs';
import { makeAutoObservable } from 'mobx';

export interface IActiveModal {
  key: string;
  open: boolean;
  data: any;
}

type ContextMenuPropsItem = JSX.Element | null;

export interface ContextMenuProps {
  target: HTMLButtonElement;
  key: string;
  anchor?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' | 'bottom-center';
  items?: ContextMenuPropsItem[];
  children?: JSX.Element;
  onClose?: () => void;
  width?: number;
  autoClose?: boolean;
}

export class LayoutStoreClass {
  activeModals: IActiveModal[] = [];
  activeMenu: ContextMenuProps | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  openModal(key: string, data?: any) {
    if (this.activeModals.find((x) => x.key === key)) return;

    this.activeModals.push({ data, open: true, key });
  }

  closeModal(key: string) {
    this.activeModals = this.activeModals.filter((x) => x.key !== key);

    // TODO: Animation for closing
    // this.activeModals = this.activeModals.map((x) => (x.key === key ? { ...x, open: false } : x));
  }

  closeLastModal() {
    this.activeModals = this.activeModals.slice(0, -1);
  }

  openMenu(props: ContextMenuProps) {
    const $menu = document.querySelector('.app-context-menu');

    if (props.key !== this.activeMenu?.key && $menu) {
      this.activeMenu = props;

      props.target.classList.add('active');

      anime({
        targets: $menu,
        opacity: [0, 1],
        scaleX: [0.8, 1],
        duration: 100,
        easing: 'easeInOutSine',
      });
    }
  }

  closeMenu() {
    const $menu = document.querySelector('.app-context-menu');

    if (this.activeMenu?.onClose) this.activeMenu.onClose();

    this.activeMenu?.target.classList.remove('active');

    if ($menu)
      anime({
        targets: $menu,
        opacity: [1, 0],
        scaleX: [1, 0.8],
        duration: 200,
        complete: () => (this.activeMenu = null),
      });
  }
}

const LayoutStore = new LayoutStoreClass();

export default LayoutStore;
