import { DatePicker, Dropdown } from 'antd';
import { MenuProps } from 'antd/es/menu';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Member, MemberUser, Statistic } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import Clickable from '../../../ui/Clickable';
import Icon from '../../../ui/Icon';

import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts';

import * as styles from './index.module.scss';

type WorkspaceIdParams = {
  id: string;
};

type DateRange = 'Сегодня' | 'Вчера' | 'Неделя' | 'Месяц' | 'Выбрать свой';

interface ChartDataItem {
  value?: number;
  date: any;
  type?: string;
  OrdersCompleted?: number;
  UserDuration?: number;
}
interface UserStatistics {
  ordersCompleted: number;
  timeSpent: number;
  date: string[];
}
interface MenuItemType {
  key: string;
  label: string;
  // Добавьте другие поля, если необходимо
}

const { RangePicker } = DatePicker;

const Chart: React.FC = () => {
  const workspaceId = '66e170b52fc0a03d58789c24';
  // const workspaceId = config.WORKSPACE_ID;

  const { id } = useParams<WorkspaceIdParams>();

  const [selectedUser, setSelectedUser] = useState<MemberUser | null | string>('all');
  const [dateRange, setDateRange] = useState<DateRange>('Сегодня');
  const [customDateRange, setCustomDateRange] = useState<[Dayjs | null, Dayjs | null] | null>(null);

  const [chartData, setChartData] = useState<ChartDataItem[]>([]);
  const [inUserSelect, setInUserSelect] = useState<string>('Все');
  const [daysOffset, setDaysOffset] = useState<string>('1');

  const [orderCount, setOrderCount] = useState(0);

  const blockRef = useRef<HTMLDivElement | null>(null); // Создаем реф
  const [width, setWidth] = useState<number>(0);

  const [totalUserDuration, setTotalUserDuration] = useState(0);

  const UserDuration: React.FC = () => {
    const [averageDuration, setAverageDuration] = useState<string>('0,00');

    useEffect(() => {
      const userStatistics = StagesStore.dataUserStatistic;

      if (userStatistics.length > 0) {
        const totalDuration = userStatistics.reduce((total, item) => total + item.duration, 0);
        const average = totalDuration / userStatistics.length;
        const result = (average / 60).toFixed(2).replace('.', ',');
        setAverageDuration(result);
      } else {
        setAverageDuration('0,00');
      }
    }, [StagesStore.dataUserStatistic, selectedUser]);

    return averageDuration;
  };

  const getDateRange = (range: string) => {
    let startDate, endDate, dayOffset;

    switch (range) {
      case 'Сегодня':
        startDate = dayjs().startOf('day').toDate();
        endDate = dayjs().endOf('day').toDate();
        dayOffset = 1;
        break;

      case 'Вчера':
        startDate = dayjs().subtract(1, 'day').startOf('day').toDate();
        endDate = dayjs().subtract(1, 'day').endOf('day').toDate();
        dayOffset = 1;
        break;

      case 'Неделя':
        startDate = dayjs().subtract(6, 'day').startOf('day').toDate();
        endDate = dayjs().endOf('day').toDate();
        dayOffset = 7;
        break;

      case 'Месяц':
        startDate = dayjs().subtract(30, 'day').startOf('day').toDate();
        endDate = dayjs().toDate();
        dayOffset = dayjs().daysInMonth();
        break;

      case 'Выбрать свой':
        if (customDateRange && customDateRange[0] && customDateRange[1]) {
          startDate = dayjs(customDateRange[0]).startOf('day').toDate();
          endDate = dayjs(customDateRange[1]).endOf('day').toDate();
          dayOffset = dayjs(endDate).diff(dayjs(startDate), 'day') + 1; // +1, чтобы включить последний день
        } else {
          startDate = dayjs().startOf('day').toDate(); // Если ничего не выбрано, берём сегодняшний день
          endDate = dayjs().endOf('day').toDate(); // Конец текущего дня
          dayOffset = 1; // Всего 1 день
        }
        break;

      default:
        startDate = dayjs().toDate();
        endDate = dayjs().toDate();
        dayOffset = 1;
    }

    return { startDate, endDate, dayOffset };
  };

  const handleDateRange = (value: DateRange) => {
    console.log('Selected date range:', value, selectedUser);
    if (!selectedUser) return;
    setDateRange(value);
    const { startDate, endDate, dayOffset } = getDateRange(value);
    setDaysOffset(dayOffset.toString());
  };

  // useEffect(() => {
  //   console.log('Selected User:', selectedUser);

  //   if (selectedUser) {
  //     handleDateRange(dateRange);
  //   }
  // }, [dateRange, selectedUser]);

  //console.log(dateRange, 'dateRange');

  const menuItems: MenuProps['items'] = [
    {
      key: 'all',
      label: 'Все',
    },
    ...Object.values(WorkspacesStore.members).map((user: Member) => ({
      key: user.userId._id,
      label: user.userId.firstname,
    })),
  ];

  const handleMenuClick = (e: any) => {
    let selectedUserId: any = e.key;

    if (selectedUserId === 'all') {
      setInUserSelect('Все');
    } else {
      //const selectedMenuItem = menuItems.find((item) => item?.key === selectedUserId);

      const selectedMenuItem = menuItems.find((item): item is MenuItemType => {
        return item !== null && item.key === selectedUserId && 'label' in item;
      });

      if (selectedMenuItem) {
        setInUserSelect(selectedMenuItem.label);
      } else {
        setInUserSelect('');
        //console.warn('Пользователь не найден:', selectedUserId);
      }
    }

    setSelectedUser(selectedUserId);
    console.log('Выбранный пользователь:', selectedUserId);
  };

  const menu: MenuProps = {
    items: menuItems,
    onClick: handleMenuClick,
    className: styles.customMenu,
  };

  const handleCustomDateRange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    setCustomDateRange(dates);
    if (!selectedUser || selectedUser === 'all' || !dates || !dates[0] || !dates[1]) return;
    const startDate = dates[0].startOf('day').toDate();
    const endDate = dates[1].endOf('day').toDate();
    const dayOffset2 = dates[1].endOf('day').diff(dates[0].startOf('day'), 'day') + 1;

    setDaysOffset(dayOffset2.toString());

    const iduser: any = selectedUser;
    StagesStore.getUserStatistic(id ? id : ' ', iduser, startDate, endDate).catch((e) => {
      console.error(e);
    });
  };

  useEffect(() => {
    const { startDate, endDate, dayOffset } = getDateRange(dateRange);
    console.log(
      startDate,
      endDate,
      dayjs(startDate).format('YYYY-MM-DD'),
      dayjs(endDate).format('YYYY-MM-DD')
    );
    setDaysOffset(dayOffset.toString());

    const handleData = (data: Statistic[]) => {
      let dataForChart: ChartDataItem[] = [];
      let totalDuration = 0;

      if (data && data.length > 0) {
        const ordersByDate: { [key: string]: { OrdersCompleted: number; UserDuration: number } } =
          {};

        data.forEach((item) => {
          const createdAt = new Date(item.createdAt);
          if (createdAt >= startDate && createdAt <= endDate) {
            const date = dayjs(createdAt).format('YYYY-MM-DD');

            if (!ordersByDate[date]) {
              ordersByDate[date] = {
                OrdersCompleted: 0,
                UserDuration: 0,
              };
            }
            ordersByDate[date].OrdersCompleted += 1;
            const currentUserDuration = Math.round((item.duration || 0) / 60);
            ordersByDate[date].UserDuration += currentUserDuration;
            totalDuration += currentUserDuration;
            console.log('Обработка даты:', date);
          }
        });

        dataForChart = Object.keys(ordersByDate).map((date) => {
          const ordersCompleted = ordersByDate[date].OrdersCompleted;
          const avgDuration =
            ordersCompleted > 0
              ? (ordersByDate[date].UserDuration / ordersCompleted).toFixed(2)
              : '0.00';
          return {
            date,
            OrdersCompleted: ordersCompleted,
            UserDuration: parseFloat(avgDuration),
          };
        });
        console.log(dataForChart, 'dataForChart');

        setChartData(dataForChart);
        setOrderCount(data.length);
        setTotalUserDuration(totalDuration);
        if (selectedUser === 'all' && StagesStore.dataUserStatisticAll.length > 0) {
          const averageDuration = totalDuration / StagesStore.dataUserStatisticAll.length;
          setTotalUserDuration(parseFloat(averageDuration.toFixed(2)));
        } else {
          setTotalUserDuration(parseFloat(totalDuration.toFixed(2)));
        }
      } else {
        console.warn('Нет данных для отображения');
        setChartData([]);
        setOrderCount(0);
      }
    };

    const iduser: any = selectedUser !== 'all' ? selectedUser : null;

    if (iduser) {
      StagesStore.getUserStatistic(id || ' ', iduser, startDate, endDate)
        .then(() => handleData(StagesStore.dataUserStatistic))
        .catch((e) => {
          console.error(e);
        });
    } else {
      console.log(
        startDate,
        endDate,
        dayjs(startDate).format('YYYY-MM-DD'),
        dayjs(endDate).format('YYYY-MM-DD')
      );
      StagesStore.getUserAllStatistic(id || ' ', startDate, endDate)
        .then(() => {
          //console.log(StagesStore.dataUserStatisticAll, 'StagesStore.dataUserStatisticAll');
          handleData(StagesStore.dataUserStatisticAll);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [selectedUser, dateRange, id, customDateRange]);

  useEffect(() => {
    if (blockRef.current) {
      setWidth(blockRef.current.offsetWidth);
    }
  }, []);

  return (
    <div className={styles.block_all_stat}>
      <p className={styles.title_ad + ' mb-16'}>Диаграмма</p>
      <div className={styles.nameBlock}>
        <div className={styles.rangePickerWrap}>
          <p className={styles.name}>Сортировка по диапазону:</p>
          <div>
            <Dropdown
              menu={{
                items: [
                  { key: 1, label: 'Сегодня', onClick: () => handleDateRange('Сегодня') },
                  { key: 2, label: 'Вчера', onClick: () => handleDateRange('Вчера') },
                  { key: 3, label: 'Неделя', onClick: () => handleDateRange('Неделя') },
                  { key: 4, label: 'Месяц', onClick: () => handleDateRange('Месяц') },
                  {
                    key: 5,
                    label: 'Выбрать свой',
                    onClick: () => handleDateRange('Выбрать свой'),
                  },
                ],
              }}
              trigger={['click']}
            >
              <Clickable className={styles.rangePickerBtn}>
                {dateRange} <Icon slug="angle_down" color="#F1B729" />
              </Clickable>
            </Dropdown>
          </div>

          <div className={styles.rangeBlock + ' ' + styles.date_wrap}>
            {dateRange === 'Сегодня' && dayjs().format('DD / MM / YYYY')}
            {dateRange === 'Вчера' && dayjs().subtract(1, 'day').format('DD / MM / YYYY')}
            {dateRange === 'Неделя' && (
              <>
                {dayjs().subtract(6, 'day').format('DD / MM / YYYY')}
                <span>—</span>
                {dayjs().format('DD / MM / YYYY')}
              </>
            )}
            {dateRange === 'Месяц' && (
              <>
                {dayjs().subtract(30, 'day').format('DD / MM / YYYY')}
                <span>—</span>
                {dayjs().format('DD / MM / YYYY')}
              </>
            )}
            {dateRange === 'Выбрать свой' && (
              <RangePicker
                value={customDateRange}
                onChange={(dates) => handleCustomDateRange(dates)}
              />
            )}
          </div>
        </div>

        <div className={styles.rangePickerWrap}>
          <p className={styles.name}>Сортировка пользователей:</p>
          <div>
            <Dropdown trigger={['click']} menu={menu}>
              <p className={styles.rangeBlock + ' ' + styles.color_yel}>
                {inUserSelect} <Icon slug="angle_down" color="#F1B729" />
              </p>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className={styles.flex_diagram}>
        <div className={styles.text_vv}>
          <div>
            <p>Всего дней</p>
            <b>{daysOffset}</b>
          </div>
          <div>
            <p>
              <span className={styles.blue_col}></span>Выполнено (шт.):
            </p>
            <b>{orderCount}</b>
          </div>
          <div>
            <p>
              <span className={styles.or_col}></span> Ср. время (мин.):
            </p>
            <b>{selectedUser == 'all' ? totalUserDuration : <UserDuration />}</b>
          </div>
        </div>
        <div ref={blockRef} className={styles.grapf_width}>
          <BarChart width={width} height={400} data={chartData}>
            <XAxis interval={0} dataKey="date" />
            <YAxis tick={false} />
            <Tooltip />
            <Bar dataKey="OrdersCompleted" barSize={10} fill="#45D2FF" name="Выполнено" />
            <Bar dataKey="UserDuration" barSize={10} fill="#FFAF74" name="Время" />
          </BarChart>
        </div>
      </div>
    </div>
  );
};

export default observer(Chart);
