import { Form, Select, message } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { AuthStore } from '../../../DataSource/Stores/AuthStore';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import { config } from '../../../config';
import { $class } from '../../../utils';
import Lang from '../../Lang';
import Button from '../../ui/Button';
import * as styles from './index.module.scss';

export type FieldTypetwo = {
  status: any;
};

interface IProps {
  className?: string;
}

const StatusProf: React.FC<IProps> = ({ className }) => {
  const iduser: any = AuthStore.currentUserData.user._id;
  const [defStatus, setDefStatus] = useState<string>('');

  const [selectedStatus, setSelectedStatus] = useState('');
  // const workspaceId = '66e170b52fc0a03d58789c24';
  const workspaceId = config.WORKSPACE_ID;

  useEffect(() => {
    const fetchDefStatus = async () => {
      try {
        await StagesStore.getUserStatus(workspaceId);
        const userStatus: any = StagesStore.dataStatus[iduser];
        const userStatus2: string = userStatus;
        setDefStatus(
          userStatus2 === 'offline' ? 'Оффлайн' : userStatus2 === 'away' ? 'Нет на месте' : 'Онлайн'
        );
      } catch (e) {
        console.error(e);
      }
    };

    if (workspaceId) {
      fetchDefStatus();
    }
  }, [workspaceId, AuthStore.currentUserData.user._id]);

  const [form] = Form.useForm();

  const onFinish = async () => {
    await StagesStore.changeUserStatus(workspaceId, selectedStatus);
    if (StagesStore.loadingChangeStatusError) {
      message.error('Update Error');
    } else {
      message.info('Success');
    }
  };

  const handleChange = (value: string) => {
    setSelectedStatus(value);
  };

  return (
    <div className={$class(styles.status_wrp, className)}>
      <Form
        key={Date.now()}
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item<FieldTypetwo> label={''} name="status">
          <Select
            defaultValue={defStatus}
            onChange={handleChange}
            options={[
              {
                value: 'offline',
                label: 'Оффлайн',
              },
              {
                value: 'away',
                label: 'Нет на месте',
              },
              {
                value: 'online',
                label: 'Онлайн',
              },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Button variant="secondary">{Lang({ str: 'save', section: 'default' })}</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(StatusProf);
