import { DownOutlined } from '@ant-design/icons';
import { Drawer, Dropdown, Image, MenuProps, Popconfirm, message } from 'antd';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { JobsDataType } from '../../../../../ types';
import { StagesStore } from '../../../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import { config } from '../../../../../../config';
import Clickable from '../../../../../ui/Clickable';
import Icon from '../../../../../ui/Icon';
import IconButton from '../../../../../ui/IconButton';
import CommentsBlock from './CommentsBlock';
import DeadlineBlock from './DeadlineBlock';
import MemberTasksBlock from './MemberTasksBlock';
import ReferenceBlock from './ReferenceBlock';
import SystemTasksBlock from './SystemTasksBlock';
import * as styles from './index.module.scss';

interface IProps extends JobsDataType {
  _id: string;
  isOpen: boolean;
  bitrixId?: string;
  onClose: () => void;
  isArchived?: boolean;
}

const JobDrawer: FC<IProps> = ({
  workspaceId,
  _id,
  jobId,
  isOpen,
  jobData,
  name,
  bitrixId,
  prodImage,
  targetDate,
  stageId,
  assignedTo,
  comments,
  expectedDate,
  onClose,
  isArchived = false,
}) => {
  const handleDateChange = async (value: Dayjs | null) => {
    if (!value) return;
    const formattedDate = value.toISOString();
    await StagesStore.updateJobTargetDate(_id, workspaceId, formattedDate);
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.isModalOpen = false;
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  const changeArchived = async (isArchived: boolean) => {
    await StagesStore.updateJobArchived(workspaceId, _id, isArchived);
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.isModalOpen = false;
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  const changeStage = async (stageId: string) => {
    await StagesStore.updateJobStage(workspaceId, _id, stageId);
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.isModalOpen = false;
      onClose();
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  const changeMember = async (memberId: string) => {
    await StagesStore.updateJobAssignedTo(_id, workspaceId, memberId);
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.isModalOpen = false;
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  const onTask = async (idTask: string, status: string) => {
    console.log('dgdgd', idTask);
    if (status === 'in_progress') {
      status = 'completed';
      await StagesStore.updateTask(workspaceId, idTask, status);
      if (StagesStore.jobUpdateError) {
        message.error('Update Error');
      } else {
        message.info('Success');
        StagesStore.isModalOpen = false;
        StagesStore.getListJobs(workspaceId).catch((e) => {
          console.error(e);
        });
      }
    }
  };

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      message.info('Скопировано', 2);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const topDropdownMenu: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <>
          {!isArchived && (
            <Popconfirm
              title="Bы действительно хотите архивировать заказ?"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => changeArchived(true)}
            >
              <div>Архивировать</div>
            </Popconfirm>
          )}
          {isArchived && (
            <Popconfirm
              title="Bы действительно хотите разархивировать заказ?"
              okText="Да"
              cancelText="Нет"
            >
              <div>Разархивировать</div>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  const stagesDropdownMenu: MenuProps['items'] = StagesStore.dataList.map((stage) => ({
    key: stage._id,
    label:
      stage._id !== stageId ? (
        <Popconfirm
          title="Bы действительно хотите перенести заказ?"
          okText="Да"
          cancelText="Нет"
          onConfirm={() => changeStage(stage._id)}
        >
          <div className={styles.name_memb}>{stage.name}</div>
        </Popconfirm>
      ) : (
        <div>{stage.name}</div>
      ),
  }));

  const membersDropdownMenu: MenuProps['items'] = Object.values(WorkspacesStore.members).map(
    (member) => ({
      key: member.userId._id,
      label:
        member.userId._id !== assignedTo ? (
          <Popconfirm
            title="Bы действительно хотите перенести заказ?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => changeMember(member.userId._id)}
          >
            <div className={styles.name_memb}>{member.userId.firstname}</div>
          </Popconfirm>
        ) : (
          <div className={styles.name_memb}>{member.userId.firstname}</div>
        ),
    })
  );

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} width={540}>
        <div className={styles.jobDrawer}>
          <div className={styles.jobDrawerTop}>
            <p className={styles.title}>{name}</p>
            <Dropdown menu={{ items: topDropdownMenu }} trigger={['click']}>
              <IconButton icon="three_dots" variant="secondary" />
            </Dropdown>
          </div>

          <div className={styles.fieldsWrap}>
            <div className={styles.field}>
              <p className={styles.name}>Job ID:</p>
              <div className={styles.copyBlock}>
                {jobId}
                <div onClick={() => handleCopy(jobId)}>
                  <Icon slug="copy" />
                </div>
              </div>
            </div>
            {bitrixId && (
              <div className={styles.field}>
                <p className={styles.name}>Bitrix ID:</p>
                <div className={styles.copyBlock}>
                  {bitrixId}
                  <div onClick={() => handleCopy(bitrixId)}>
                    <Icon slug="copy" />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={styles.fieldsWrap}>
            <div className={styles.field}>
              <p className={styles.name}>Этап</p>
              <Dropdown menu={{ items: stagesDropdownMenu }} trigger={['click']}>
                <Clickable className={styles.dropdownBtn}>
                  <span>
                    {stageId
                      ? // ? StagesStore.dataList.find((item) => item._id === stageId)?.name
                        StagesStore.dataList.find(
                          (item) => item._id === StagesStore.dataJobs.job?.stageId
                        )?.name
                      : 'Статус'}
                  </span>
                  <DownOutlined color="#2A3038" />
                </Clickable>
              </Dropdown>
            </div>
            <div className={styles.field}>
              <p className={styles.name}>Ответственный</p>
              <Dropdown
                menu={{ items: membersDropdownMenu, style: { height: 300, overflowY: 'auto' } }}
                trigger={['click']}
              >
                <Clickable className={styles.dropdownBtn}>
                  <span>
                    {assignedTo
                      ? WorkspacesStore.members[assignedTo]?.userId?.firstname
                      : 'Ответственный'}
                  </span>
                  <DownOutlined color="#2A3038" />
                </Clickable>
              </Dropdown>
            </div>
            {prodImage && (
              <div className={styles.field} style={{ alignItems: 'start' }}>
                <p className={styles.name}>Фото готового</p>
                <Image src={`${config.EXTERNAL_ADDRESS}/image-uploader/${prodImage}`} />
              </div>
            )}
          </div>

          <ReferenceBlock jobData={jobData} />

          <DeadlineBlock targetDate={targetDate} expectedDate={expectedDate} />

          {!!comments?.length && <CommentsBlock comments={comments} />}

          {!!StagesStore.dataUserTask.length && <MemberTasksBlock assignedTo={assignedTo} />}

          {!!StagesStore.dataSystemTask.length && <SystemTasksBlock />}
        </div>
      </Drawer>
    </>
  );
};

export default observer(JobDrawer);
