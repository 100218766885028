import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import {
  Condition,
  DataType,
  GeoPoint,
  JobsDataType,
  StagesDataType,
  Statistic,
  // StatusType,
  Task,
} from '../../../components/ types';
import { api, RequestData } from '../../../utils';
import { basicRequestGet } from '../../Requests/basicRequestGet';
import { basicRequestPatch } from '../../Requests/basicRequestPatch';
import { basicRequestPost } from '../../Requests/basicRequestPost';
import { basicRequestPut } from '../../Requests/basicRequestPut';
// import workspaces from '../../../components/Cabinet/Workspaces';

export class StagesStoreActionsClass {
  @observable isModalOpen: boolean = false;

  @observable loading: boolean = false;
  @observable loadingError: boolean = false;
  @observable getloading: boolean = false;
  @observable getError: boolean = false;
  @observable viewButtonFinish: boolean = false;

  @observable loadingJobs: boolean = false;
  @observable loadingJobsError: boolean = false;

  @observable loadingJob: boolean = false;
  @observable loadingJobError: boolean = false;

  @observable loadingArchives: boolean = false;
  @observable loadingArchivesEr: boolean = false;

  @observable dataList: StagesDataType[] = [];
  @observable dataUserTask: Task[] = [];
  @observable dataSystemTask: Task[] = [];

  @observable dataJobsList: JobsDataType[] = [];
  @observable dataArchives: JobsDataType[] = [];

  @observable dataJobs: DataType = {};

  @observable jobUpdateLoading: boolean = false;
  @observable jobUpdateError: boolean = false;

  @observable taskUpdateLoading: boolean = false;
  @observable taskUpdateError: boolean = false;

  @observable dataUpdateJob: StagesDataType | null = null;

  @observable dataMyTasks: Task[] = [];
  @observable dataMyTaskOpened: Task | null = null;
  @observable loadingMyTasks: boolean = false;
  @observable loadingMyTaskOpen: boolean = false;
  @observable loadingErrorMyTasks: boolean = false;
  @observable loadingErrorMyTaskOpen: boolean = false;

  @observable layoutPage: string = 'library';
  @observable layoutPageDelivery: string = 'library';
  @observable layoutPageJob: string = 'joblay';
  @observable layoutPageJobDel: string = 'joblay';

  @observable dataQueueList: JobsDataType[] = [];
  @observable loadingQueue: boolean = false;
  @observable loadingQueueError: boolean = false;

  @observable dataStatus: Record<string, string> = {};

  @observable loadingChangeStatusError: boolean = false;
  @observable loadingChangeStatus: boolean = false;

  @observable tabsIndex: number = 0;

  @observable selectedJob: JobsDataType | null = null;
  @observable selectedJobDel: JobsDataType | null = null;
  @observable isJobSelected: boolean = false;
  @observable isJobSelectedDel: boolean = false;
  @observable JobSelectedDel: boolean = false;
  @observable showText: boolean = false;
  @observable showTextDel: boolean = false;

  @observable selectedTask: Task | null = null;
  @observable isSelectedTask: boolean = false;

  @observable showTextTask: boolean = false;
  @observable showTextTaskDelivery: boolean = false;

  @observable taskUpdateDateError: boolean = false;
  @observable taskUpdateDateLoading: boolean = false;

  @observable dataUpdateTaskDate: Task | null = null;

  @observable ExDate?: Date;

  @observable loadingJobInWork: boolean = false;
  @observable loadingJobInWorkError: boolean = false;

  @observable dataListJobsWork: JobsDataType[] = [];
  @observable loadingWaitJobs: boolean = false;
  @observable loadingWaitJobsError: boolean = false;

  @observable loadingTakeToWait: boolean = false;
  @observable loadingTakeToWaitError: boolean = false;
  @observable loadingUserStatistic: boolean = false;
  @observable UserStatisticError: boolean = false;
  @observable dataUserStatistic: Statistic[] = [];
  @observable dataUserStatisticAll: Statistic[] = [];

  @observable dataUpdateJobFileName: string = '';
  @observable loadingAddComment: boolean = false;
  @observable loadingAddCommentError: boolean = false;

  @observable dataAddJobComment: JobsDataType[] = [];

  @observable modalEditFormOrder: boolean = false;
  @observable rejectJobLoading: boolean = false;
  @observable rejectJobError: boolean = false;
  @observable loadinGiveOrder: boolean = false;
  @observable loadingErrorGiveOrder: boolean = false;
  @observable updateTableSearch: boolean = false;

  getUpdatedJobs = new RequestData<[string], { updatedJobs: JobsDataType[] }>(
    (workspaceId) => api(`workspaces/${workspaceId}/jobs/updated`),
    { updatedJobs: [] }
  );

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  @action setUpdateTable(value: boolean) {
    this.updateTableSearch = value;
  }

  @action setViewButtonFinish(value: boolean) {
    this.viewButtonFinish = value;
  }

  @action setModalEditFormOrder(value: boolean) {
    this.modalEditFormOrder = value;
  }

  @action setExDate(value?: Date) {
    this.ExDate = value;
  }

  @action setSelectedTask(task: Task | null) {
    this.selectedTask = task;
  }

  @action setIsSelectedTask(value: boolean) {
    this.isSelectedTask = value;
  }

  @action setSelectedJob(job: JobsDataType | null) {
    this.selectedJob = job;
    this.isJobSelected = job !== null;
  }

  @action setSelectedJobDel(job: JobsDataType | null) {
    this.selectedJobDel = job;
    this.JobSelectedDel = job !== null;
  }

  @action setIsSelectedJobDel(value: boolean) {
    this.isJobSelectedDel = value;
  }

  @action setIsSelectedJob(value: boolean) {
    this.isJobSelected = value;
  }

  @action setShowText(value: boolean) {
    this.showText = value;
  }

  @action setShowTextDel(value: boolean) {
    this.showTextDel = value;
  }

  @action setShowTextTask(value: boolean) {
    this.showTextTask = value;
  }

  @action setShowTextTaskDelivery(value: boolean) {
    this.showTextTaskDelivery = value;
  }

  //obnovlenye date
  @action
  async updateTaskexpectedDate(workspaceId: string, jobId: string, expectedDate: Date) {
    // console.log('update Task expectedDate');
    console.log('update Task expectedDate', expectedDate);
    console.log('update Task expectedDate', this.ExDate);
    this.taskUpdateDateLoading = true;
    this.taskUpdateDateError = false;
    try {
      const res = await basicRequestPatch(`/workspaces/${workspaceId}/jobs/${jobId}/expectedDate`, {
        expectedDate,
      });
      this.dataUpdateTaskDate = res.data;
    } catch (error) {
      console.error('update Task expectedDate|error=', error);
      this.taskUpdateDateError = true;
    } finally {
      this.taskUpdateDateLoading = false;
    }
  }
  //get stages all list//

  @action
  setDataJobList(val: JobsDataType[]) {
    this.dataJobsList = val;
  }

  @action
  async getList(workspaceId: string) {
    // console.log('getList stage');
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.loadingError = false;
    this.dataList = [];
    try {
      const res = await basicRequestGet('/workspaces/' + workspaceId + '/stages', {
        workspaceId,
      });
      console.log('---- stages|getList|res.data=', res.data);
      this.dataList = res.data.data as StagesDataType[];
    } catch (error) {
      console.error('getList stages|error=', error);
      this.loadingError = true;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  //get jobs all list//
  @action
  async getListJobs(workspaceId: string) {
    // console.log('getList jobs spisok');
    if (this.loadingJobs) {
      return;
    }
    this.loadingJobs = true;
    this.loadingJobsError = false;

    try {
      const res = await basicRequestGet('/workspaces/' + workspaceId + '/jobs', {
        // workspaceId,
      });
      console.log('----getList jobs spisok|res.data=', res.data);
      this.setDataJobList(res.data.data);
      return res.data.data;
    } catch (error) {
      console.error('getJobs|error=', error);
      runInAction(() => {
        this.loadingJobsError = true;
      });
      return [];
    } finally {
      runInAction(() => {
        this.loadingJobs = false;
      });
    }
  }

  //get jobs archivs list//
  @action
  async getArchivsJobs(workspaceId: string, start: number, end: number) {
    // console.log('getList archivs');
    if (this.loadingArchives) {
      return;
    }
    this.loadingArchives = true;
    this.loadingArchivesEr = false;
    this.dataArchives = [];
    try {
      const res = await basicRequestGet(
        '/workspaces/' + workspaceId + '/jobs/archived?start=' + start + '&end=' + end,
        { start, end }
      );
      console.log('---- ww|getArchivs|res.data=', res.data.data);
      runInAction(() => {
        this.dataArchives = res.data.data as JobsDataType[];
      });
    } catch (error) {
      console.error('getJobs|error=', error);
      runInAction(() => {
        this.loadingArchivesEr = true;
      });
    } finally {
      runInAction(() => {
        this.loadingArchives = false;
      });
    }
  }

  //get one jobs by id//
  @action
  async getJobsId(workspaceId: string, jobId: string) {
    // console.log('get one jobs');
    // console.log(workspaceId);
    // console.log(jobId);
    if (this.loadingJob) {
      return;
    }
    this.loadingJob = true;
    this.loadingJobError = false;
    this.dataJobs = {};
    this.dataUserTask = [];
    try {
      const res = await basicRequestGet(`/workspaces/${workspaceId}/jobs/${jobId}`, {
        jobId,
      });
      console.log('---- ww|getJobsID|res.data=', res.data);
      runInAction(() => {
        this.dataJobs = res.data as DataType;
        this.dataUserTask = res.data.userTasks;
        this.dataSystemTask = res.data.systemTasks;
      });
    } catch (error) {
      console.error('getJobsID|error=', error);
      this.loadingJobError = true;
    } finally {
      runInAction(() => {
        this.loadingJob = false;
      });
    }
  }

  @action
  async updateJobArchived(workspaceId: string, jobId: string, isArchived: boolean) {
    // console.log('updateJobs');
    this.jobUpdateLoading = true;
    this.jobUpdateError = false;
    try {
      const res = await basicRequestPatch(`/workspaces/${workspaceId}/jobs/${jobId}/archived`, {
        isArchived,
      });
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateJobs|error=', error);
      this.jobUpdateError = true;
    } finally {
      this.jobUpdateLoading = false;
    }
  }

  @action
  async updateJobStage(workspaceId: string, jobId: string, stageId: string) {
    // console.log('updateJobs');
    this.jobUpdateLoading = true;
    this.jobUpdateError = false;
    try {
      const res = await basicRequestPatch(`/workspaces/${workspaceId}/jobs/${jobId}/stage`, {
        stageId,
      });
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateJobs|error=', error);
      this.jobUpdateError = true;
    } finally {
      this.jobUpdateLoading = false;
    }
  }

  @action
  async updateJobAssignedTo(jobId: string, workspaceId: string, assignedTo: string) {
    // console.log('updateJobs');
    this.jobUpdateLoading = true;
    this.jobUpdateError = false;
    try {
      const res = await basicRequestPatch(`/workspaces/${workspaceId}/jobs/${jobId}/assignedTo`, {
        workspaceId,
        assignedTo,
      });
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateJobs|error=', error);
      this.jobUpdateError = true;
    } finally {
      this.jobUpdateLoading = false;
    }
  }

  @action
  async updateJobTargetDate(jobId: string, workspaceId: string, targetDate: string) {
    // console.log('updateJobs');
    this.jobUpdateLoading = true;
    this.jobUpdateError = false;
    try {
      const res = await basicRequestPatch(`/workspaces/${workspaceId}/jobs/${jobId}/targetDate`, {
        workspaceId,
        targetDate,
      });
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateJobs|error=', error);
      this.jobUpdateError = true;
    } finally {
      this.jobUpdateLoading = false;
    }
  }

  @action
  async updateJobImage(workspaceId: string, jobId: string, formData: FormData) {
    // console.log('updateJobs');
    this.jobUpdateLoading = true;
    this.jobUpdateError = false;
    try {
      const res = await basicRequestPatch(
        `/image-uploader/workspaces/${workspaceId}/jobs/${jobId}/updateProdImage`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateJobs|error=', error);
      this.jobUpdateError = true;
    } finally {
      this.jobUpdateLoading = false;
    }
  }

  @action
  async updateTask(workspaceId: string, id: string, status: string) {
    // console.log('updateStatusTask');
    this.taskUpdateLoading = true;
    this.taskUpdateError = false;
    try {
      const res = await basicRequestPatch(
        `/workspaces/${workspaceId}/stages-steps-tasks/${id}/status`,
        { id, status }
      );
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateStatusTask|error=', error);
      this.taskUpdateError = true;
    } finally {
      this.taskUpdateLoading = false;
    }
  }

  @action
  async updateTaskWithGeo(
    workspaceId: string,
    id: string,
    status: string,
    currentCourierGeo: GeoPoint
  ) {
    // console.log('updateStatusTask');
    this.taskUpdateLoading = true;
    this.taskUpdateError = false;
    try {
      const res = await basicRequestPatch(
        `/workspaces/${workspaceId}/stages-steps-tasks/${id}/status`,
        { id, status, currentCourierGeo }
      );
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateStatusTask|error=', error);
      this.taskUpdateError = true;
      throw error;
    } finally {
      this.taskUpdateLoading = false;
    }
  }

  @action
  async updateTaskTargetDate(workspaceId: string, id: string, targetDate: string) {
    // console.log('updateTaskTargetDate');
    this.taskUpdateLoading = true;
    this.taskUpdateError = false;
    try {
      const res = await basicRequestPatch(
        `/workspaces/${workspaceId}/stages-steps-tasks/${id}/targetDate`,
        { targetDate }
      );
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateTaskTargetDate|error=', error);
      this.taskUpdateError = true;
    } finally {
      this.taskUpdateLoading = false;
    }
  }

  @action
  async getMyTasks(workspaceId: string) {
    // console.log('getMyTasks');
    if (this.loadingMyTasks) {
      return;
    }
    this.loadingMyTasks = true;
    this.loadingErrorMyTasks = false;
    // this.dataMyTasks = [];
    try {
      const res = await basicRequestGet(`/workspaces/${workspaceId}/stages-steps-tasks/my`);
      console.log('----getMyTasks|res.data=', res.data);
      runInAction(() => {
        this.dataMyTasks = res.data;
      });
    } catch (error) {
      console.error('getMyTasks|error=', error);
      runInAction(() => {
        this.loadingErrorMyTasks = true;
      });
    } finally {
      runInAction(() => {
        this.loadingMyTasks = false;
      });
    }
  }

  @action
  async getMyTaskOpen(workspaceId: string, id: string) {
    // console.log('getMyTaskOpen');
    if (this.loadingMyTaskOpen) {
      return;
    }
    this.loadingMyTaskOpen = true;
    this.loadingErrorMyTaskOpen = false;
    this.dataMyTaskOpened = null;
    try {
      const res = await basicRequestGet(`/workspaces/${workspaceId}/stages-steps-tasks/${id}`);
      console.log('----getMyTaskOpen|res.data=', res.data);
      runInAction(() => {
        this.dataMyTaskOpened = res.data.task;
      });
    } catch (error) {
      console.error('getMyTasks|error=', error);
      runInAction(() => {
        this.loadingErrorMyTaskOpen = true;
      });
    } finally {
      runInAction(() => {
        this.loadingMyTaskOpen = false;
      });
    }
  }

  @action
  setLayoutPage(value: any) {
    this.layoutPage = value;
  }

  @action
  setLayoutPageDelivery(value: any) {
    this.layoutPageDelivery = value;
  }

  @action
  setLayoutPageJob(value: any) {
    this.layoutPageJob = value;
  }

  @action
  setLayoutPageJobDel(value: any) {
    this.layoutPageJobDel = value;
  }

  @action
  setTabsIndex(value: any) {
    this.tabsIndex = value;
  }

  //get jobs all list//
  @action
  async getListJobsQueue(workspaceId: string, stageId: string) {
    // console.log('getList jobs queue');
    if (this.loadingQueue) {
      return;
    }
    this.loadingQueue = true;
    this.loadingQueueError = false;
    this.dataQueueList = [];
    try {
      const res = await basicRequestGet('/workspaces/' + workspaceId + '/jobs?stageId=' + stageId, {
        stageId,
      });
      console.log('getJobs queue|res.data=', res.data);
      runInAction(() => {
        this.dataQueueList = res.data.data as JobsDataType[];
      });
    } catch (error) {
      console.error('getJobs queue|error=', error);
      runInAction(() => {
        this.loadingQueueError = true;
      });
    } finally {
      runInAction(() => {
        this.loadingQueue = false;
      });
    }
  }

  @action
  async autoRouteForTasks(
    workspaceId: string,
    latitude: string,
    longitude: string
  ): Promise<Task[] | void> {
    if (this.loadingMyTasks) {
      return;
    }
    this.loadingMyTasks = true;
    this.loadingErrorMyTasks = false;
    // this.dataMyTasks = [];
    try {
      const res = await basicRequestPost(
        '/workspaces/' + workspaceId + '/stages-steps-tasks/autoRouteForTasks',
        {
          longitude,
          latitude,
        }
      );
      console.log('----autoRouteForTasks|res.data=', res.data);
      runInAction(() => {
        this.dataMyTasks = res.data;
      });
      return res.data;
    } catch (error) {
      console.error('autoRouteForTasks|error=', error);
      runInAction(() => {
        this.loadingErrorMyTasks = true;
      });
    } finally {
      runInAction(() => {
        this.loadingMyTasks = false;
      });
    }
  }

  @action
  async getUserStatus(workspaceId: string) {
    console.log('get user status');
    if (this.loadingQueue) {
      return;
    }
    this.loadingQueue = true;
    this.loadingQueueError = false;
    this.dataStatus = {};
    try {
      const res = await basicRequestGet('/workspaces/' + workspaceId + '/usersOnlineStatus', {
        workspaceId,
      });
      console.log('get user status=', res.data);
      runInAction(() => {
        this.dataStatus = res.data.data;
      });
    } catch (error) {
      console.error('getJobs queue|error=', error);
      runInAction(() => {
        this.loadingQueueError = true;
      });
    } finally {
      runInAction(() => {
        this.loadingQueue = false;
      });
    }
  }

  @action
  async changeUserStatus(workspaceId: string, onlineStatus: string) {
    // console.log('change user status');
    if (this.loadingChangeStatus) {
      return;
    }
    this.loadingChangeStatus = true;
    this.loadingChangeStatusError = false;
    this.dataStatus = {};
    try {
      const res = await basicRequestPatch('/workspaces/' + workspaceId + '/usersOnlineStatus', {
        workspaceId,
        onlineStatus,
      });
      console.log('change user status=', res.data);
      runInAction(() => {
        this.dataStatus = res.data.data;
      });
    } catch (error) {
      console.error('getJobs queue|error=', error);
      runInAction(() => {
        this.loadingChangeStatusError = true;
      });
    } finally {
      runInAction(() => {
        this.loadingChangeStatus = false;
      });
    }
  }

  @action
  async getStageParams(workspaceId: string, stageId: string) {
    try {
      const res = await basicRequestGet(
        `/workspaces/${workspaceId}/stages-params?stageId=${stageId}`,
        {
          workspaceId,
        }
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  }

  @action
  async updateStageById(workspaceId: string, stageId: string, stage: StagesDataType) {
    try {
      const res = await basicRequestPut(`/workspaces/${workspaceId}/stages/${stageId}`, {
        stage,
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  }

  @action
  async updateStageRules(workspaceId: string, stageId: string, conditions: Condition[]) {
    try {
      const res = await basicRequestPatch(
        `/workspaces/${workspaceId}/stages/${stageId}/transition-rules`,
        {
          conditions,
        }
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  }

  @action
  async getUserStatistic(
    workspaceId: string,
    userId: string,
    startDate: Date | null = null,
    finishDate: Date | null = null
  ) {
    if (this.loadingUserStatistic) return;

    this.loadingUserStatistic = true;
    this.UserStatisticError = false;
    this.dataUserStatistic = [];

    try {
      let query = `/workspaces/${workspaceId}/stats/${userId}`;
      if (startDate && finishDate) query += `?startDate=${startDate}&finishDate=${finishDate}`;
      const { data } = await basicRequestGet(query);
      console.log('getUserStatistic|res.data=', data);

      this.dataUserStatistic = data.data;
    } catch (error) {
      console.error('getUserStatistic|error=', error);
      this.UserStatisticError = true;
    } finally {
      this.loadingUserStatistic = false;
    }
  }

  @action
  async getUserAllStatistic(
    workspaceId: string,
    startDate: Date | null = null,
    finishDate: Date | null = null
  ) {
    if (this.loadingUserStatistic) return;

    this.loadingUserStatistic = true;
    this.UserStatisticError = false;
    this.dataUserStatisticAll = [];

    try {
      let query = `/workspaces/${workspaceId}/stats`;
      if (startDate && finishDate) query += `?startDate=${startDate}&finishDate=${finishDate}`;
      const { data } = await basicRequestGet(query);
      console.log('getUserStatistic all|res.data=', data);

      this.dataUserStatisticAll = data.data;
    } catch (error) {
      console.error('getUserStatistic all|error=', error);
      this.UserStatisticError = true;
    } finally {
      this.loadingUserStatistic = false;
    }
  }

  // delivery

  @action
  async jobInwork(workspaceId: string, jobId: string) {
    if (this.loadingJobInWork) {
      return;
    }
    this.loadingJobInWork = true;
    this.loadingJobInWorkError = false;
    try {
      const res = await basicRequestPatch(`/workspaces/${workspaceId}/jobs/${jobId}/takeToWork`, {
        jobId,
      });
      console.log('take in work status=', res.data);
      runInAction(() => {
        this.dataStatus = res.data.data;
      });
    } catch (error) {
      console.error('getJobs In Work |error=', error);
      runInAction(() => {
        this.loadingJobInWorkError = true;
      });
    } finally {
      runInAction(() => {
        this.loadingJobInWork = false;
      });
    }
  }

  @action
  async takeToWait(workspaceId: string, jobId: string) {
    if (this.loadingTakeToWait) {
      return;
    }
    this.loadingTakeToWait = true;
    this.loadingTakeToWaitError = false;
    try {
      const res = await basicRequestPatch(`/workspaces/${workspaceId}/jobs/${jobId}/takeToWait`, {
        jobId,
      });
      console.log('take in work status=', res.data);
      // runInAction(() => {
      //   this.dataStatus = res.data.data;
      // });
    } catch (error) {
      console.error('getJobs In Work |error=', error);
      runInAction(() => {
        this.loadingTakeToWaitError = true;
      });
    } finally {
      runInAction(() => {
        this.loadingTakeToWait = false;
      });
    }
  }

  //get 2 tab jobs all list//
  @action
  async getListWaitJobs(workspaceId: string) {
    // console.log('getList jobs queue');
    if (this.loadingWaitJobs) {
      return;
    }
    this.loadingWaitJobs = true;
    this.loadingWaitJobsError = false;
    this.dataListJobsWork = [];
    try {
      const res = await basicRequestGet(`/workspaces/${workspaceId}/jobs/myWaitJobs`);
      console.log('getListWaitJobs|res.data=', res.data);
      runInAction(() => {
        this.dataListJobsWork = res.data.data as JobsDataType[];
      });
    } catch (error) {
      console.error('getListWaitJobs|error=', error);
      runInAction(() => {
        this.loadingWaitJobsError = true;
      });
    } finally {
      runInAction(() => {
        this.loadingWaitJobs = false;
      });
    }
  }

  @action
  async uploadJobImage(file: FormData) {
    this.jobUpdateLoading = true;
    this.jobUpdateError = false;
    try {
      const res = await basicRequestPost(`/image-uploader/uploadImage`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      this.dataUpdateJobFileName = res.data.fileName;
    } catch (error) {
      console.error('updateJobs|error=', error);
      this.jobUpdateError = true;
    } finally {
      this.jobUpdateLoading = false;
    }
  }

  @action
  async rejectJob(workspaceId: string, id: string) {
    this.rejectJobLoading = true;
    this.rejectJobError = false;
    try {
      await basicRequestPost(`/workspaces/${workspaceId}/jobs/${id}/reject`, { id });
    } catch (error) {
      console.error('rejectJobs|error=', error);
      this.rejectJobError = true;
    } finally {
      this.rejectJobLoading = false;
    }
  }

  @action
  async addJobComment(workspaceId: string, id: string, text: string, fileUrls: string[]) {
    if (this.loadingAddComment) {
      return;
    }
    this.loadingAddComment = true;
    this.loadingAddCommentError = false;
    this.dataAddJobComment = [];
    try {
      const res = await basicRequestPost(`/workspaces/${workspaceId}/jobs/${id}/addJobComment`, {
        text,
        fileUrls,
      });
      console.log('----addJobComment|res.data=', res.data);
      runInAction(() => {
        this.dataAddJobComment = res.data;
      });
      return res.data;
    } catch (error) {
      console.error('addJobComment |error=', error);
      runInAction(() => {
        this.loadingAddCommentError = true;
      });
    } finally {
      runInAction(() => {
        this.loadingAddComment = false;
      });
    }
  }

  @action
  async giveOrders(workspaceId: string, jobIds: string[], userId: string) {
    if (this.loadinGiveOrder) {
      return;
    }
    this.loadinGiveOrder = true;
    this.loadingErrorGiveOrder = false;
    try {
      await basicRequestPost(`/workspaces/${workspaceId}/jobs/assignMultiJobs`, {
        jobIds,
        userId,
      });
    } catch (error) {
      console.error('giveorder|error=', error);
      runInAction(() => {
        this.loadingErrorGiveOrder = true;
      });
    } finally {
      runInAction(() => {
        this.loadinGiveOrder = false;
      });
    }
  }
}
