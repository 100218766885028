import { observer } from 'mobx-react';
import { FC, useEffect, useRef, useState } from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { $class } from '../../../utils';
import FinishJobModal from '../DeliveryCenter/Mobile/FinishJobModal';
import * as styles from './index.module.scss';

export interface ButtonSubmitType {
  label: string;
  value: string;
}

interface ButtonProps {
  name: string;
  onClick: (type?: string) => void;
  className?: string;
  types?: ButtonSubmitType[];
  disabled?: boolean;
}

const SliderButton: FC<ButtonProps> = ({ name, onClick, types, className, disabled }) => {
  const [slideWidth, setSlideWidth] = useState(0);
  const [slidePosition, setSlidePosition] = useState(0);
  const [buttonWidth, setButtonWidth] = useState(0);
  const buttonRef = useRef<HTMLDivElement>(null);

  const [isFullySlid, setIsFullySlid] = useState(false);
  const [isGrenClass, setIsGrenClass] = useState(false);

  const [typeModal, setTypeModal] = useState(false);
  const withTypes = !!types?.length;

  const handleSwipeRight = () => {
    if (disabled) return;
    setIsGrenClass(true);

    if (withTypes) {
      setTypeModal(true);
    } else {
      onClick();
    }
  };

  const onSubmitWithType = (type: string) => {
    console.log('SUBMIT WITH TYPE: ' + type);
    onClick(type);
    setTypeModal(false);
  };

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, []);

  const swipeHandlers = useSwipeable({
    onSwiping: (event: SwipeEventData) => {
      if (disabled) return;
      const maxSlidePosition = buttonWidth - 72; // 100% ширины кнопки
      const currentSlidePosition = Math.max(0, Math.min(maxSlidePosition, event.deltaX));
      setSlidePosition(currentSlidePosition);

      if (currentSlidePosition >= maxSlidePosition) {
        setIsFullySlid(true);
      } else {
        setIsFullySlid(false);
      }
    },
    onSwiped: (event: SwipeEventData) => {
      if (disabled) return;
      if (isFullySlid) {
        handleSwipeRight();
        setTimeout(() => {
          setSlidePosition(0);
          setIsGrenClass(false);
        }, 600);
      } else {
        setSlidePosition(0);
      }
    },
    trackTouch: true,
    trackMouse: true,
  });
  // console.log(buttonWidth, 'buttonWidth');

  return (
    <div
      ref={buttonRef}
      style={{
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <div
        className={$class(styles.call_button, isGrenClass && styles.fully_slid, className)}
        {...swipeHandlers}
      >
        <div
          className={`${styles.slider}`}
          style={{
            transform: `translateX(${slidePosition}px)`,
          }}
        >
          <div className={styles.slider_inner}>
            <svg
              width="30"
              height="22"
              viewBox="0 0 30 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.6711 22V16.2864H0.764648V5.76212H15.648V0L29.2352 11.0085L15.6711 22Z"
                fill="#E1A719"
              />
            </svg>
          </div>
        </div>
        <span className={styles.button_text}>{name}</span>
      </div>

      {withTypes && (
        <FinishJobModal
          onSubmit={onSubmitWithType}
          items={types}
          open={typeModal}
          onClose={() => setTypeModal(false)}
        />
      )}
    </div>
  );
};

export default observer(SliderButton);
