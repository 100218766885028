import { Flex, Form, message } from 'antd';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import usePageTitle from '../../../../DataSource/Hooks/usePageTitle';
import { AuthStore } from '../../../../DataSource/Stores/AuthStore';
import { ProfileStore } from '../../../../DataSource/Stores/Site/ProfileStore';
import { $class } from '../../../../utils';
import Button from '../../../ui/Button';
import Icon from '../../../ui/Icon';
import IconButton from '../../../ui/IconButton';
import Input from '../../../ui/Input';
import ImageUpload from './ImageUpload';
import ModalProfile from './ModalProfile';
import * as styles from './index.module.scss';

export type FieldTypetwo = {
  firstname: string;
  secondname: string;
  password: string;
  email: string;
  confirmpassword: string;
  token?: string;
};

const EditProfile = ({ onBack }: { onBack: () => void }) => {
  usePageTitle('Профиль');

  const [form] = Form.useForm();
  const location = useLocation();

  useEffect(() => {
    form.setFieldsValue(AuthStore.currentUserData.user);
  }, []);

  const forceUpdate = () => {
    form.setFieldsValue({ email: AuthStore.currentUserData.user.email });
  };

  const onFinish = async (values: FieldTypetwo) => {
    if (values.password && values.password !== values.confirmpassword) {
      message.error('Passwords do not match');
      return; // Прерываем отправку формы
    }

    try {
      await AuthStore.editProfile(values);
    } catch (error) {
      message.error('Error');
      return;
    }
    message.info('Success');
  };

  const validateConfirmPassword = (_: any, value: string) => {
    if (!value || form.getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('The two passwords do not match!'));
  };

  const openModal = () => {
    ProfileStore.isModalOpen = true;
  };

  return (
    <div className={$class(styles.profile, [styles.mobile, location.pathname.includes('user')])}>
      <Button onClick={onBack} autoWidth>
        <Icon slug="chevronLeft" color="#909090" />
        Вернуться назад
      </Button>
      <ImageUpload image={AuthStore.currentUserData.user.photo} />

      <Form
        key={Date.now()}
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item<FieldTypetwo>
          label={<p className={styles.formLabel}>Имя</p>}
          name="firstname"
          rules={[
            {
              required: true,
              message: 'Please input your first name!',
            },
            {
              message: 'You can only enter letters',
              pattern: new RegExp(/^[A-Za-zа-яёА-ЯЁ]+$/),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item<FieldTypetwo>
          label={<p className={styles.formLabel}>Фамилия</p>}
          name="secondname"
          rules={[
            { required: true, message: 'Please input your second name!' },
            {
              message: 'You can only enter letters',
              pattern: new RegExp(/^[A-Za-zа-яёА-ЯЁ]+$/),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item<FieldTypetwo> label={<p className={styles.formLabel}>E-mail</p>}>
          <Flex gap={16}>
            <Form.Item name="email" noStyle>
              <Input disabled />
            </Form.Item>
            <IconButton icon="pen" size="lg" onClick={openModal} />
          </Flex>
        </Form.Item>
        <Form.Item<FieldTypetwo>
          label={<p className={styles.formLabel}>Пароль</p>}
          name="password"
          rules={[{ message: 'Please input your password!' }]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item<FieldTypetwo>
          label={<p className={styles.formLabel}>Повторите пароль</p>}
          name="confirmpassword"
          dependencies={['password']}
          rules={[
            { required: true, message: 'Please confirm password!' },
            { validator: validateConfirmPassword },
          ]}
          style={{ marginBottom: '40px' }}
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item style={{ margin: 0 }}>
          <Button variant="secondary">Сохранить</Button>
        </Form.Item>
      </Form>
      <ModalProfile forceUpdate={forceUpdate} />
    </div>
  );
};
export default observer(EditProfile);
