import { $class } from '../../../utils';
import './index.scss';

interface IProps {
  children: React.ReactNode;
  variant?: 'default' | 'flat';
  color?: 'primary' | 'success' | 'alert' | 'error' | 'inactive';
}

const Chip = ({ children, color = 'primary', variant = 'default' }: IProps) => {
  return (
    <div className={$class('ui-chip', `ui-chip--${color}`, `ui-chip--variant_${variant}`)}>
      {children}
    </div>
  );
};

export default Chip;
