import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  FormProps,
  Input,
  Modal,
  Radio,
  message,
} from 'antd';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { GeoPoint, JobFor, jobForDeliveryCenter } from '../../../../../ types';
import { StagesStore } from '../../../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import { ManagerStore } from '../../../../../../DataSource/Stores/ManagerStore';
import { OrganizationsStore } from '../../../../../../DataSource/Stores/Site/OrganizationsStore';
import Icon from '../../../../../ui/Icon';
import StatusTitle from '../../../StatusTitle';
import * as styles from './index.module.scss';

type Contact = {
  name: string;
  phone: string;
};

type FieldType = {
  contactsFrom: Contact[];
  contactsTo: Contact[];
  geoPointFrom: GeoPoint;
  geoPointTo: GeoPoint;
  // username?: string;
  // password?: string;
  // remember?: string;
  // monday?: string;

  from: string;
  to: string;
  datetime: Dayjs;
  instruction: string;
  paymentType: string;
  cost?: number;
};
type WorkspaceIdParams = {
  id?: string;
};

const { TextArea } = Input;

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

interface ModalAddProps {
  // forceUpdate: () => void;
  _jobFor?: JobFor;
}

interface Organization {
  name: string;
  address: {
    fullAddress: string;
    geoPoint: GeoPoint | null;
  };
  contactData: Contact[];
}

interface LocationForm {
  value: string;
  contactData: Contact[];
  geoPoint: GeoPoint | null;
  // label: string;
}

const ModalAdd: React.FC<ModalAddProps> = ({ _jobFor }) => {
  const [form] = Form.useForm();
  const [locationFrom, setLocationFrom] = useState<LocationForm[]>([]);
  const { id } = useParams<WorkspaceIdParams>();

  const [jobFor, setJobFor] = useState<JobFor>('sau');

  const location = useLocation();

  useEffect(() => {
    if (_jobFor) {
      setJobFor(_jobFor);
      form.setFieldsValue({ statusjob: _jobFor });
    } else {
      if (location.pathname.includes('/sau')) {
        setJobFor('sau');
        form.setFieldsValue({ statusjob: jobFor });
      } else if (location.pathname.includes('/lulu')) {
        setJobFor('lulu');
        form.setFieldsValue({ statusjob: jobFor });
      } else if (location.pathname.includes('/metabody')) {
        setJobFor('metabody');
        form.setFieldsValue({ statusjob: jobFor });
      } else {
        setJobFor('sau');
      }
    }
    console.log('fdf', jobFor);
  }, [location, _jobFor, ManagerStore.ModalOpenOrder]);

  console.log(jobFor, 'jobForrrr444', _jobFor);

  // const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
  //   if (!id) return;

  //   console.log('Success:', values);

  //   console.log(values.datetime.toDate());

  //   const jobData: jobForDeliveryCenter = {
  //     name: `Доставка на ${values.to}`,
  //     externalId: {},
  //     workspaceId: id,
  //     jobData: [
  //       {
  //         text: values.instruction,
  //         photoFiles: [],
  //       },
  //     ],
  //     targetDate: values.datetime.toDate(),
  //     deliveryData: {
  //       pickupPoint: {
  //         address: values.from,
  //         contactData: values.contactsFrom,
  //         geoPoint: values.geoPointFrom || null,
  //         // geoPoint: {
  //         //   latitude: '55.7558',
  //         //   longitude: '37.6173',
  //         // },
  //       },
  //       deliveryPoint: {
  //         address: values.to,
  //         contactData: values.contactsTo,
  //         geoPoint: values.geoPointTo || null,
  //         // geoPoint: {
  //         //   latitude: '48.8566',
  //         //   longitude: '2.3522',
  //         // },
  //       },
  //       paymentData: {
  //         method: values.paymentType,
  //         amount: values.cost || 0,
  //       },
  //     },
  //     jobFor,
  //   };

  //   try {
  //     await WorkspacesStore.addJobForDeliveryCenter(id, jobData);
  //     message.info('Success');
  //     form.resetFields();
  //     ManagerStore.ModalOpenOrder = false;
  //   } catch (err) {
  //     if (err instanceof AxiosError && err?.response?.data?.message?.message) {
  //       return message.error(err?.response?.data?.message?.message);
  //     }
  //   }
  // };

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    if (!id) return;
    console.log('Success:', values);
    console.log(values.datetime.toDate());
    const jobData: jobForDeliveryCenter = {
      name: `Доставка на ${values.to}`,
      externalId: {},
      workspaceId: id,
      jobData: [
        {
          text: values.instruction,
          photoFiles: [],
        },
      ],
      targetDate: values.datetime.toDate(),
      deliveryData: {
        pickupPoint: {
          address: values.from,
          contactData: values.contactsFrom,
          geoPoint: values.geoPointFrom || null,
        },
        deliveryPoint: {
          address: values.to,
          contactData: values.contactsTo,
          geoPoint: values.geoPointTo || null,
        },
        paymentData: {
          method: values.paymentType,
          amount: values.cost || 0,
        },
      },
      jobFor,
    };

    const jobid = StagesStore.dataJobs?.job?._id;
    try {
      if (StagesStore.modalEditFormOrder == true) {
        // Если ModalOpenOrder истинно, используем update
        await WorkspacesStore.updateJobForDeliveryCenter(id, jobid ? jobid : '', jobData);
        message.info('Job updated successfully');
        form.resetFields();
        StagesStore.getListJobs(id).catch((e) => {
          console.error(e);
        });
      } else {
        // В противном случае добавляем новую работу
        await WorkspacesStore.addJobForDeliveryCenter(id, jobData);
        message.info('Job added successfully');
        StagesStore.getListJobs(id).catch((e) => {
          console.error(e);
        });
      }
      form.resetFields();
      ManagerStore.ModalOpenOrder = false;
    } catch (err) {
      if (err instanceof AxiosError && err?.response?.data?.message?.message) {
        return message.error(err?.response?.data?.message?.message);
      }
    }
  };

  const handleChangeFrom = (value: string, option: LocationForm | LocationForm[]) => {
    // console.log(`selected ${value}`);
    console.log('handleChangeFrom: ', { value, option });
    if (Array.isArray(option)) {
      return;
    }
    if (!Array.isArray(option.contactData) || option.contactData.length === 0) {
      return;
    }
    console.log('option.contactData: ', option.contactData);
    // option.contactData.map((value, index) => {
    //   console.log({ value, index });
    // });
    const contactsFrom = option.contactData.map((contact) => ({
      name: contact.name,
      phone: contact.phone,
    }));

    const geoPointFrom = option.geoPoint;

    form.setFieldsValue({
      contactsFrom,
      geoPointFrom,
    });
  };

  const handleChangeTo = (value: string, option: LocationForm | LocationForm[]) => {
    // console.log(`selected ${value}`);
    console.log('handleChangeTo: ', { value, option });
    if (Array.isArray(option)) {
      return;
    }
    if (!Array.isArray(option.contactData) || option.contactData.length === 0) {
      return;
    }

    const contactsTo = option.contactData.map((contact) => ({
      name: contact.name,
      phone: contact.phone,
    }));
    const geoPointTo = option.geoPoint;
    form.setFieldsValue({
      contactsTo,
      geoPointTo,
    });
  };

  const handleOk = () => {
    ManagerStore.ModalOpenOrder = false;
    StagesStore.setModalEditFormOrder(false);
    form.resetFields();
  };
  const handleCancel = () => {
    ManagerStore.ModalOpenOrder = false;
    form.resetFields();
    StagesStore.setModalEditFormOrder(false);
  };

  useEffect(() => {
    if (StagesStore.modalEditFormOrder == true) {
      const zoneData = StagesStore.dataJobs?.job;
      const targetDate = dayjs(zoneData?.targetDate);
      form.setFieldsValue({
        from: zoneData?.deliveryData?.pickupPoint?.address,
        to: zoneData?.deliveryData?.deliveryPoint?.address,
        datetime: targetDate,
        contactsFrom: zoneData?.deliveryData?.pickupPoint?.contactData.map((contact) => ({
          name: contact.name,
          phone: contact.phone,
        })),
        contactsTo: zoneData?.deliveryData?.deliveryPoint?.contactData.map((contact) => ({
          name: contact.name,
          phone: contact.phone,
        })),
        geoPointFrom: zoneData?.deliveryData?.pickupPoint?.geoPoint || null,
        geoPointTo: zoneData?.deliveryData?.deliveryPoint?.geoPoint || null,
        instruction: zoneData?.jobData?.[0]?.text || '',
        paymentType: zoneData?.deliveryData?.paymentData?.method,
        cost: zoneData?.deliveryData?.paymentData?.amount,
        statusjob: zoneData?.jobFor,
      });
    } else {
      form.resetFields();
    }
  }, [StagesStore.modalEditFormOrder]);

  useEffect(() => {
    if (id) {
      (async () => {
        await OrganizationsStore.getList(id);
        console.log('OrganizationsStore.dataList: ', OrganizationsStore.dataList);
        if (OrganizationsStore.dataList.length) {
          const addresses: LocationForm[] = OrganizationsStore.dataList.map(
            (org: Organization) => ({
              value: `${org.name} ${org.address.fullAddress}`,
              contactData: org.contactData,
              geoPoint: org.address.geoPoint,
            })
          );
          console.log({ addresses });
          setLocationFrom(addresses);
        }
      })();
    }
  }, [id]);

  return (
    <Modal
      title={'заказ'}
      open={ManagerStore.ModalOpenOrder}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <div className={styles.moda_cont}>
        <p className={styles.moda_cont__title}>
          {StagesStore.modalEditFormOrder ? (
            <div className={styles.chip}>#{StagesStore.dataJobs?.job?.jobId}</div>
          ) : (
            'новый заказ'
          )}
        </p>

        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          //className={styles.orderModal}
          requiredMark={false}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="statusjob"
            rules={[{ required: true, message: 'Укажите группу' }]}
            style={{ marginBottom: 12 }}
          >
            <Radio.Group
              disabled={!!_jobFor}
              value={jobFor}
              // onChange={(e) => setJobFor(e.target.value)}
              onChange={(e) => setJobFor(e.target.value as JobFor)}
            >
              <Radio value="sau">SAU</Radio>
              <Radio value="lulu">LULU</Radio>
              <Radio value="metabody">METABODY</Radio>
            </Radio.Group>
          </Form.Item>

          {StagesStore.modalEditFormOrder ? (
            <StatusTitle title="редактирование" theme="work" />
          ) : (
            <StatusTitle title="создание" theme="create" />
          )}
          <div className={styles.formLabel}>Откуда:</div>
          <Form.Item
            name="from"
            rules={[{ required: true, message: 'Укажите адрес' }]}
            style={{ marginBottom: 12 }}
          >
            <AutoComplete
              options={locationFrom}
              placeholder="Введите или выберите адрес.."
              // filterOption={(inputValue, option) =>
              //   option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              // }
              onChange={handleChangeFrom}
            />
          </Form.Item>
          <Form.Item name="geoPointFrom" style={{ marginBottom: 12, display: 'none' }}>
            <Input placeholder="Гео от" />
          </Form.Item>

          <div className={styles.formLabel}>Куда:</div>
          <Form.Item
            name="to"
            rules={[{ required: true, message: 'Укажите адрес' }]}
            style={{ marginBottom: 12 }}
          >
            <AutoComplete
              options={locationFrom}
              placeholder="Введите или выберите адрес.."
              onChange={handleChangeTo}
            />
          </Form.Item>
          <Form.Item name="geoPointTo" style={{ marginBottom: 12, display: 'none' }}>
            <Input placeholder="Гео куда" />
          </Form.Item>

          <div className={styles.formLabel}>Дата и время доставки:</div>
          <Form.Item
            name="datetime"
            rules={[{ required: true, message: 'Заполните поле' }]}
            style={{ marginBottom: 12 }}
          >
            <DatePicker
              placeholder="Выберите дату"
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>

          <div className={styles.formLabel}>Инструкция для курьера:</div>
          <Form.Item
            name="instruction"
            rules={[{ required: true, message: 'Заполните поле' }]}
            style={{ marginBottom: 12 }}
          >
            <TextArea placeholder="Введите описание..." autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>

          <div className={styles.formLabel}>Контакты в точке Забора:</div>
          <Form.List
            name="contactsFrom"
            rules={[
              {
                validator: async (_, contactsFrom) => {
                  if (!contactsFrom || contactsFrom.length === 0) {
                    return Promise.reject(new Error('Вы должны добавить хотя бы один контакт!'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ display: 'flex', marginBottom: 8 }}>
                    <Form.Item
                      {...restField}
                      name={[name, 'phone']}
                      style={{ marginBottom: 0 }}
                      rules={[{ required: true, message: 'Требуется указать номер' }]}
                    >
                      <Input placeholder="Телефон" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      style={{ marginLeft: 8, marginBottom: 0 }}
                      rules={[{ required: true, message: 'Требуется указать имя' }]}
                    >
                      <Input placeholder="Имя" />
                    </Form.Item>

                    <Button
                      icon={<Icon slug="minus" size={18} color="#959595" />}
                      style={{ width: 32, marginLeft: 8 }}
                      shape="default"
                      type="link"
                      onClick={() => remove(name)}
                    ></Button>
                  </div>
                ))}
                <Form.Item>
                  <Button type="default" onClick={() => add()} block>
                    + Добавить контакт
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <div className={styles.formLabel}>Контакты в точке Доставки:</div>
          <Form.List
            name="contactsTo"
            rules={[
              {
                validator: async (_, contactsTo) => {
                  if (!contactsTo || contactsTo.length === 0) {
                    return Promise.reject(new Error('Вы должны добавить хотя бы один контакт!'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ display: 'flex', marginBottom: 8 }}>
                    <Form.Item
                      {...restField}
                      name={[name, 'phone']}
                      style={{ marginBottom: 0 }}
                      rules={[{ required: true, message: 'Требуется телефон!' }]}
                    >
                      <Input placeholder="Phone" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      style={{ marginLeft: 8, marginBottom: 0 }}
                      rules={[{ required: true, message: 'Требуется указать имя' }]}
                    >
                      <Input placeholder="Имя" />
                    </Form.Item>

                    <Button
                      icon={<Icon slug="minus" size={18} color="#959595" />}
                      style={{ width: 32, marginLeft: 8 }}
                      shape="default"
                      type="link"
                      onClick={() => remove(name)}
                    ></Button>
                  </div>
                ))}
                <Form.Item>
                  <Button type="default" onClick={() => add()} block>
                    + Добавить контакт
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <div className={styles.formLabel}>Способ оплаты:</div>
          <Form.Item
            name="paymentType"
            style={{ marginBottom: 12 }}
            rules={[{ required: true, message: 'Пожалуйста, выберите тип оплаты' }]}
          >
            <Radio.Group>
              <Radio value={'cash'}>Наличные</Radio>
              <Radio value={'online'}>Онлайн</Radio>
            </Radio.Group>
          </Form.Item>

          <div className={styles.formLabel}>Сумма, ₸</div>
          <Form.Item
            name="cost"
            rules={[
              {
                type: 'number',
                message: 'Сумма должна быть числом!',
                transform: (value) => (value ? Number(value) : value), // Преобразует значение в число
              },
            ]}
            style={{ marginBottom: 12 }}
          >
            <Input
              prefix="₸"
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.]/g, '');
              }}
              placeholder="Введите сумму..."
            />
          </Form.Item>

          {/* <div className={styles.one_block}>
            <p className={styles.one_tit}>Параметры заказа</p>
            <div className={styles.flex_input}>
              <Form.Item<FieldType>
                label="Курьер"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Select
                  defaultValue="Выберите"
                  onChange={handleChange}
                  options={[
                    { value: 'Активен', label: 'Активен' },
                    { value: 'Не активен', label: 'Не активен' },
                  ]}
                />
              </Form.Item>
              <Form.Item<FieldType>
                label="Компания"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Select
                  defaultValue="Выберите"
                  onChange={handleChange}
                  options={[
                    { value: 'Активен', label: 'Активен' },
                    { value: 'Не активен', label: 'Не активен' },
                  ]}
                />
              </Form.Item>
            </div>
            <div className={styles.flex_input}>
              <Form.Item<FieldType>
                label="Откуда"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Select
                  defaultValue="Выберите"
                  onChange={handleChange}
                  options={[
                    { value: 'Активен', label: 'Активен' },
                    { value: 'Не активен', label: 'Не активен' },
                  ]}
                />
              </Form.Item>
              <Form.Item<FieldType>
                label="Куда"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Select
                  defaultValue="Выберите"
                  onChange={handleChange}
                  options={[
                    { value: 'Активен', label: 'Активен' },
                    { value: 'Не активен', label: 'Не активен' },
                  ]}
                />
              </Form.Item>
            </div>
          </div>

          <div className={styles.one_block}>
            <Form.Item<FieldType>
              label="комментарий"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <TextArea rows={2} placeholder="Заполните" />
            </Form.Item>
            <Form.Item<FieldType>
              label="Содержание"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <TextArea rows={2} placeholder="Заполните" />
            </Form.Item>
          </div> */}

          <Button style={{ width: '100%' }} type="primary" htmlType="submit">
            {StagesStore.modalEditFormOrder ? 'Обновить' : 'Добавить'}
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default observer(ModalAdd);
