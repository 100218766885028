import anime from 'animejs';
import { CSSProperties, MouseEventHandler, ReactNode, useRef } from 'react';
import { $class } from '../../../utils';
import './index.scss';

interface IProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  id?: string;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  disabled?: boolean;
  rippleColor?: string;
}

const Clickable = ({
  onClick,
  rippleColor = 'black',
  id,
  className,
  style,
  children,
  disabled,
}: IProps) => {
  const elRef = useRef<HTMLButtonElement | null>(null);
  const pointerRef = useRef(null);

  const onUp = () => {
    anime({
      targets: pointerRef.current,
      opacity: 0,
    });
  };

  const onDown = () => {
    anime({
      targets: pointerRef.current,
      opacity: 1,
      scale: 3,
    });
  };

  const onEnter = () => {
    anime({
      targets: pointerRef.current,
      opacity: 1,
      scale: 1,
    });
  };

  const onMove = (e: any) => {
    const x = e?.clientX;
    const y = e?.clientY;

    const rect = elRef.current?.getBoundingClientRect();

    if (!rect) return;

    anime({
      targets: pointerRef.current,
      left: x - rect.x - 15,
      top: y - rect.y - 15,
      duration: 20,
    });
  };

  return (
    <button
      id={id}
      ref={elRef}
      onTouchStart={onDown}
      onMouseDown={onDown}
      onTouchEnd={onUp}
      onTouchCancel={onUp}
      onMouseUp={onUp}
      onMouseLeave={onUp}
      onTouchStartCapture={onEnter}
      onMouseEnter={onEnter}
      onMouseMove={onMove}
      onClick={onClick && !disabled ? onClick : undefined}
      className={$class('ui-clickable', className)}
      disabled={disabled}
      style={style}
    >
      <div
        ref={pointerRef}
        style={{ backgroundColor: rippleColor }}
        className="ui-clickable-pointer"
      ></div>
      {children}
    </button>
  );
};

export default Clickable;
