import { ConfigProvider, Layout } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { HeaderStore } from '../DataSource/Stores/HeaderStore';
import Menu from '../components/Cabinet/DeliveryCenter/Menu';
import Nav from '../components/Cabinet/DistributionCenter/Nav';
import Nav2 from '../components/Cabinet/ShokolatyeCex/Nav';
import Header from '../components/Header/Header';
import * as styles from './index.module.scss';

function CabinetLayout() {
  const { id } = useParams();
  const location = useLocation();

  const onClose = () => {
    HeaderStore.setOpenRight(false);
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 990px)' });

  const siderStyle: CSSProperties = {
    backgroundColor: '#F3F5F6',
    padding: '24px 32px',
  };

  return (
    <ConfigProvider>
      {id == '6707a212598d48bda5860bf0' ? (
        <div className="block_width">
          <Header />
          <div className="flex_row">
            <div className="menu_block">
              {!location.pathname.includes('sau') &&
                !location.pathname.includes('lulu') &&
                !location.pathname.includes('metabody') && <Menu />}
            </div>
            <div className="second_column">
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <Layout>
          <Sider width="auto" style={siderStyle}>
            {id == '6732ed08a29f2065aeb56662' ? <Nav2 /> : <Nav />}
          </Sider>
          <div id="cabinet-layout" className={styles.cabinetLayout}>
            <Outlet />
          </div>
        </Layout>
      )}
    </ConfigProvider>
  );
}
export default observer(CabinetLayout);
