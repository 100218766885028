// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rg9e7cpYwEEWoCUuPOul{display:flex;flex-direction:column;gap:10px;padding:0}.ojTarv5rc9_zPr0MTmIA{display:flex;align-items:center;gap:12px}.ojTarv5rc9_zPr0MTmIA .ZfURynII1EYKYCE1jgU3{height:35px;background-color:#fff;border:1px solid #e0e0e0;border-radius:8px;box-shadow:0 2px 2px 0 #eaeaea;color:rgba(42,48,56,.4);font-size:14px;font-weight:800;text-transform:uppercase;display:flex;align-items:center;justify-content:center;padding:0 16px}.ojTarv5rc9_zPr0MTmIA .YT6O6GWmvE9nf3dIw3tq>input{height:35px;color:rgba(151,151,151,.5019607843);font-size:15px}.rg9e7cpYwEEWoCUuPOul td{font-size:18px}.VTxAgqq0Ndw3u5YcDr_a{display:flex;align-items:center;justify-content:center;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DistributionCenter/History/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,SAAA,CAEA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,4CACE,WAAA,CACA,qBAAA,CACA,wBAAA,CACA,iBAAA,CACA,8BAAA,CACA,uBAAA,CACA,cAAA,CACA,eAAA,CACA,wBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CAGF,kDACE,WAAA,CACA,mCAAA,CACA,cAAA,CAIJ,yBACE,cAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA","sourcesContent":[".historyPage{display:flex;flex-direction:column;gap:10px;padding:0}.historyPageTop{display:flex;align-items:center;gap:12px}.historyPageTop .title{height:35px;background-color:#fff;border:1px solid #e0e0e0;border-radius:8px;box-shadow:0 2px 2px 0 #eaeaea;color:rgba(42,48,56,.4);font-size:14px;font-weight:800;text-transform:uppercase;display:flex;align-items:center;justify-content:center;padding:0 16px}.historyPageTop .searchInput>input{height:35px;color:rgba(151,151,151,.5019607843);font-size:15px}.historyPage td{font-size:18px}.copyBtn{display:flex;align-items:center;justify-content:center;cursor:pointer}"],"sourceRoot":""}]);
// Exports
export var historyPage = `rg9e7cpYwEEWoCUuPOul`;
export var historyPageTop = `ojTarv5rc9_zPr0MTmIA`;
export var title = `ZfURynII1EYKYCE1jgU3`;
export var searchInput = `YT6O6GWmvE9nf3dIw3tq`;
export var copyBtn = `VTxAgqq0Ndw3u5YcDr_a`;
export default ___CSS_LOADER_EXPORT___;
